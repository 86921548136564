import Vue from "vue";
import VueRouter from "vue-router";
import AgentRoom from "@/views/AgentRoom.vue";
import ClientRoom from "@/views/ClientRoom.vue";
import Admin from "@/views/Admin.vue";
import Users from "@/views/Users.vue";
import Companies from "@/views/Companies.vue";
import Endcall from "@/views/Endcall.vue";
import ErrorPage from '@/views/ErrorPage.vue';

import store from '../store/index'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AgentRoom",
    component: AgentRoom,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: "/room",
    name: "ClientRoom",
    component: ClientRoom,
    meta: { requiresAuth: false }
  },
  {
    path: "/endcall",
    name: "endcall",
    component: Endcall
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin/users",
    name: "users",
    component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin/companies",
    name: "companies",
    component: Companies,
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    name: "errorpage",
    component: ErrorPage,
    meta: { requiresAuth: false }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userClaims = store.state.Auth.token;
  const isWebRTCSupported = typeof navigator.getUserMedia != 'undefined' || typeof window.RTCPeerConnection != 'undefined';
  let WebRTCSupported = false;
  if (isWebRTCSupported) {
    WebRTCSupported = true;
  }

  if (/CriOS/i.test(navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator.userAgent)) {
    WebRTCSupported = false;
  }

  if (to.name === 'login' && userClaims) {
    next('/');
    return;
  }

  if ((to.name === "admin" || to.name === "users" || to.name === "companies") && userClaims && userClaims.claims.type == "agent") {
    next("error")
    return
  }

  

  if (to.name === 'admin' && userClaims && userClaims === "superuser") {
    next();
    return
  }

  if (!userClaims && to.name === 'clientroom' && to.query.id) {
    next();
    return;
  }

  if (requiresAuth && !userClaims) {
      next("/login");
  } else {
    next();
  }

});

export default router;
