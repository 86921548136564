<template>
  <div>
    <v-menu
      @input="updateShowmenu($event)"
      nudge-bottom="60"
      nudge-left="350"
      :close-on-content-click="false"
      :close-on-click="true"
    >
      <template v-slot:activator="{ on:menu}">
        <v-tooltip :bottom="!$store.state.active_call" :right="$store.state.active_call">
          <template v-on="what" v-slot:activator="{ on:tooltip }">
            <v-btn
              data-cy="alertsMenu"
              @click="showMenu=!showMenu;$store.commit('SET_NO_FOCUS_WINDOW',false); menuBadge=0"
              icon
              class="mr-2"
              v-on="{ ...tooltip, ...menu }"
            >
              <v-badge :content="menuBadge" :value="menuBadge>0" color="red" overlap>
                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span data-cy="alertsMenutt">{{$i18n.t("GENERAL.alert")}}</span>
        </v-tooltip>
      </template>

      <v-card width="400px">
        <v-list two-line subheader>
          <v-subheader inset>{{$i18n.t('GENERAL.alert')}}</v-subheader>

          <v-list-item
            @click="clickNotif(meeting)"
            v-for="(meeting, key) in active_rooms"
            :key="key"
            v-if="showNotif(meeting)"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{meeting.customer_name}} {{$i18n.t("ROOM.WAITINGUSER")}}</v-list-item-title>
              <v-list-item-subtitle>{{meeting.description}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action></v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <InfoMeeting :meeting="selectedMeeting" ref="infoMeeting"></InfoMeeting>
  </div>
</template>

<script >
import { mapState } from "vuex";
import Meeting from "@/models/meeting.js";
import chronometer from "./../components/chronometer.vue" 
import ModalOpenMeeting from './ModalOpenMeeting.vue'
import InfoMeeting from './InfoMeeting.vue'
import { Synth } from "tone";

export default {
  components:{
    chronometer,ModalOpenMeeting,InfoMeeting
  },
  data() {
    return {
      menuBadge:0,
      showMenu:false,
      selectedMeeting:null,
      meetingsList:[],
      addSchedule:false
    }
  },
  props:{
    active_rooms: Object
  },
  methods: {
    updateShowmenu(e){
      if(!e){
        this.showMenu=false;
      }
    },
    openMeetingInfo(meeting){
    },
    clickNotif(meeting){
      this.selectedMeeting = meeting
      this.$refs.infoMeeting.openDialog();
    },
    showNotif(meeting){
      if(!meeting){
        return false
      }

      if(this.$store.state.active_meeting && this.$store.state.active_meeting.idmeeting == meeting.idmeeting){
        return false
      }

      return true
    }
  },
  watch:{
    'active_rooms':async function(val){

        let meetingList = []
        let rooms =  this.$props.active_rooms
        if(rooms){
          let roomKeys = Object.keys(rooms);

          if(typeof roomKeys=="undefined"){
            return 
          }
        for(let i = 0; i<roomKeys.length;i++){
          let meeting = rooms[roomKeys];
          
          
            meetingList.push(meeting);
            if(!this.showMenu){
               if(this.$store.state.active_meeting && this.$store.state.active_meeting.idmeeting == meeting.idmeeting){

                }else{
                  this.menuBadge++;
                  const synth = new Synth().toMaster();
                  //play a middle 'C' for the duration of an 8th note
                  synth.triggerAttackRelease("D4", "8n");
                }
            }

            if(!document.hasFocus()){
              
              this.$store.commit('SET_NO_FOCUS_WINDOW',true)
              if(document.title.indexOf("*")==-1){
                document.title = document.title + " *"
              }
            }

          /* else{
            !this.showMenu && this.menuBadge-1>0? this.menuBadge-- : ''
          } */
        }
        this.meetingsList=meetingList;
        }else{
          this.$store.commit('SET_NO_FOCUS_WINDOW',false)
          document.title = document.title.replace(" *","")
          this.menuBadge=0;
        }
    }
  },
  mounted(){
    document.addEventListener('click', (event) => {
      if(this.$store.state.no_focus_alert){
        this.$store.commit('SET_NO_FOCUS_WINDOW',false)
        document.title = document.title.replace(" *","")
      }
    });
    document.addEventListener('focus', (event) => {
            event.preventDefault()
      if(this.$store.state.no_focus_alert){
        this.$store.commit('SET_NO_FOCUS_WINDOW',false)
        document.title = document.title.replace(" *","")
      }
    });
    document.addEventListener('input', (event) => {
      event.preventDefault()
      if(this.$store.state.no_focus_alert){
        this.$store.commit('SET_NO_FOCUS_WINDOW',false)
        document.title = document.title.replace(" *","")
      }
    });
  },
  computed:{
  }
};
</script>

<style lang="scss" scoped>
</style>