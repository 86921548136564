class CommunicationsSingleton {
  setCommunications(communications) {
    this.communications = communications;
  }

  destroyed() {
    if (this.communications) {
      this.communications.unloadPage();
      this.communications = null;
    }
  }
}
const singleton = new CommunicationsSingleton();
export default singleton;
