<template>
  <!--SETTINGS-->

  <v-menu nudge-bottom="60px" transition="slide-x-transition"  :close-on-click="false" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" class="mr-2">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>

    <v-list   v-if="!incall" style="width: 222px;">
      <!-- Admin item -->

       <v-list-item v-if="$store.state.Auth.token.claims.type=='superuser'" to="/admin" class="pa-0" style="    text-decoration: none !important; ">
        <v-list-item-content class="pa-0">
            
            <div style="padding: 10px 25px 10px 15px;">
              <span>
                <v-icon class="pr-2">mdi-account</v-icon>
                Admin
              </span>
            </div>
        
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="$store.state.Auth.token.claims.type=='admin'" to="/admin/users" class="pa-0" style="    text-decoration: none !important; ">
        <v-list-item-content class="pa-0">
            
            <div style="padding: 10px 25px 10px 15px;">
              <span>
                <v-icon class="pr-2">mdi-account</v-icon>
                {{ $i18n.t("SETTING.ADMIN") }}
              </span>
            </div>
        
        </v-list-item-content>
      </v-list-item>



      <!-- Appearance Item -->
      <v-list-item @click class="pa-0">
        <v-list-item-content class="pa-0">
          <v-menu
            open-on-hover
            :close-on-content-click="false"
            left
            nudge-left="182px"
            nudge-top="10px"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" style="padding: 10px 25px 10px 15px;">
                <span>
                  <v-icon class="pr-2">mdi-chevron-left</v-icon>
                  <v-icon class="pr-1">mdi-palette</v-icon>
                  {{ $i18n.t("SETTING.appearance") }}
                </span>
              </div>
            </template>

            <v-card class="px-4 py-2 apparence-card" style="height: 378px;">
              <v-list style="padding: 3px 0px 0px 0px;">
                <v-list-item two-line>
                  <v-icon size="32">mdi-collage</v-icon>
                  <v-list-item-content class="pl-4">
                    <v-list-item-title
                      style="font-size: 14px; font-weight: 400; opacity: 0.9;"
                    >{{ $i18n.t("SETTING.appearance").toUpperCase() }}</v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px; font-weight: 300; opacity: 0.9;"
                    >{{ $i18n.t("SETTING.selectAppearance") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-row no-gutters class="px-6" style="height: 50px;">
                <v-col style="display: flex;">
                  <span class="pr-4 pt-4" style="font-size: 14px; font-weight: 400; opacity: 0.9;">
                    {{
                    $i18n.t("SETTING.lightMode")
                    }}
                  </span>
                  <v-switch
                    class="pt-4"
                    style="margin-top: 0px !important;"
                    color="primary"
                    @change="setTheme"
                    :input-value="$vuetify.theme.dark"
                  ></v-switch>
                  <span class="pl-4 pt-4" style="font-size: 14px; font-weight: 400; opacity: 0.9;">
                    {{
                    $i18n.t("SETTING.darkMode")
                    }}
                  </span>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row no-gutters class="pt-3 px-2">
                <v-col
                  cols="6"
                  class="py-2 px-10"
                  v-for="(group, index) in colorGroups"
                  :key="index"
                >
                  <v-card
                    class="apparence-card__item"
                    :color="group"
                    style="border-radius: 10px; box-shadow: none; height: 38px !important;"
                  >
                    <div @click="setColor(group)" class="hover-div">
                      <v-icon>mdi-check</v-icon>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
        </v-list-item-content>
      </v-list-item>

      <!-- Language Item-->
      <v-list-item @click class="pa-0">
        <v-list-item-content class="pa-0">
          <v-menu
            open-on-hover
            :close-on-content-click="false"
            left
            nudge-left="182px"
            nudge-top="10px"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" style="padding: 10px 25px 10px 15px;">
                <span>
                  <v-icon class="pr-2">mdi-chevron-left</v-icon>
                  <v-icon class="pr-1">mdi-web</v-icon>
                  {{ $i18n.t("SETTING.lenguage") }}
                </span>
              </div>
            </template>
            <v-card class="px-2 py-2 apparence-card" height="230px" width="275px">
              <v-list style="padding: 3px 0px 0px 0px;">
                <v-list-item two-line>
                  <v-icon size="32">mdi-web</v-icon>
                  <v-list-item-content class="pl-4">
                    <v-list-item-title
                      style="font-size: 14px; font-weight: 400; opacity: 0.9;"
                    >{{ $i18n.t("SETTING.lenguage").toUpperCase() }}</v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px; font-weight: 300; opacity: 0.9;"
                    >{{ $i18n.t("SETTING.selectLenguage") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list class="px-4" rounded>
                <v-list-item-group v-model="language" color="primary">
                  <v-list-item
                    @click.prevent="setLang(item.key)"
                    v-for="item in langs"
                    :key="item.key"
                  >
                    <v-list-item-content class="text-center">
                      <v-list-item-title style="font-size: 15px; font-weight: 400; opacity: 0.9;">
                        {{
                        item.value
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item-content>
      </v-list-item>

      <!-- Room settings -->

      <!-- Language Item-->
      <v-list-item @click class="pa-0">
        <v-list-item-content class="pa-0">
          <div @click="showDialogSettings" style="padding: 10px 25px 10px 15px;">
            <span>
              <v-icon class="pr-1">mdi-cog</v-icon>
              {{$i18n.t("SETTING.ROOMSETTINGS")}}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>

      <!-- edit pass-->

      <v-list-item class="pa-0" @click="modalChangePassword = true">
        <v-list-item-content class="pa-0">
          <div style="padding: 10px 25px 10px 15px;">
            <span>
              <v-icon class="pr-2">mdi-lock</v-icon>
              {{$i18n.t("SETTING.EDITPASS")}}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <a
            :href="$store.state.lang=='es'?'https://integra-scm.atlassian.net/wiki/spaces/UCV/overview': 'https://integra-scm.atlassian.net/wiki/spaces/UCVEN/overview'"
            class="docsLink"
            target="blank"
          >
            <div style="padding: 10px 25px 10px 15px;">
              <span>
                <v-icon class="pr-2">mdi-information</v-icon>
                {{ $i18n.t("SETTING.DOCS") }}
              </span>
            </div>
          </a>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-card class="px-2 py-2 room-card" v-bind:class="{'smallSettings':incall}" v-else>
      <v-list style="padding: 3px 0px 0px 0px;">
        <v-list-item two-line>
          <v-icon size="32">mdi-cog</v-icon>
          <v-list-item-content class="pl-4">
            <v-list-item-title
              style="font-size: 14px; font-weight: 400; opacity: 0.9;"
            >{{$i18n.t("SETTING.title")}}</v-list-item-title>
            <v-list-item-subtitle
              style="font-size: 12px; font-weight: 300; opacity: 0.9;"
            >{{$i18n.t("SETTING.ROOMCONFIG")}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-row no-gutters class="px-6" style="height: 50px;">
        <v-col
          cols="6"
          v-if="$store.state.Auth.isLogged"
          style="display: flex; justify-content: space-evenly;"
        >
          <span
            class="pr-4 pt-4"
            style="font-size: 14px; font-weight: 400; opacity: 0.9;"
          >{{$i18n.t("SETTING.SUBTITLEON")}}</span>
          <v-switch
            class="pt-4"
            style="margin-top: 0px !important;"
            color="primary"
            :disabled="checkSafari()"
            @change="setSubtitles"
            :input-value="$store.state.room_configs.subtitles"
          ></v-switch>
        </v-col>
        <v-col
          cols="6"
          v-if="$store.state.Auth.isLogged"
          style="display: flex; justify-content: space-evenly;"
        >
          <span
            class="pr-4 pt-4"
            style="font-size: 14px; font-weight: 400; opacity: 0.9;"
          >{{$i18n.t('SETTING.MIRROR')}}</span>
          <v-switch
            :disabled="!$store.state.room_configs.subtitles || checkSafari()"
            class="pt-4"
            style="margin-top: 0px !important;"
            color="primary"
            @change="setSubtitlesMirror"
            :input-value="$store.state.room_configs.mirrorSubtitles"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-subheader>{{$i18n.t("SETTING.VIDEODEF")}}</v-subheader>

          <v-slider
            @change="setVideoQuality"
            :value="$store.state.room_configs.video_quality"
            :tick-labels="ticksLabels"
            :max="2"
            step="1"
            ticks="always"
            tick-size="4"
          ></v-slider>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            prepend-inner-icon="mdi-message-video"
            :items="videoSources"
            @change="setVideoInput"
            :value="$store.state.videoDeviceLabel"
            item-text="label"
            item-value="deviceId"
            dense
            filled
            rounded
            :label="$i18n.t('SETTING.CAMERASOURCE')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            :value="$store.state.audioInputDeviceLabel"
            prepend-inner-icon="mdi-microphone"
            :items="audioSources"
            item-text="label"
            item-value="deviceId"
            @change="setAudioInput"
            dense
            filled
            rounded
            :label="$i18n.t('SETTING.MICSOURCE')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" v-if="$store.state.Auth.isLogged">
          <v-divider style="width:100%"></v-divider>
          <v-chip class="ma-2" small color="primary lighten-1">BETA</v-chip>
          <span
            class="pr-4 pt-4"
            style="font-size: 14px; font-weight: 400; opacity: 0.9; display:inline-block"
          >{{$i18n.t('SETTING.BACKGROUNDBLUR')}}</span>
          <v-switch
            class="pt-4"
            style="margin-top: 0px !important;display:inline-block"
            color="primary"
            @change="toggleBackgroundBlur"
            :input-value="$store.state.room_configs.backgroundBlur"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <v-chip class="ma-2" small color="primary lighten-1">BETA</v-chip>
          <span
            class="pr-4 pt-4"
            style="font-size: 14px; font-weight: 400; opacity: 0.9; display:inline-block"
          >{{$i18n.t('SETTING.VIRTUALBACKGROUND')}}</span>
          <v-switch
            class="pt-4"
            style="margin-top: 0px !important;display:inline-block"
            color="primary"
            @change="toggleVirtualBackgroundBlur"
            :input-value="$store.state.room_configs.virtualBackground? true: false"
          ></v-switch>

          <img
            v-show="$store.state.room_configs.virtualBackground != false && $store.state.room_configs.virtualBackground !=null && $store.state.room_configs.virtualBackground !=true"
            style="width: 150px;
    position: absolute;
    top: -55px;"
            :src="$store.state.room_configs.virtualBackground != false && $store.state.room_configs.virtualBackground !=null? $store.state.room_configs.virtualBackground: null"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="modalChangePassword" max-width="500">
      <v-card>
        <v-card-title class="justify-center" style="color: #525252;">
          <v-icon class="mr-2">mdi-lock</v-icon>
          {{$i18n.t("SETTING.EDITPASS")}}
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pa-4">
          <v-text-field
            prepend-icon="mdi-lock"
            filled
            rounded
            dense
            v-model="actualPassword"
            type="password"
            :rules="actualPasswordRule"
            :label="$i18n.t('SETTING.ACTUALPASS')"
            required
          ></v-text-field>
          <v-divider></v-divider>

          <v-text-field
            prepend-icon="mdi-lock"
            filled
            rounded
            dense
            v-model="newPassword"
            type="password"
            :rules="newPasswordRule"
            :label="$i18n.t('SETTING.NEWPASS')"
            required
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-lock"
            filled
            rounded
            dense
            v-model="newPasswordConfirm"
            type="password"
            :rules="validateSamePassword"
            :label="$i18n.t('SETTING.CONFNEWPASS')"
            required
          ></v-text-field>

          <v-btn
            style="float:right"
            :loading="loadingButton"
            :disabled="!valid"
            color="primary"
            class="mr-4"
            @click="changePassword"
          >{{$i18n.t("MEETING.confirm")}}</v-btn>
          <v-spacer style="clear:both"></v-spacer>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRoomSettings" width="500">
      <v-list style="padding: 3px 0px 0px 0px;background-color:var(--v-primary-base) !important;">
        <v-list-item dark two-line>
          <v-icon size="32">mdi-cog</v-icon>
          <v-list-item-content class="pl-4">
            <v-list-item-title
              style="font-size: 14px; font-weight: 400; opacity: 0.9;"
            >{{$i18n.t("SETTING.title")}}</v-list-item-title>
            <v-list-item-subtitle
              style="font-size: 12px; font-weight: 300; opacity: 0.9;"
            >{{$i18n.t("SETTING.ROOMCONFIG")}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-tabs background-color="primary" right dark>
        <v-tab>
          <v-icon left>mdi-account</v-icon>
          {{$i18n.t("SETTING.ROOM")}}
        </v-tab>
        <v-tab @click="bindMedia">
          <v-icon left>mdi-camera</v-icon>
          <v-icon left>mdi-microphone</v-icon>Media
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-row no-gutters class="px-6 pt-2">
              <v-col cols="6" v-if="$store.state.Auth.isLogged" style="display: flex;">
                <span
                  class="pr-4 pt-4 pl-8"
                  style="font-size: 14px; font-weight: 400; opacity: 0.9;"
                >{{$i18n.t("SETTING.SUBTITLEON")}}</span>
                <v-switch
                  class="pt-4"
                  :disabled="checkSafari()"
                  style="margin-top: 0px !important;"
                  color="primary"
                  @change="setSubtitles"
                  :input-value="$store.state.room_configs.subtitles"
                ></v-switch>
              </v-col>
              <v-col cols="6" v-if="$store.state.Auth.isLogged" style="display: flex; ">
                <span
                  class="pr-4 pt-4 pl-8"
                  style="font-size: 14px; font-weight: 400; opacity: 0.9;"
                >{{$i18n.t('SETTING.MIRROR')}}</span>
                <v-switch
                  :disabled="!$store.state.room_configs.subtitles || checkSafari()"
                  class="pt-4"
                  style="margin-top: 0px !important;"
                  color="primary"
                  @change="setSubtitlesMirror"
                  :input-value="$store.state.room_configs.mirrorSubtitles"
                ></v-switch>
              </v-col>

              <v-col
                cols="12"
                v-show="$store.state.Auth.isLogged && $store.state.permissions &&  $store.state.permissions.video &&  $store.state.permissions.audio"
              >
                <v-divider class="mt-0 mb-0"></v-divider>
                <span
                  class="pr-4 pt-4 pl-8"
                  style="font-size: 14px; font-weight: 400; opacity: 0.9; display:inline-block"
                >{{$i18n.t("SETTING.ONLYAUDIO")}}</span>
                <v-switch
                  class="pt-4"
                  style="margin-top: 0px !important;display:inline-block"
                  color="primary"
                  @change="toggleStartOnlyAudio"
                  :input-value="$store.state.room_configs.onlyAudio"
                ></v-switch>
                <v-divider class="mt-0 mb-0"></v-divider>
              </v-col>
              <v-col cols="12">
                <v-subheader class="pl-8">{{$i18n.t("SETTING.VIDEODEF")}}</v-subheader>

                <v-slider
                  class="pl-8 pr-8"
                  @change="setVideoQuality"
                  :value="$store.state.room_configs.video_quality"
                  :tick-labels="ticksLabels"
                  :max="2"
                  step="1"
                  ticks="always"
                  tick-size="4"
                ></v-slider>
                <v-divider class="mt-0 mb-0"></v-divider>
              </v-col>
              <v-col cols="12">
                <v-subheader class="pl-8">{{$i18n.t("SETTING.RECORDINGDEF")}}</v-subheader>

                <v-slider 
                  class="pl-8 pr-8 mb-3"
                  @change="setVideoRecordingQuality"
                  :value="$store.state.room_configs.rec_quality"
                  :tick-labels="ticksLabels"
                  :max="2"
                  step="1"
                  ticks="always"
                  tick-size="4"
                ></v-slider>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-row no-gutters class="px-6 pt-5">
              <v-col cols="12">
                <v-autocomplete
                  prepend-inner-icon="mdi-message-video"
                  :items="videoSources"
                  @change="setVideoInput"
                  :value="$store.state.videoDeviceLabel"
                  item-text="label"
                  item-value="deviceId"
                  dense
                  filled
                  rounded
                  :label="$i18n.t('SETTING.CAMERASOURCE')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :value="$store.state.audioInputDeviceLabel"
                  prepend-inner-icon="mdi-microphone"
                  :items="audioSources"
                  item-text="label"
                  item-value="deviceId"
                  @change="setAudioInput"
                  dense
                  filled
                  rounded
                                    :label="$i18n.t('SETTING.MICSOURCE')"

                ></v-autocomplete>
              </v-col>

              <v-col cols="12" v-if="$store.state.Auth.isLogged">
                <v-divider style="width:100%" class="mt-0 mb-0"></v-divider>
                <v-chip class="ma-2" small color="primary lighten-1">BETA</v-chip>
                <span
                  class="pr-4 pt-4"
                  style="font-size: 14px; font-weight: 400; opacity: 0.9; display:inline-block"
                >{{$i18n.t('SETTING.BACKGROUNDBLUR')}}</span>
                <v-switch
                  class="pt-4"
                  style="margin-top: 0px !important;display:inline-block"
                  color="primary"
                  @change="toggleBackgroundBlur"
                  :input-value="$store.state.room_configs.backgroundBlur"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-chip class="ma-2" small color="primary lighten-1">BETA</v-chip>
                <span
                  class="pr-4 pt-4"
                  style="font-size: 14px; font-weight: 400; opacity: 0.9; display:inline-block"
                >{{$i18n.t('SETTING.VIRTUALBACKGROUND')}}</span>
                <v-switch
                  class="pt-4"
                  style="margin-top: 0px !important;display:inline-block"
                  color="primary"
                  @change="toggleVirtualBackgroundBlur"
                  :input-value="$store.state.room_configs.virtualBackground? true: false"
                ></v-switch>
                <input style="display:none;"
                  class="mb-3"
                  :disabled="!$store.state.room_configs.virtualBackground"
                  type="file"
                  ref="myfile"
                  id="myfile"
                />


        <v-hover v-show="$store.state.room_configs.virtualBackground" v-slot:default="{ hover }" style="width:150px;height:112.5px;position:absolute;right:10px;bottom:2px;">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover ,'hover-vcard':true}"
            >
              <v-img 
                :src="$store.state.room_configs.virtualBackground && $store.state.room_configs.virtualBackground !=true ? $store.state.room_configs.virtualBackground: ''"
             
              >
                <v-card-title class="title white--text">
                  <v-row
                    class="fill-height flex-column"
                    justify="space-between"
                  >
              

                    <div class="align-self-center">
                      <v-btn style="font-size: 10px; display:none;margin-top:20px;"
                        :class="{ 'show-btns': hover }"
                        color="primary"
                        
                        @click="askForFiles"
                      >Change <br> background
                      <v-icon>mdi-paperclip</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>

              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
  </v-menu>
</template>

<script>
import Login from '../models/login';



export default {
  props:{
    incall:Boolean
  },
  data(){
    return {
      icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      item:{
          title: 'New Releases',
          text: "It's New Release Friday",
          subtext: 'Newly released songs. Updated daily.',
          img: 'http://lorempixel.com/output/nightlife-q-c-640-480-5.jpg',
        },
      urlBlob:null,
      ticksLabels: [
        this.$i18n.t("SETTING.VIDEOLOW"),
        this.$i18n.t("SETTING.VIDEOSTANDARD"),
        this.$i18n.t("SETTING.VIDEOHIGH")
        ],
      dialogRoomSettings:false,
      colorGroups: [
        "#003B4D",
        "#F4B223",
        "#FD495C",
        "#6BCBB8",
        "#ABC8E7",
        "#7B868C",
      ],
      language: this.$store.state.lang=='es'?0:1,
      modalChangePassword: false,
      loadingButton: false,
      langs: [
        { key: "es", value: "Español" },
        { key: "en", value: "English" },
      ],
      valid: false,
      
      actualPassword: '',
      newPassword: '',
      newPasswordConfirm: '',

      actualPasswordRule: [(v) => !!v || "Required"],
      newPasswordRule: [(v) => !!v || "Required"],
      videoSource:null,
      videoSources:[],
      audioInput:null,
      audioSources:[]

    }
  },
  computed:{
    validateSamePassword(){
      return [
        (v) => v === this.newPassword || 'Passwords has to be the same'
      ]
    }
  },
  mounted(){
    this.init()
  },  
  methods: {
    askForFiles() {      
      this.$refs.myfile.click();
    },
    bindMedia(){
      setTimeout(() => {
        document.getElementById('myfile').addEventListener('change', (event) => {
                  var ruta = event.target.value;
                    var fileName = ruta.replace(/^.*\\/, "");
                    var archivo = event.target.files[0];
                    if (archivo) {
                        this.readImage(archivo,fileName);
                    } else {
                      alert('error imagen1')
                    }
              });
      }, 1000);

    },
    showDialogSettings(){
      this.dialogRoomSettings= true;
    },
    readImage(file,fileName) {
		        var reader = new FileReader();
		        reader.readAsDataURL(file);
		        reader.onload =  (files) => {
		            //processor.image.src = files.target.result;
		            const img = new Image();
        			img.src = files.target.result;
        			img.onload = () => {
	                const elem = document.getElementById('imgResize-virtual-background');
	                elem.width = 640;
	                elem.height = 480;
	                const ctx = elem.getContext('2d');
	                // img.width and img.height will contain the original dimensions
	                ctx.drawImage(img, 0, 0, 640, 480);
	                ctx.canvas.toBlob((blob) => {
                      let objectURL = URL.createObjectURL(blob);      
                      this.$store.commit("SET_VIRTUAL_BACKGROUND",objectURL);
                      document.getElementById('img-virtual-background').src=objectURL;
	                }, 'image/png', 1);
	            }
			    };

		    },
    toggleStartOnlyAudio(val){
      this.$store.commit("TOGGLE_ONLY_AUDIO",val);
    },
    toggleBackgroundBlur(val){
      this.$store.commit("TOGGLE_BACKGROUND_BLUR",val);
    },
    toggleVirtualBackgroundBlur(val){
      this.$store.commit("SET_VIRTUAL_BACKGROUND",val);
    },
    setVideoQuality(val){
      this.$store.commit("SET_VIDEO_QUALITY",{
        value:val
      });
    },
    setVideoRecordingQuality(val){
      this.$store.commit("SET_VIDEO_REC_QUALITY",{
        value:val
      });
    },
    setVideoInput(val){
      this.$store.commit("SET_INPUT_CONFIG",{
        value:val,
        input:"video"
      });
    },
    setAudioInput(val){
      this.$store.commit("SET_INPUT_CONFIG",{
        value:val,
        input:"audio"
      });
    },
    async init(){
      let devices = await this.getDeviceIdForLabel(null);
      this.videoSources = devices.filter(
        device => device.kind === "videoinput"
      );
      this.audioSources = devices.filter(
        device => device.kind === "audioinput"
      )

      this.$store.commit("SET_AVAILABLE_DEVICES",devices)

    },
    async changePassword() {
      let form = this.$refs.form;
      if (form.validate()) {
        this.loadingButton = true;
        
        let user = this.$store.state.Auth.token.claims.sub;
        let result = await Login.changePassword(user, this.actualPassword, this.newPassword);
        if(result){
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: "Password succesfully changed",
          });
        }else{
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "Invalid credentials",
          });
        }
        this.loadingButton = false;
        this.modalChangePassword = false;
      }
    },
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch("changeTheme", this.$vuetify.theme.dark);
    },

    setSubtitles(){
      this.$store.commit("TOGGLE_CONFIG_SUBTITLES");
    },

    setSubtitlesMirror(){
      this.$store.commit("TOGGLE_CONFIG_SUBTITLES_MIRROR")
    },

    setColor(color) {
      this.$vuetify.theme.themes.dark.primary = color;
      this.$vuetify.theme.themes.light.primary = color;
      this.$store.dispatch("changePrimary", color);
    },

    async setLang(lang) {
      await this.$store.dispatch("changeLang", lang);
    },

    async getDeviceIdForLabel(cameraLabel) {
      const videoInputs = await this.getVideoInputs();

      for (let i = 0; i < videoInputs.length; i++) {
        const videoInput = videoInputs[i];
        if (videoInput.label === cameraLabel) {
          return videoInput.deviceId;
        }
      }

      return videoInputs;
    },
    async getVideoInputs() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        //console.log("enumerateDevices() not supported.");
        return [];
      }

      const devices = await navigator.mediaDevices.enumerateDevices();

      return devices;
    },
    checkSafari() {
      return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
    }
  }
}
</script>
<style scoped>
.hover-vcard {
  transition: opacity .4s ease-in-out;
}

.hover-vcard:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  display:block !important;
}
</style>

<style lang="scss">
.apparence-card {
  width: 300px;
  height: 320px;
}
.room-card {
  width: 350px;
}
.smallSettings {
  height: 604px !important;
}
.apparence-card__item {
  width: 85px !important;
  height: 30px !important;
  transition: all 0.3s;

  .hover-div {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: all 0.3s;
    cursor: pointer;

    i {
      color: #fff;
      margin-top: 8px;
      margin: 10px 12px;
    }
  }
}

.apparence-card__item:hover {
  .hover-div {
    opacity: 100;
  }
}
.docsLink:hover {
  text-decoration: none;
}
</style>
