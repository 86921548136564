const VideoStreamMerger = require("video-stream-merger");
import * as EBML from "ts-ebml";
import firebase from "./../firebase";
import store from "./../store/index";
import Meeting from "./../models/meeting.js"
const moment = require('moment');

export default class VideoRecorder {
  constructor(meeting) {

    this.meeting = meeting;
    this.record = false;
    this.merger = null;
    this.recordedChunks = [];
    this.isInCall = false;

    this.audioContext;
    this.mediaRecorder = null;
    this.members = 0;
    this.videoFormat = 'video/webm; codecs="vp8, opus"';

    this.imageBlobs = [];
  }

  initMergerAndRecorder() {
    this.localVideo = document.getElementById("localVideo");
    this.remoteVideo = document.getElementById("remoteVideo");

    this.audioContext = new AudioContext();
    let remoteWidth = 320, remoteHeight = 180;
    let localWidth = 64, localHeight = 36;
    let subtractImage = 180;

    if (store.state.room_configs.rec_quality == 1) {
      remoteWidth = remoteWidth * 2
      remoteHeight = remoteHeight * 2
      localWidth = localWidth * 2
      localHeight = localHeight * 2
      subtractImage = subtractImage * 2
    }

    if (store.state.room_configs.rec_quality == 2) {
      remoteWidth = remoteWidth * 4
      remoteHeight = remoteHeight * 4
      localWidth = localWidth * 4
      localHeight = localHeight * 4
      subtractImage = subtractImage * 4

    }

    this.merger = new VideoStreamMerger({
      width: remoteWidth,
      height: remoteHeight,
      fps: 25,
      audioContext: this.audioContext
    });

    var panNodeL = this.audioContext.createStereoPanner();
    panNodeL.pan.value = -1;

    var panNodeR = this.audioContext.createStereoPanner();
    panNodeR.pan.value = 1;

    this.merger.addStream(this.remoteVideo.srcObject, {
      x: 0, // position of the topleft corner
      y: 0,
      width: this.merger.width,
      height: this.merger.height,
      mute: false,
      audioEffect: function (sourceNode, destinationNode) {
        sourceNode.connect(panNodeL)
        panNodeL.connect(destinationNode)
      },
      draw: (ctx, frame, done) => {
      
        var scale = Math.min(ctx.canvas.width / frame.clientWidth, ctx.canvas.height / frame.clientHeight);
        // get the top left position of the image
        var x = (ctx.canvas.width / 2) - (frame.clientWidth / 2) * scale;
        var y = (ctx.canvas.height / 2) - (frame.clientHeight/ 2) * scale;
        ctx.drawImage(frame, x, y, frame.clientWidth* scale, frame.clientHeight* scale);
       
        done()
      }
    });

    

    this.merger.addStream(this.localVideo.srcObject, {
      x: 0,
      y: this.merger.height - subtractImage,
      width: localWidth,
      height: localHeight,
      mute: false,
      audioEffect: function (sourceNode, destinationNode) {
        sourceNode.connect(panNodeR)
        panNodeR.connect(destinationNode)
      }
    });

    this.merger.start();

    const options = { mimeType: this.videoFormat };

    this.mediaRecorder = new MediaRecorder(this.merger.result, options);
    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    };

  }

  async processChunks() {
    if (this.recordedChunks.length > 0) {
      const blob = new Blob(this.recordedChunks, {
        type: this.videoFormat,
      });

      const seekBlob = await this.getSeekableBlob(blob);

      if (this.getDownloadMethod() === "download") {
        this.downloadFile(seekBlob);
      } else if (this.getDownloadMethod("cloudstorage")) {
        this.uploadFile(seekBlob, 'webm');
        this.recordedChunks = []
      }
    }
  }

  startRecording() {
    this.mediaRecorder.start(10000);
    this.record = true;
    console.debug("Started Recording...");
  }

  resumeRecording() {
    this.mediaRecorder.resume();
    this.record = true;
    console.debug("Resume Recording...");
  }

  pauseRecording() {
    this.mediaRecorder.pause();
    this.record = false;
    console.debug("Paused Recording...");
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.merger.destroy();
    this.merger = null;
    this.mediaRecorder = null;
    this.record = false;
    this.processChunks();
    console.debug("Stopped Recording...");
  }

  Record() {
    if (this.record) {
      this.pauseRecording();
    } else {
      if (this.merger == null && this.mediaRecorder == null) {
        this.initMergerAndRecorder();
        this.startRecording();
      } else {
        this.resumeRecording()

      }
    }
  }

  startCall() {
    this.isInCall = true;
  }

  async finishCall() {
    this.isInCall = false;
    if (this.imageBlobs.length > 0) {
      for (let i = 0; i < this.imageBlobs.length; i++) {
        let image = this.imageBlobs[i];
        let resp = await this.uploadFile(image.blob, image.format);
      }

      this.imageBlobs = [];
    }

    if (this.merger != null && this.mediaRecorder != null) {
      this.stopRecording();
    }
  }

  rotateRecording(isportrait) {
   /*  let width;
    let height;

    if(isportrait) {
      width = 360;
      height = 640;
    } else {
      width = 640;
      height = 360;
    }
    this.merger.setOutputSize(width, height);// this is changing only canvas and not the stream inside
 */
  }

  addImage(blob) {
    this.imageBlobs.push(blob);
  }

  downloadFile(blob) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = "recording.webm";
    a.click();
    window.URL.revokeObjectURL(url);
    this.merger.destroy();
    this.recordedChunks = [];
  }


  async uploadFile(blob, format) {

    return new Promise((resolve, reject) => {

      const metadata = {
        contentType: format == 'webm' ? this.videoFormat : 'image/png',
        customMetadata: {
          company: this.meeting.company,
          userid: this.meeting.userid,
          id: this.meeting.idmeeting,
        },
      };

      const timestamp = this.meeting.startdate.substring(0, 8);


      if (!this.meeting.recordings) {
        this.meeting.recordings = [];
      }


      let datetime =  moment().format('YYYY-MM-DD_HH:mm:ss:SSS');
      let recordingName = datetime;


      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(
          this.meeting.company +
          "/" +
          this.meeting.userid +
          "/" +
          timestamp +
          "/" +
          recordingName +
          "." + format
        )
        .put(blob, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed", // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setUploadProgress(progress);
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused": // or 'paused'
              console.log("Upload is paused");
              break;
            case "running": // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          reject()
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            let meetingUpdate = this.meeting;
            meetingUpdate.recordings.push({
              name: recordingName + '.' + format,
              size: blob.size
            }); 

            //meetingUpdate.recordings.push((recordingName+'.'+format));

            await Meeting.updateMeetingRecording(new Meeting(meetingUpdate));
            await Meeting.updateCompanyAndUserStorage(new Meeting(meetingUpdate), blob.size)
            //Meeting.transcriptRecording(new Meeting(meetingUpdate))
            if (format == 'webm') {
              this.setDownloadLink(downloadURL);
            }

            resolve(downloadURL)
          });
        }
      );


    })

  }

  getDownloadMethod() {
    return store.state.Auth.token.claims.recordings || "download";
  }

  setUploadProgress(progress) {
    store.commit("SET_ACTUAL_UPLOAD", {
      idmeeting: this.meeting.idmeeting,
      progress: progress,
    });
  }

  setDownloadLink(downloadURL) {
    store.commit("SET_ACTUAL_UPLOAD_URL", {
      idmeeting: this.meeting.idmeeting,
      url: downloadURL,
    });
  }

  async getSeekableBlob(inputBlob) {
    return new Promise((resolve) => {
      const reader = new EBML.Reader();
      const decoder = new EBML.Decoder();
      const tools = EBML.tools;
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const ebmlElms = decoder.decode(event.target.result);

        ebmlElms.forEach(function (element) {
          reader.read(element);
        });
        reader.stop();
        const refinedMetadataBuf = tools.makeMetadataSeekable(
          reader.metadatas,
          reader.duration,
          reader.cues
        );
        const body = event.target.result.slice(reader.metadataSize);
        const newBlob = new Blob([refinedMetadataBuf, body], {
          type: this.videoFormat,
        });
        resolve(newBlob);
      };
      fileReader.readAsArrayBuffer(inputBlob);
    });
  }
}
