<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on" @click.prevent="$router.push('/admin')">
          <v-icon size="30">mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>{{ $i18n.t("TABLE.back")}}</span>
    </v-tooltip>
    <Table
      :data="users"
      :headers="headers"
      :loading="loading"
      :icon="'mdi-account'"
      :title="'fullname'"
      :subtitle="'type'"
      :subtitleIcon="'mdi-account-box'"
      :acceptCompanies="true"
      :companies="companies"
      @save="showSave"
      @add="showAdd"
      @delete="showDelete"
      @getCompany="getUsers"
    ></Table>

    <v-dialog
      v-model="dialogs.add"
      persistent
      width="650px"
      overlay-opacity="0.5"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
    >
      <v-card class="save-card px-8 pb-4 pt-8">
        <v-card-text class="">
          <v-row no-gutters class="px-2">
            <v-col cols="12" class="px-2">
              <v-form ref="add-form" lazy-validation>
                <v-text-field
                  filled
                  dense
                  rounded
                  v-model.trim="user.id"
                  :rules="[rules.required]"
                  prepend-icon="mdi-identifier"
                  :suffix="user.id + '@' + companySelected"
                  :label="$i18n.t('COMPANY.idLabel')"
                  :disabled="(dialogs.save == true && !dialogs.add == false)"
                ></v-text-field>
                <v-row no-gutters>
                  <v-col cols="6" class="pr-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      v-model.trim="user.fullname"
                      :rules="[rules.required]"
                      prepend-icon="mdi-account"
                      :label="$i18n.t('USER.nameLabel')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      :rules="this.dialogs.add && this.dialogs.save ? []:[rules.required]"
                      type="password"
                      v-model.trim="user.password"
                      prepend-icon="mdi-lock"
                      :label="$i18n.t('USER.passwordLabel')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6" class="pr-2">
                    <v-select
                      v-model="user.recordings"
                      filled
                      :rules="[rules.required]"
                      rounded
                      :items="recordingTypes"
                      :label="$i18n.t('USER.recordingUpdateLabel')"
                      prepend-icon="mdi-record-rec"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="pr-2">
                    <v-select
                      v-model="user.recordEnabled"
                      filled
                      :rules="[rules.required]"
                      rounded
                      :items="recordTypes"
                      :label="$i18n.t('USER.recordingLabel')"
                      prepend-icon="mdi-record-rec"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="pl-2">
                    <v-select
                      v-model="user.type"
                      filled
                      :rules="[rules.required]"
                      rounded
                      :items="userTypes"
                      :label="$i18n.t('USER.typeLabel')"
                      prepend-icon="mdi-account-box"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6" class="pr-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      v-model.trim="user.email"
                      prepend-icon="mdi-email"
                      :label="$i18n.t('USER.mailLabel')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <vue-tel-input
                      ref="telephoneNumber"
                      :placeholder="$i18n.t('USER.phoneLabel')"
                      @input="onInputPhone"
                      v-model="user.phone"
                    ></vue-tel-input>
                    <label style="color:red" v-show="errorPhone">{{$i18n.t('USER.INVALIDPHONE')}}</label>
                  </v-col>
                </v-row>

                <v-autocomplete
                  :items="langs"
                  filled
                  dense
                  rounded
                  v-model="user.lang"
                  prepend-icon="mdi-web"
                  :label="$i18n.t('SETTING.lenguage')"
                ></v-autocomplete>
                <v-textarea
                  filled
                  rows="4"
                  dense
                  rounded
                  v-model="user.description"
                  prepend-icon="mdi-card-text"
                  :label="$i18n.t('USER.descriptionLabel')"
                ></v-textarea>
                <v-switch
                  style="position:absolute;z-index:2;"
                  v-model="user.disabled"
                  :label="$i18n.t('ADMIN.disabled')"
                ></v-switch>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" style="text-align: end;">
              <v-btn text class="mr-1" @click="closeAdd">{{ $i18n.t("GENERAL.close") }}</v-btn>
              <v-btn
                v-if="dialogs.add && !dialogs.save"
                depressed
                :loading="buttonLoader"
                color="green"
                dark
                @click.prevent="add"
              >
                {{
                $i18n.t("GENERAL.add")
                }}
              </v-btn>
              <v-btn
                v-else
                depressed
                :loading="buttonLoader"
                color="green"
                dark
                @click.prevent="save"
              >{{ $i18n.t("GENERAL.save") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Delete
      v-model="this.dialogs.delete"
      @remove="remove"
      @close="closeDelete"
      :loading="buttonLoader"
    ></Delete>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import User from "@/models/user.js";
import Company from "@/models/company.js";
import { mapState } from "vuex";
import store from "@/store/index";
import Delete from "@/components/Delete.vue";

export default {
  components: {
    Table,
    Delete,
  },
  data() {
    return {
      langs:[
        {
          text:"English (EE.UU.)",
          value:"en-US"
        },
        {
          text:"English (Australia)",
          value:"en-AU"
        },
        {
          text:"English (India)",
          value:"en-IN"
        },
        {
          text:"English (United Kingdom)",
          value:"en-GB"
        },
        {
          text:"Español (España)",
          value:"es-ES"
        },
        {
          text:"Español (Argentina)",
          value:"es-AR"
        },
        {
          text:"Español (Bolivia)",
          value:"es-BO"
        },
        {
          text:"Español (Chile)",
          value:"es-CL"
        },
        {
          text:"Español (Colombia)",
          value:"es-CO"
        },
        {
          text:"Español (Costa Rica)",
          value:"es-CR"
        },
        {
          text:"Español (Ecuador)",
          value:"es-EC"
        },
        {
          text:"Español (Uruguay)",
          value:"es-UY"
        },
        {
          text:"Español (Guatemala)",
          value:"es-GT"
        },
        {
          text:"Español (Honduras)",
          value:"es-HN"
        },{
          text:"Español (México)",
          value:"es-MX"
        },{
          text:"Español (Nicaragua)",
          value:"es-NI"
        },{
          text:"Español (Panamá)",
          value:"es-PA"
        },{
          text:"Español (Paraguay)",
          value:"es-PY"
        },{
          text:"Español (Perú)",
          value:"es-PE"
        },{
          text:"Español (Puerto Rico)",
          value:"es-PR"
        },{
          text:"Español (Republica Dominicana)",
          value:"es-DO"
        },{
          text:"Español (Venezuela)",
          value:"es-VE"
        }

      ],
      headers: ["fullname", "type", "description"],
      loading: false,
      userTypes: ["admin", "agent"],
      recordingTypes: ["download", "cloudstorage"],
      recordTypes:['disabled','both','only agent'],
      companySelected: "",
      user: {},
      users:[],
      companies:[],
      buttonLoader: false,
      rules: {
        required: (value) => !!value || "required",
         required: (value) => !!value || "required",
      },
      dialogs: {
        delete: false,
        add: false,
        save: false,
      },
      errorPhone: false,
    };
  },

  created() {
    this.getCompanies();
    
    if (this.$store.state.Auth.token.claims.type == "superuser") {
      this.userTypes.push("superuser");
    }

  },
  methods: {
    validatePhoneInput() {
      if (
        this.user.phone == "" ||
        this.user.phone == null ||
        this.user.phone == undefined
      ) {
        return true;
      }
      let isValid = this.$refs.telephoneNumber.phoneObject.isValid;
      isValid ? (this.errorPhone = false) : (this.errorPhone = true);
      return isValid;
    },

    onInputPhone(val, obj) {
      let value = val;
      let isValid = obj.isValid;
      this.validatePhoneInput();
    },

    async getUsers(companyId) {
      try {
        this.companySelected = companyId;
        this.users = await User.getAll(companyId);
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching users",
        });
      }
    },

    async getCompanies(){
      try {
        this.companies = await Company.getAll();
        if(this.$store.state.Auth.token.claims.type == 'admin'){
           this.companySelected = this.companies[0].id;
           await this.getUsers(this.companySelected);
        }
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: error,
        });
      }
    },

    async remove() {
      this.buttonLoader = true;
      try {
        await User.remove(this.user, this.companySelected);
        this.getUsers(this.companySelected);
        this.closeDelete();
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "DELETE",
          text: "User removed successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "DELETE",
          text: "Error in remove user",
        });
      }
      this.buttonLoader = false;
    },

    async add() {
      this.buttonLoader = true;

      if (this.$refs["add-form"].validate() && this.validatePhoneInput()) {
        this.user.phone = this.$refs.telephoneNumber.phoneObject.number.e164;
        try {
          const user = new User(this.user);
          await User.add(user, this.companySelected);
          this.getUsers(this.companySelected);
          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text:this.$i18n.t('ADMIN.ADDSUCCESS'),
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: this.$i18n.t('ADMIN.ADDERROR'),
          });
        }
      }
      this.buttonLoader = false;
    },

    async save() {
      this.buttonLoader = true;
      if (this.$refs["add-form"].validate()) {
        try {
          const user = new User(this.user);
          await User.update(user, this.companySelected);
          this.getUsers(this.companySelected);
          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "SAVE",
            text: "User updated successfully",
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "SAVE",
            text: "Error in save user",
          });
        }
      }
      this.buttonLoader = false;
    },

    closeDelete() {
      this.dialogs.delete = !this.dialogs.delete;
    },

    closeAdd() {
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.$refs["add-form"].reset();
    },

    showDelete(user) {
      this.dialogs.delete = !this.dialogs.delete;
      this.user = user;
    },
    showAdd() {
      if (this.$refs["add-form"]) {
        this.$refs["add-form"].reset();
      }
      this.user = new User({});
      this.dialogs.add = !this.dialogs.add;
    },
    showSave(user) {
      this.user = new User(user);
      this.dialogs.add = true;
      this.dialogs.save = true;
    },
  },
};
</script>

<style lang="scss">
.save-card {
  border-top: 3px solid var(--v-primary-base) !important;
}

.vti__dropdown-list {
  left: -81px !important;
  padding: 0px !important;
}

.vue-tel-input {
  border-radius: 64px !important;
  height: 55px !important;
  display: flex !important;
  border: 0px solid #bbb !important;
  text-align: left !important;
  background: #f0f0f0 !important;
}

.vue-tel-input:focus {
  outline: 0px !important;
  box-shadow: 0px 0px !important;
}
.vti__dropdown:focus {
  outline: 0px !important;
  box-shadow: 0px 0px !important;
}
.vti__dropdown:hover {
  background-color: #f3f3f3 !important;
  border-radius: 54px !important;
}
.vti__dropdown.open {
  background-color: #f3f3f3 !important;
  border-radius: 54px !important;
}

.theme--dark .vue-tel-input{
  background-color: #272727 !important;
}

.theme--dark .vti__dropdown-list{
   background-color: #272727 !important;
}

.theme--dark .vti__dropdown-item.highlighted{
  background-color: #303030 !important;
}

.theme--dark .vti__dropdown.open{
  background-color: #303030 !important;
}

.theme--dark .vti__input{
  color:white;
}
</style>