import Meeting from "@/models/meeting.js";
import moment from 'moment';
export const namespaced = true;

export const state = {
  meetings: {}
};

export const mutations = {
  SET_MEETINGS(state, value) {
    state.meetings = value
  }
};

export const actions = {
  async getMeetings({ commit }, obj) {
     let objMeetings = await getDayMeetings(obj.user, obj.company);
     commit("SET_MEETINGS", objMeetings);
  }
};

const getDayMeetings = async (user, company) => {
    let meetings = await Meeting.getMeetingsInADay(user, company, moment().format('YYYYMMDD'));
    let meetingsObj = convertArrayToObject(meetings, 'idmeeting');
    return meetingsObj;
}

const convertArrayToObject = async (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
        ...obj,
        [item[key]]: item,
        };
    }, initialValue);
}
