<template>
  <div>
    <v-row class="room-row">
      <v-col>
        <img
          style="width: 40%;
    margin: auto;
    display: block;"
          :src="require('../../src/assets/videocall.svg')"
          alt="Image not found"
        />
        <p>{{$i18n.t("DISPOSITIONS.TITLE")}}</p>
        <div class="text-center">
          <v-rating
            half-icon="mdi-star-half-full"
            half-increments
            background-color="grey"
            v-model="rating"
            @input="inputRating"
          ></v-rating>
        </div>
        <v-btn
          color="primary"
          style="margin-top:10px"
          dark
          :to="`/room?id=${$route.query.id}&u=${$route.query.u}&c=${$route.query.c}&d=${$route.query.d}`"
        >{{$i18n.t("ROOM.BACKTOCALL")}}</v-btn>
        <v-btn
          style="margin-left:15px;margin-top:10px"
          dark
          v-if="$store.state.Auth.isLogged"
          :to="`/`"
        >{{$i18n.t("ROOM.BACKTOHOME")}}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Meeting from '../models/meeting';
export default {
  components:{
  },
  data() {
    return {
      rating:0
    };
  },
  created(){
  },  
  mounted() {

  },
  methods: {
    inputRating(){
      let meeting = this.$store.state.client_meeting
      meeting.rating= this.rating;
      meeting = new Meeting(meeting)
      Meeting.updateRating(meeting);
    }
  },
  computed:{
    
  }
};
</script>