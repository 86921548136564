<template>
  <v-app id="app">
    <notifications group="feedback" data-cy="notification" />
    <!-- <Login v-if="!isLogged"></Login> -->
    <Navbar></Navbar>

    <v-content v-bind:class="{'showPadding':showPadding}">
      <v-container fluid style="padding:0px;height:100%;max-width: 100%;">
        <transition name="fade-transition">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Company from './models/company.js'
import Login from "@/views/Login.vue";
import { mapState } from "vuex";
import firebase from "./firebase";
import communications from "./communications/CommunicationsSingleton.js";
import Navbar from "@/components/Navbar.vue";

export default {
  data(){
    return{
      showPadding:true
    }
  },
  components: {
    Login,Navbar
  },
  computed: {
    ...mapState({
      isLogged: (state) => state.Auth.isLogged,
    })
  },
  created() {
    this.$i18n.locale = this.$store.state.lang;
    this.$vuetify.theme.dark = this.$store.state.isDark;
    this.$vuetify.theme.themes.dark.primary = this.$store.state.primary;
    this.$vuetify.theme.themes.light.primary = this.$store.state.primary;
    this.subscribeGoogleAuth();

    //cerrar todas las cosas de webrtc y las conexiones, para que no quede nada colgado al cerrar el browser
    let iOS = ["iPad", "iPhone", "iPod"].indexOf(navigator.platform) >= 0;
    let eventName = iOS ? "pagehide" : "beforeunload";
    window.addEventListener(eventName, (e) => {
      communications.destroyed();
    });
  },
  methods:{
    async subscribeGoogleAuth() {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          // User is signed in.
          //console.log(user);
          //en el token estan los users claims
          this.$store.dispatch("Auth/userLogin", user);
          this.showPadding=false
          let companyId = user.uid.split("@")[1]
          let company = await Company.getById(companyId);
          this.$store.commit("SET_USER_COMPANY", company);
        } else {
          this.$store.dispatch("Auth/userLogout");
          this.showPadding=true
        }
      });
    },
  }

}
</script>

<style lang="scss">
.showPadding {
  padding: 0px !important;
}
#app {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app.dontShow #nav {
  display: none !important;
}
.notifications {
  top: 0px !important;
  width: 310px !important;
  right: 10px !important;
}

.notification-wrapper {
  margin: 10px 0 !important;
  height: auto !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}

.vue-notification {
  font-size: 14px !important;
  margin: -0px 0px 0px 0px !important;
  margin-right: 0px !important;
  cursor: pointer !important;
  height: 100% !important;
}

.vue-notification-group {
  top: 58px !important;
  right: 10px !important;
}

.vue-notification-wrapper {
  border-radius: 8px !important;
  margin-bottom: 5px !important;
}

.vue-notification-template.vue-notification.warning {
  background-color: #ffbc00 !important;
  border-left: 6px solid #ffd150 !important;
}

.vue-notification-template.vue-notification.error {
  border-left-color: #b82e24 !important;
  border-left: 6px solid #ff8484 !important;
}

.vue-notification-template.vue-notification.info {
  background: #1fa9d8 !important;
  border-left: 6px solid #87d1ea !important;
}

.vue-notification-template.vue-notification.success {
  border-left: 6px solid #458047 !important;
}

.vue-notification .notification-title {
  padding-bottom: 5px !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-btn:focus {
  border: 0px;
  outline: 0px;
}
</style>
