<template>
  <div id="chatDiv">
    <div class="messages-container" style="background:transparent !important">
      <ol id="messages">
        <template v-for="(message, index) in messages">
          <li
            v-if="message.type=='text'"
            :key="index"
            v-bind:class="message.incoming ? '':'mine primary'"
            v-linkified
            v-html="message.message"
          ></li>
          <li v-else-if="message.type=='location'" :key="index" v-bind:class="message.incoming ? '':'mine primary'">

            <iframe
              height="150px"
              style="width: 100%;height: 150px; border:0"
              frameborder="0" 
              :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyAxz221ZpJRRqcDjeaB2I87GOK8V9yWDSk&q=${message.message.split('||&||')[0]},${message.message.split('||&||')[1]}`" allowfullscreen>
            </iframe>

          </li>
          <li v-else :key="index" v-bind:class="message.incoming ? '':'mine primary'">
            <template v-if="message.name.indexOf('.png')!=-1 || message.name.indexOf('.jpg')!=-1 || message.name.indexOf('.jpeg')!=-1 || message.name.indexOf('.JPG')!=-1">
              <img v-if="message.incoming" :src="message.url" width="200" /><br v-if="message.incoming">
              <v-icon>mdi-file-image</v-icon>
            </template>
              
            <v-icon
              v-else-if="message.name.indexOf('.doc')!=-1 || message.name.indexOf('.docx')!=-1 || message.name.indexOf('.pdf')!=-1 || message.name.indexOf('.txt')!=-1"
            >mdi-file-document</v-icon>

            <template v-else-if="message.name.indexOf('.mp4')!=-1 || message.name.indexOf('.webm')!=-1">
              <video v-if="message.incoming" :src="message.url" width="200" ></video><br v-if="message.incoming">
              <v-icon>mdi-file-video</v-icon>
            </template>
            <template v-else-if="message.name.indexOf('.mp3')!=-1 || message.name.indexOf('.wav')!=-1">
              <audio v-if="message.incoming" :src="message.url" controls></audio><br v-if="message.incoming">
              <v-icon>mdi-file-music</v-icon>
            </template>
            <v-icon v-else>{{message.url?'mdi-download':'mdi-file'}}</v-icon>
            <label v-html="message.url?'':message.name"></label>
            <a
              v-show="message.url"
              :href="message.url?message.url:''"
              target="_blank"
              :download="message.name"
            >{{message.name}}</a>
            <v-progress-linear
              v-show="!message.url && message.progress<100"
              :value="message.progress"
              striped
              color="primary"
              class="mb-0"
            ></v-progress-linear>
          </li>
        </template>
      </ol>
    </div>
    <div
      v-if="!infomeeting"
      style="background-color: #00000094;padding-left: 8px;
    padding-top: 16px !important;
    border-radius: 4px;"
    >
      <v-textarea
        @keyup.enter.native="addMessage"
        dark
        style="display: inline-block; width: 60%;"
        solo
        id="message"
        ref="message"
        no-resize
        height="50px"
        label="Send a message..."
        dense
        flat
        background-color="#00000094"
        v-model="message"
      ></v-textarea>
      <!--<VEmojiPicker class="emojis__content" v-show="showEmoji && !ismobile" @select="addEmoji" />-->
      <input ref="uploader" style="display:none;" type="file" @change="onFileChanged" />
      <v-btn icon large style="float: right;" dark @click.prevent="addMessage">
        <v-icon>mdi-send</v-icon>
      </v-btn>
      <v-btn icon dark large style="float: right;" @click="getLocation">
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn  v-show="!ismobile" icon dark large style="float: right;" @click="showEmojis">
        <v-icon>mdi-emoticon-outline</v-icon>
      </v-btn>
      <v-btn icon v-show="activecall" large style="float:right" dark @click="askForFiles">
        <v-icon>mdi-paperclip</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import VEmojiPicker from "v-emoji-picker";

export default {
  props: {
    ismobile:Boolean,
    messages: Array,
    selected: Boolean,
    activecall:Boolean,
    infomeeting:Boolean
  },
  components:{
    VEmojiPicker
  },
  data() {
    return {
      message: null,
      showEmoji: false
    };
  },
  watch:{
    
  },
  created(){
  
  },  
  mounted() {
    
  },
  methods: {
    onFileChanged(e){
      this.$emit('uploadFile',e.target.files[0])
    },
    askForFiles() {
      this.$refs.uploader.click()
    },
    showEmojis() {
      this.$refs.message.focus();
      this.showEmoji = !this.showEmoji;
    },

   addEmoji(emoji) {
    this.$refs.message.focus();
    if (!this.message) {
      this.message = emoji.data;
    } else {
      this.message += emoji.data;
    }
    this.showEmoji = false;
  },

   async getLocation() {
      let cachi = this;
      navigator.geolocation.getCurrentPosition(async (position) => {
        this.sendLocation(position.coords)
      });
  },

  sendLocation(location){

    this.$emit('sendLocation',location);


  },

   addMessage() {
    if (this.message.trim()!="") {
      this.$emit("send-message", this.message);
      this.message = "";
      this.updateScroll();
    }
    this.$refs.message.focus();
  },

   updateScroll() {
    const timer = setTimeout(() => {
      const element = document.getElementById("messages");
      element.scrollTop = element.scrollHeight;
    }, 10);
  }

  }
};
</script>


<style lang="scss">
.linkified {
  background: #dad9d7;
  border-radius: 4px;
  padding: 1px;
  color: blue !important;
}
@font-face {
  font-family: NotomojiColor;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.glitch.com/61908de1-dd0a-4359-a54b-6cb6d41bb5fd%2FNotoColorEmoji.ttf?1513108808150)
    format("truetype");
}

.emojis__content {
  height: 200px;
  width: 265px !important;
  position: fixed;
  bottom: 90px;
  right: 35px;
}

$padding: 10px;

.messages-container {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  max-height: calc(100% - 80px);
  height: calc(100% - 80px);
}

#messages {
  background: transparent !important;
  padding: $padding;
  padding-left: 12px !important;
  margin: 0;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #f5f5f5;
  overflow: auto;
  font-size: 16px;
}

#messages li {
  list-style-type: none;
  margin: 2px 8px 2px 0;
  float: left;
  border-radius: 0.5em 0.5em 0.5em 0;
  padding: $padding;
  color: black;
  position: relative;
  z-index: 1;
  background: #e2e2e2de;
  max-width: 80%;
  word-break: break-word;
}

#messages li.mine {
  max-width: 80%;
  word-break: break-word;
  margin: 2px 0 2px 8px;
  border-radius: 0.5em 0.5em 0 0.5em;
  align-self: flex-end;
  z-index: 0;
  background: #000000a6;
  background-attachment: fixed;
  color: white;
}

#chatDiv {
  max-height: 325px;
  height: 325px;
  bottom: 100px;
  width: 100%;
  padding: 0px 10px 0px 10px;
}
</style>
