<template>
  <v-row
    :class="$vuetify.theme.dark ? 'dark-background' : 'background'"
    align="center"
    justify="center"
    style="height:100%"
  >
    <v-col style="max-width: 600px;">
      <v-card class="pt-10 pb-6 px-12 card-content">
        <v-row no-gutters>
          <v-col cols="12" class="text-center pb-4">
            <v-avatar size="100">
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo2.svg"
                transition="scale-transition"
                width="100"
              />
            </v-avatar>
          </v-col>
          <v-col cols="12" class="pt-2 px-12">
            <v-form ref="login" lazy-validation>
              <v-text-field
                data-cy="userlogin"
                prepend-icon="mdi-account"
                :rules="[rules.required]"
                :label="$i18n.t('LOGIN.usernameLabel')"
                filled
                rounded
                dense
                v-model="username"
                @keyup.enter="doLogin()"
              ></v-text-field>
              <v-text-field
                data-cy="passlogin"
                prepend-icon="mdi-lock"
                :label="$i18n.t('LOGIN.passwordLabel')"
                :rules="[rules.required]"
                v-model="password"
                filled
                rounded
                dense
                type="password"
                @keyup.enter="doLogin()"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="12" class="text-center">
            <span
              @click="showForgetPasswordModal = true"
              style="cursor: pointer; color: #D1404A;"
            >{{ $i18n.t("LOGIN.forgotPassword") }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters justify="end">
          <v-col cols="4" class="pt-6">
            <v-btn
              data-cy="loginbtn"
              :loading="loadingButton"
              block
              large
              class="card-content__footer"
              dark
              depressed
              @click.prevent="doLogin"
            >{{ $i18n.t("LOGIN.signIn") }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- This is the password reset overlay -->
    <v-dialog
      v-model="showForgetPasswordModal"
      width="450px"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
      overlay-opacity="0.7"
    >
      <v-card class="password-reset__content">
        <v-card-title
          class="justify-center"
          style="color: #525252;"
        >{{ $i18n.t("LOGIN.resetPassword") }}</v-card-title>
        <v-card-text class="pb-0 pt-5 px-12">
          <v-form ref="forgetPasswordForm" lazy-validation>
            <v-text-field
              filled
              rounded
              dense
              prepend-icon="mdi-account"
              :label="$i18n.t('LOGIN.usernameLabel')"
              :rules="[rules.required]"
              v-model="forgetUsername"
            ></v-text-field>

            <v-text-field
              filled
              rounded
              dense
              prepend-icon="mdi-at"
              label="Email"
              v-model="forgetEmail"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <div class="text-end" style="width: 100%;">
            <v-btn
              class="mr-2"
              @click="showForgetPasswordModal = false"
              text
            >{{ $i18n.t("GENERAL.close") }}</v-btn>
            <v-btn
              :loading="loadingForgetButton"
              depressed
              @click="forgetPasswordRequest"
              color="secondary"
            >{{ $i18n.t("GENERAL.send") }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import firebase from "./../firebase/index.js";
import LoginModel from "./../models/login.js";


export default{
  
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const username = urlParams.get("u");
    const password = urlParams.get("p");
    this.username = username ? username : "";
    this.password = password ? password : "";
  },
  
  data(){
    return {
       username : "",
       password : "",
       showForgetPasswordModal : false,
       firebase : firebase,
       loadingButton : false,

       forgetEmail : "",
       forgetUsername : "",
       loadingForgetButton : false,

       rules : {
        required: (value) => !!value || "Required",
      },
    }
  },
  
  methods:{
    async forgetPasswordRequest(){
      if (this.$refs.forgetPasswordForm.validate()) {
        try {
          this.loadingForgetButton = true;
          await LoginModel.forgetPassword(this.forgetUsername, this.forgetEmail);
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: "An email was sent, follow instructions to recover your password",
          });
        } catch (error) {

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "Error",
          });
        }
        this.loadingForgetButton = false;
        this.showForgetPasswordModal = false;
      }
    },

    async doLogin() {
      if (this.$refs.login.validate()) {
        try {
          this.loadingButton = true;
          await LoginModel.login(this.username, this.password);
        } catch (error) {
         

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text:this.$i18n.t("GENERAL.ERRORLOGIN"),
          });
        }
        this.loadingButton = false;
      }
    }
  }

  

  
}
</script>

<style scoped lang="scss">
.background {
  background-image: url("../assets/background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: var(--v-primary-base);
}

.dark-background {
  background-image: url("../assets/background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #272727;
}

.card-content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border-top: 5px solid #d1404a;
  .card-content__footer {
    background-color: #d1404a !important;
    border-radius: 8px;
  }
}

.password-reset__content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  width: 450px;
}
</style>
