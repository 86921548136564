export default {
    timerCallback: function () {
        if (this.video.paused || this.video.ended) {
            return;
        }
        this.computeFrame();
        let self = this;
        setTimeout(function () {
            self.timerCallback();
        }, 0);
    },

    doLoad: function async() {
        this.width = 640;
        this.height = 480;
        this.image = document.createElement('img');
        this.image.width = this.width;
        this.image.height = this.height;
        this.image.id = 'img-virtual-background';
        this.image.style.display = "none";

        this.video = document.createElement('video');
        this.video.id = "video-virtual-background";
        this.video.width = this.width;
        this.video.height = this.height;
        this.video.style.display = "none";

        this.c1 = document.createElement('canvas');
        this.c1.id = "c1-virtual-background";
        this.c1.width = this.width;
        this.c1.height = this.height;
        this.ctx1 = this.c1.getContext("2d");
        this.c1.style.display = "none";

        this.c2 = document.createElement('canvas');
        this.c2.width = this.width;
        this.c2.height = this.height;
        this.c2.id = "c2-virtual-background";
        this.ctx2 = this.c2.getContext("2d");
        this.c2.style.display = "none";

        this.c3 = document.createElement('canvas');
        this.c3.width = this.width;
        this.c3.height = this.height;
        this.c3.id = "c3-virtual-background";
        this.ctx3 = this.c3.getContext("2d");
        this.c3.style.display = "none";



        let self = this;
        this.video.addEventListener("play", function () {
            self.width = self.video.videoWidth / 2;
            self.height = self.video.videoHeight / 2;
            self.timerCallback();
        }, false);

        document.body.appendChild(this.image);
        document.body.appendChild(this.video);
        document.body.appendChild(this.c1);
        document.body.appendChild(this.c2);
        document.body.appendChild(this.c3);
        return
    },

    computeFrame: function () {
        this.ctx1.drawImage(this.video, 0, 0, 640, 480);
        let frame = this.ctx1.getImageData(0, 0, 640, 480);
        let l = frame.data.length / 4;
        for (let i = 0; i < l; i++) {
            let r = frame.data[i * 4 + 0];
            let g = frame.data[i * 4 + 1];
            let b = frame.data[i * 4 + 2];
            if (r > 170 && g > 100 && b > 80)
                frame.data[i * 4 + 3] = 0;
        }
        this.ctx2.putImageData(frame, 0, 0);
        //  let onlyPersonframe = this.ctx2.getImageData(0, 0, 640, 480);
        this.ctx3.drawImage(this.image, 0, 0);
        this.ctx3.drawImage(this.c2, 0, 0)
        let data = this.ctx3.getImageData(0, 0, 640, 480)
        this.ctx3.putImageData(data, 0, 0)
        return;
    },

    start: async function () {

        let stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true
        });

        this.video.srcObject = stream;
        this.video.play();
        return this.c3.captureStream(24);
    },

    stop: function () {
        if (this.video.srcObject) {
            this.video.srcObject.getTracks().forEach(track => {
                track.stop();
            });
            this.video.srcObject = null;
        }
    }
};
