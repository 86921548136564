<template>
  <v-app-bar
    app
    :color="$store.state.active_call || !$store.state.Auth.isLogged ? 'transparent':'primary'"
    :dark="$store.state.active_call?false:true"
    id="nav"
    style="box-shadow: 0px 0px;"
    dense
  >
    <v-img @click="$router.replace('/')" style="cursor:pointer;"
      alt="Logo"
      class="shrink mr-2"
      contain
      :src="$store.state.active_call || !$store.state.Auth.isLogged ?require('../assets/logo-grey.svg'):require('../assets/logo.svg')"
      transition="scale-transition"
      v-bind:class="{'logoOpacity':$store.state.active_call}"
      width="170"
    />
    <v-speed-dial 
    
      :value="fab"
      v-if="$store.state.active_call"
      style="position: absolute;
      top: 55px;
      left: 38px;"
      :direction="'bottom'"
    >
      <template v-slot:activator>
        <v-btn @click="fab = !fab" depressed fab small>
          
          <v-icon >mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <ScreenShot v-if="$store.state.Auth.isLogged"></ScreenShot>
      <Notifications v-if="$store.state.Auth.isLogged" :active_rooms="$store.state.meeting_members"></Notifications>
      <Schedule v-if="$store.state.Auth.isLogged"></Schedule>

      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <Settings :incall="$store.state.active_call"></Settings>
          </div>
        </template>
        <span>{{$i18n.t("SETTING.title")}}</span>
      </v-tooltip>
    </v-speed-dial>

    <v-spacer></v-spacer>

    <template v-if="!$store.state.active_call  && $store.state.Auth.isLogged">
      <Notifications :active_rooms="$store.state.meeting_members"></Notifications>
      <Schedule></Schedule>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <Settings></Settings>
          </div>
        </template>
        <span data-cy="settingsMenutt">{{$i18n.t("SETTING.title")}}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn style="overflow: hidden;" small depressed color="#d75d5d" fab v-on="on" data-cy="nameMenu">
            <span class="white--text headline">{{initials}}</span>
          </v-btn>
        </template>
        <span data-cy="nameMenutt">{{$store.state.Auth.token.claims.name}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="logout()" v-on="on" icon class="ml-2" data-cy="logoutbtn">
            <v-icon>mdi-exit-run</v-icon>
          </v-btn>
        </template>
        <span data-cy="logoutbtntt">Logout</span>
      </v-tooltip>
    </template>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $i18n.t("MEETING.areYouSure") }}</v-card-title>

        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="logout(true)">{{ $i18n.t("MEETING.confirm") }}</v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="dialogConfirm = false"
          >{{ $i18n.t("GENERAL.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import Schedule from "@/components/Schedule.vue";
import Settings from "@/components/Settings.vue";
import Notifications from "@/components/Notifications.vue";
import { mapState } from "vuex";
import Login from "./../models/login.js";
import ScreenShot from "./ScreenShot.vue";

export default {
  data(){
    return{
      fab:false,
      dialogConfirm:false
    }
  },
  components: {
    ScreenShot,
    Schedule,
    Settings,
    Notifications,
  },
  methods: {
    async logout(bool) {
      if(bool){
        await Login.logout();
        this.$store.commit("UNSET_ACTIVE_MEETING")
        this.$router.replace("/login");
        this.dialogConfirm=false;
      }
      if(this.$store.state.active_meeting){
        this.dialogConfirm=true;
      }else{
        await Login.logout();
        this.$store.commit("UNSET_ACTIVE_MEETING")
        this.$router.replace("/login");
        this.dialogConfirm=false;
      }
    },
  },
  computed: {
    ...mapState({
      initials: (state) => {
        let arr = state.Auth.token.claims.name.split(" ");
        if (arr.length === 1) return arr[0];
        else return (arr[0][0] + arr[1][0]).toUpperCase();
      },
    }),
  },
  mounted(){
      this.ctAux = document.createElement('canvas');
      this.ctAux.id = "imgResize-virtual-background";
      this.ctxAux = this.ctAux.getContext("2d");
      this.ctAux.style.display = "none";
      document.body.appendChild(this.ctAux)
  }
};
</script>

<style lang="scss">
.logoOpacity {
  opacity: 0.5 !important;
}
.v-toolbar__content {
  padding-left: 3px !important;
}
.v-speed-dial__list button.theme--light {
  background-color: #f5f5f5 !important;
}
.v-speed-dial__list button.theme--dark {
  background-color: #272727 !important;
}
</style>
