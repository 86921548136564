import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify.js";
import VueI18n from "vue-i18n";
import Locale from "@/locale";
import notification from "vue-notification";
import linkify from 'vue-linkify'
import firebase from './firebase/index.js';
import VueClipboard from 'vue-clipboard2'

import VueTelInput from 'vue-tel-input'

import VuetifyConfirm from 'vuetify-confirm'

Vue.use(VueTelInput)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(require('vue-shortkey'), { prevent: ['#message', 'input', 'textarea'] })

Vue.directive("linkified", linkify);
Vue.use(VueI18n);
Vue.use(notification);
Vue.prototype.$firebase = firebase;
Vue.prototype.$communications = {};

const i18n = new VueI18n({
  locale: store.state.lang, // set locale
  messages: { en: Locale.en, es: Locale.es }
});

Vue.use(VuetifyConfirm, { vuetify })

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default { i18n }