import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBDey5j5RBJGMPHDyaLTKpdEKefpp1Q_oE",
  authDomain: "ucvideo.firebaseapp.com",
  databaseURL: "https://ucvideo.firebaseio.com",
  projectId: "ucvideo",
  storageBucket: "ucvideo.appspot.com",
  //messagingSenderId: "1077482972318",
  appId: "1:1077482972318:web:e595c024db5df6c448078f",
  measurementId: "G-6PDB1DW4MY"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp;
