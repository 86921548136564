<template>
  <div class="pa-3" v-bind:style="{'display':layoutMode?'inline-block':'inline-flex','width':layoutMode?'100%':'unset'}" >

     <template v-if="layoutMode">
      <v-card elevation="2" class="dark">
        <v-card-title class="meeting-title">{{ $i18n.t("MEETING.title") }}</v-card-title>
        <v-card-text class="pb-1">
          <v-row no-gutters>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mt-2"
                    v-on="on"
                    fab
                    text
                    color="primary"
                    dark
                    small
                    @click="updateCalendar(false)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("GENERAL.prev") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="10" class="pa-3 pt-0">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    :label="$i18n.t('MEETING.ACTUALDAY')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :locale="$store.state.lang? $store.state.lang :'en'"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mt-2"
                    v-on="on"
                    fab
                    text
                    color="primary"
                    dark
                    small
                    @click="updateCalendar(true)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("GENERAL.next") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-sheet style="height: calc(100vh - 350px);" v-show="!loadingCalendar">
            <v-calendar
              now="2020-04-07 12:00:00"
              ref="calendar"
              type="day"
              v-model="value"
              :events="meetingsList"
              :interval-format="interval"
              :event-color="getEventColor"
              event-overlap-mode="stack"
              :event-overlap-threshold="30"
              @change="getMeetings"
              @click:event.self="clickMeeting"
            ></v-calendar>
          </v-sheet>
          <v-sheet style="height: calc(100vh - 350px);" v-show="loadingCalendar">
            <v-container class="fill-height" fluid>
              <v-row align="center" justify="center">
                <v-col class="text-center">
                  <v-btn icon>
                    <v-icon class="rotate">mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-card-text>
        <v-card-actions class="px-4 pb-4" style="justify-content: flex-end;">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                color="green"
                dark
                small
                @click="$refs.addScheduleDialog.openDialog()"
                data-cy="addschedule"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{$i18n.t("MEETING.new")}}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>

    </template>


    <v-tooltip bottom v-if="!layoutMode">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click="createNewMeeting" :disabled="loadingQuick" icon data-cy="quickmeetingMenu">
          <v-icon v-if="!loadingQuick">mdi-account-multiple</v-icon>
          <v-icon v-else class="rotate">mdi-cached</v-icon>
        </v-btn>
      </template>
      <span data-cy="quickmeetingMenutt">{{ $i18n.t("MEETING.EXPRESS") }}</span>
    </v-tooltip>

    <v-menu  v-if="!layoutMode"
      nudge-bottom="60"
      nudge-left="350"
      :close-on-content-click="false"
     
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on : tooltip }">
            <v-btn icon class="mr-2" v-on="{ ...tooltip, ...menu }" @click="refreshMeetingColor()" data-cy="scheduleMenu">
              <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>
          </template>
          <span data-cy="scheduleMenutt">{{$i18n.t("MEETING.title")}}</span>
        </v-tooltip>
      </template>

      <v-card width="400px">
        <v-card-title class="meeting-title">{{ $i18n.t("MEETING.title") }}</v-card-title>
        <v-card-text class="pb-1">
          <v-row no-gutters>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mt-2"
                    v-on="on"
                    fab
                    text
                    color="primary"
                    dark
                    small
                    @click="updateCalendar(false)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("GENERAL.prev") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="10" class="pa-3 pt-0">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    :label="$i18n.t('MEETING.ACTUALDAY')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :locale="$store.state.lang? $store.state.lang :'en'"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mt-2"
                    v-on="on"
                    fab
                    text
                    color="primary"
                    dark
                    small
                    @click="updateCalendar(true)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("GENERAL.next") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-sheet style="height: calc(100vh - 350px);" v-show="!loadingCalendar">
            <v-calendar
              now="2020-04-07 12:00:00"
              ref="calendar"
              type="day"
              v-model="value"
              :events="meetingsList"
              :interval-format="interval"
              :event-color="getEventColor"
              event-overlap-mode="stack"
              :event-overlap-threshold="30"
              @change="getMeetings"
              @click:event.self="clickMeeting"
            ></v-calendar>
          </v-sheet>
          <v-sheet style="height: calc(100vh - 350px);" v-show="loadingCalendar">
            <v-container class="fill-height" fluid>
              <v-row align="center" justify="center">
                <v-col class="text-center">
                  <v-btn icon>
                    <v-icon class="rotate">mdi-autorenew</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-card-text>
        <v-card-actions class="px-4 pb-4" style="justify-content: flex-end;">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                color="green"
                dark
                small
                @click="$refs.addScheduleDialog.openDialog()"
                data-cy="addschedule"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{$i18n.t("MEETING.new")}}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-menu>
    <InfoMeeting @reloadMeetings="reloadCalendar" :meeting="selectedMeeting" ref="infoMeeting"></InfoMeeting>

    <!-- Add Schedule -->
    <AddMeeting @newMeeting="add_meeting" ref="addScheduleDialog"></AddMeeting>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Meeting from "./../models/meeting.js";
import ModalOpenMeeting from "./ModalOpenMeeting.vue";
import moment from "moment";
import AddMeeting from "./AddMeeting.vue";
import InfoMeeting from "./InfoMeeting.vue";

export default {
  props:{
    layoutMode:Boolean
  },
  data(){
    return{
      addSchedule : false,
      value : "",
      date : new Date().toISOString().substr(0, 10),
      menu : false,
      selectedMeeting : null,
      loadingCalendar : false,
      meetingsList: [],
      loadingQuick:false
    }
  },
  components: {
    ModalOpenMeeting,
    AddMeeting,
    InfoMeeting,
  },
  mounted(){
    this.refreshMeetingColor();
  },
  computed: {
    ...mapState({
      meetings: (state) => state.Meeting.meetings,
    }),
  },
  watch:{
    'date':function(val){
      this.value = val;
    }
  },
  methods:{

    async createNewMeeting(){
      try{
        this.loadingQuick=true;
        await this.$refs.addScheduleDialog.createQuickMeeting();
        this.loadingQuick=false;
      }catch(e){
        this.loadingQuick=false;
      }
    },
      interval(date) {
      return date.time;
    },

     reloadCalendar() {
      let obj = {
        start: {
          date: moment().format("YYYY-MM-DD"),
        },
      };
      this.getMeetings(obj);
    },

    async getMeetings(obj) {
      this.loadingCalendar = true;
      let user = this.$store.state.Auth.token.claims.sub.split("@")[0];
      let company = this.$store.state.Auth.token.claims.sub.split("@")[1];

      let dayString = moment(obj.start.date, "YYYY-MM-DD").format("YYYYMMDD");
      let meetings = await Meeting.getMeetingsInADay(user, company, dayString);

      this.meetingsList = meetings.map((meeting) => {
        if (meeting.status == "deleted") {
          return false;
        } else {
          let now = moment();
          let event_end = moment(meeting.enddate, "YYYYMMDDHHmm");
          let color = now > event_end ? "old" : this.$store.state.primary;

          if (color == "old") {
            color = "grey";
          } else {
            if (meeting.status == "canceled") {
              color = "red darken-1";
            } else if (meeting.status == "pending") {
              color = "yellow darken-3";
            } else if (meeting.status == "confirmed") {
              color = "green darken-1";
            }
          }

          let event_name = meeting.customer_name;
          meeting.description
            ? (event_name = event_name += " - " + meeting.description)
            : null;

          return {
            name: event_name,
            start: moment(meeting.startdate, "YYYYMMDDHHmm").format(
              "YYYY-MM-DD HH:mm"
            ),
            end: moment(meeting.enddate, "YYYYMMDDHHmm").format(
              "YYYY-MM-DD HH:mm"
            ),
            color: color,
            meeting: meeting,
          };
        }
      });
      
      this.meetingsList = this.meetingsList.filter((elem) => elem != false);
      this.loadingCalendar = false;

      setTimeout(() => {
        let elems = document.getElementsByClassName("v-event-timed");
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].className.indexOf("grey") == -1) {
            elems[i].scrollIntoView();
            return true;
          }
        }
      }, 400);

      this.refreshMeetingColor();
    },

    refreshMeetingColor() {
      for (let i = 0; i < this.meetingsList.length; i++) {
        let actual = this.meetingsList[i];
        let now = moment();
        let event_end = moment(actual.end, "YYYY-MM-DD HH:mm");

        now > event_end ? (actual.color = "grey") : this.$store.state.primary;
      }

      setTimeout(() => {
        let elems = document.getElementsByClassName("v-event-timed");
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].className.indexOf("grey") == -1) {
            elems[i].scrollIntoView();
            return true;
          }
        }
      }, 400);

      this.$emit('update_stats',this.meetingsList)
    },

    clickMeeting(event) {
      let meeting = event.event.meeting;
      this.selectedMeeting = meeting;
      let infomeeting= this.$refs["infoMeeting"];
      infomeeting.openDialog();
    },

     add_meeting(meeting) {
      let now = moment();
      let event_end = moment(meeting.enddate, "YYYYMMDDHHmm");
      let event_start = moment(meeting.startdate, "YYYYMMDDHHmm");
      let isToday = moment().isSame(event_start, "day");
      let color = now > event_end ? "old" : this.$store.state.primary;

      if (color == "old") {
        color = "grey";
      } else {
        if (meeting.status == "canceled") {
          color = "red darken-1";
        } else if (meeting.status == "pending") {
          color = "yellow darken-3";
        } else if (meeting.status == "confirmed") {
          color = "green darken-1";
        }
      }

      let event_name = meeting.customer_name;
      meeting.description
        ? (event_name = event_name += " - " + meeting.description)
        : null;
      if (isToday) {
        this.meetingsList.push({
          name: event_name,
          start: moment(meeting.startdate, "YYYYMMDDHHmm").format(
            "YYYY-MM-DD HH:mm"
          ),
          end: moment(meeting.enddate, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm"),
          color: color,
          meeting: meeting,
        });
      }

       this.refreshMeetingColor();
    },

    

    printDate(date) {
      const aux = new Date(date);
      let newDate = "";
      newDate = aux.getHours() < 10 ? "0" + aux.getHours() : "" + aux.getHours();
      newDate +=
        aux.getMinutes() < 10 ? ":0" + aux.getMinutes() : ":" + aux.getMinutes();
      return newDate;
    },

    getEventColor(event) {
      return event.color;
    },

    updateCalendar(bool) {
      if (bool) {
        this.date = moment(this.date, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        this.date = moment(this.date, "YYYY-MM-DD")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }

      this.value = this.date;
    }
  }
}
</script>

<style lang="scss" scoped>
.date-box {
  background: #f5f5f5;
  padding: 10px !important;
}
.date-box__label {
  font-size: 16px;
}
.meeting-title {
  justify-content: center;
  font-size: 18px;
}
.meeting-list {
  max-height: 250px;
  overflow-y: auto;
}
</style>
<style>
.v-event-timed-container > .v-event-timed.yellow > .pl-1 {
  color: black !important;
  border: 1px solid transparent !important;
}

.v-date-picker-table
  .v-btn.v-date-picker-table__current.v-btn--rounded.v-btn--outlined.theme--light.accent--text {
  color: grey !important;
}
.v-btn.v-size--default.v-date-picker-table__current.v-btn--outlined.theme--light.accent--text {
  color: grey !important;
}

.v-date-picker-table
  .v-btn.v-date-picker-table__current.v-btn--active.v-btn--text.v-btn--rounded.theme--light {
  color: grey !important;
}



div.v-date-picker-header__value>div.accent--text>button{
  color: #414141 !important;
}

div.v-date-picker-header__value>div.accent--text>button:hover{
  color: lightgrey !important;
}


.v-date-picker-table .v-btn.v-btn--active {
  color: grey !important;
}

.v-date-picker-header.theme--light .v-date-picker-header__value button:hover{
  color:grey !important;
}
.v-time-picker-clock__item.v-time-picker-clock__item--active.accent {
  color: grey !important;
}
</style>
