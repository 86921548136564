import firebase from '../firebase/index'
import store from '../store/index'

export default class TextProvider {
  constructor(obj) {
    this.name = obj.name || ''
    this.method = obj.method || 'POST'
    this.url = obj.url || ''
    this.body = obj.body || ''
    this.headers = obj.headers || ''
    this.enabled = obj.enabled || false
  }

  clone() {
    return new TextProvider(this);
  }

  getObject() {
    return {
      name :this.name,
      method : this.method,
      url :this.url,
      body : this.body,
      headers : this.headers,
      enabled : this.enabled
    }
  }

  static async saveConfiguration(type, company, providers,templates) {
    const query = await firebase.firestore().collection(`/companies/${company.id}/configurations`).doc(type).set(
        {
          providers:providers,
          templates: templates
        }
    );
    return query;
  }


  static async getAllConfigurations(company) {
    const query = await firebase.firestore().collection(`/companies/${company.id}/configurations`).get();
    const configs = {};
    let data = false
    query.docs.forEach(config => {
      if (config.id === 'sms'){
        data =  config.data();
      }
    });

    return data
  }
}