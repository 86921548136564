<template>
  <div ref="div"></div>
</template>

<script >
import firebase from "@/firebase/index.js";
import { getFirebaseDataBase } from "@/firebase/utils";
import $ from "jquery";
import "popper.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import CodeMirror from "codemirror/lib/codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/xml/xml";
import "codemirror/theme/monokai.css";

import "summernote/dist/summernote-bs4";
import "summernote/dist/summernote-bs4.css";
import "summernote-file";

import moment from "moment";
import Meeting from "@/models/meeting.js";
import Company from "@/models/company.js";

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    value: String,
  },
  beforeDestroy() {
    console.log("summernote destroyed");
    $(this.$refs.div).summernote("destroy");
  },
  mounted() {
    $(this.$refs.div).summernote({
      callbacks: {
        onChange: (contents) => {
          this.$emit("input", contents);
        },
        onFileUpload: (file) => {
          this.myOwnCallBack(file[0]);
        },
      },
      height: 250, //set editable area's height
      codemirror: {
        // codemirror options
        CodeMirrorConstructor: CodeMirror,
        theme: "monokai",
      },
      toolbar: [
        ["style", ["style"]],
        ["font", ["bold", "underline", "clear"]],
        ["color", ["color"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["table", ["table"]],
        ["insert", ["link", "file"]],
        ["view", ["fullscreen", "codeview"]],
      ],
    });
    $(this.$refs.div).summernote("code", "");
    $(this.$refs.div).summernote("code", this.value || "");
  },
  computed: {},
  methods: {
    convertirB64(archivo, funcion) {
      var reader = new FileReader();
      reader.onload = function (readerEvt) {
        var b64 = btoa(readerEvt.target.result);
        var menor = 0;
        if (b64.length % 4 != 0) menor = 4 - (b64.length % 4);
        for (var i = 0; i < menor; i++) {
          b64 += "=";
        }
        funcion(b64);
      };
      reader.readAsBinaryString(archivo);
    },

    getValue() {
      return $(this.$refs.div).summernote("code");
    },

    async uploadFile(blob, format) {
      return new Promise((resolve, reject) => {
        let company = this.$store.state.Auth.token.claims.user_id.split("@")[1];
        const metadata = {
          contentType: format == "webm" ? this.videoFormat : "image/png",
          customMetadata: {
            company: company,
          },
        };

        let datetime = moment().format("YYYY-MM-DD_HH:mm:ss:SSS");
        let recordingName = datetime;
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef
          .child(company + "/resources/" + recordingName + "." + format)
          .put(blob, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed", // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            switch (snapshot.state) {
              case "paused": // or 'paused'
                console.log("Upload is paused");
                break;
              case "running": // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            reject();
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(async (downloadURL) => {
                resolve({
                  url: downloadURL,
                  name: recordingName + "." + format,
                });
              });
          }
        );
      });
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    myOwnCallBack(file) {
      var reader = new FileReader();
      reader.onload = async (readerEvt) => {
        var b64 = btoa(readerEvt.target.result);
        var menor = 0;
        if (b64.length % 4 != 0) menor = 4 - (b64.length % 4);
        for (var i = 0; i < menor; i++) {
          b64 += "=";
        }

        let blob = this.b64toBlob(b64);
        let upload = await this.uploadFile(blob, file.type.split("/")[1]);

        let listMimeImg = [
          "image/png",
          "image/jpeg",
          "image/webp",
          "image/gif",
          "image/svg",
        ];
        let listMimeAudio = ["audio/mpeg", "audio/ogg"];
        let listMimeVideo = ["video/mpeg", "video/mp4", "video/webm"];
        let elem;

        if (listMimeImg.indexOf(file.type) > -1) {
          //Picture
          $(this.$refs.div).summernote("editor.insertImage", upload.url);
        } else if (listMimeAudio.indexOf(file.type) > -1) {
          //Audio
          elem = document.createElement("audio");
          elem.setAttribute("src", upload.url);
          elem.setAttribute("controls", "controls");
          elem.setAttribute("preload", "metadata");
          $(this.$refs.div).summernote("editor.insertNode", elem);
        } else if (listMimeVideo.indexOf(file.type) > -1) {
          //Video
          elem = document.createElement("video");
          elem.setAttribute("src", upload.url);
          elem.setAttribute("controls", "controls");
          elem.setAttribute("preload", "metadata");
          $(this.$refs.div).summernote("editor.insertNode", elem);
        } else {
          //Other file type
          elem = document.createElement("a");
          let linkText = document.createTextNode(file.name);
          elem.appendChild(linkText);
          elem.title = file.name;
          elem.href = upload.url;
          $(this.$refs.div).summernote("editor.insertNode", elem);
        }

        console.log("uploaded");

        await Company.updateCompanyStorage(
          this.$store.state.Auth.token.claims.user_id.split("@")[1],
          blob.size,
          upload.name
        );
      };
      reader.readAsBinaryString(file);
    },

    progressHandlingFunction(e) {
      if (e.lengthComputable) {
        //Log current progress
        console.log((e.loaded / e.total) * 100 + "%");

        //Reset progress on complete
        if (e.loaded === e.total) {
          console.log("Upload finished.");
        }
      }
    },
  },
  watch: {
    propValue() {
      $(this.$refs.div).summernote("code", this.propValue || "");
    },
  },
};
</script>

<style>
.modal-backdrop {
  z-index: 0 !important;
}
</style>