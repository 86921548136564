<template>
  <v-dialog v-model="visible" :max-width="hasVideo ? '800' : '650'">
    <v-card>
      <iframe
        v-if="meeting && meeting.latitude && meeting.longitude"
        height="150px"
        style="width: 100%; height: 150px; border: 0"
        frameborder="0"
        :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyAxz221ZpJRRqcDjeaB2I87GOK8V9yWDSk&q=${meeting.latitude},${meeting.longitude}`"
        allowfullscreen
      >
      </iframe>
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <v-list-item three-line>
            <v-list-item-content class="pb-0">
              <div
                style="
                  border-bottom: 1px solid #80808026;
                  padding: 10px;
                  padding-right: 8%;
                "
                class="headline mb-4 text-left"
              >
                {{ $i18n.t("MEETING.meetingWith") }}
                {{ meeting ? meeting.customer_name : "" }}
                <v-list-item-title
                  style="display: inline"
                  class="mb-1 pl-3 mb-3 text-left"
                  >{{ getMeetingDayInfo }}</v-list-item-title
                >
                <v-chip class="ma-2" :color="returnColor">{{
                  $i18n.t("MEETING." + (meeting ? meeting.status : ""))
                }}</v-chip>
                <v-rating
                  v-if="meeting && meeting.rating != ''"
                  readonly
                  half-icon="mdi-star-half-full"
                  half-increments
                  background-color="grey"
                  v-model="meeting.rating"
                  :value="meeting ? meeting.rating : 0"
                ></v-rating>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      style="
                        position: absolute;
                        right: 10px;
                        top: 6px;
                        z-index: 999;
                      "
                      v-clipboard:copy="meeting ? meeting.url : ''"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $i18n.t("MEETING.COPYURL") }}</span>
                </v-tooltip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-subheader>
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ $i18n.t("MEETING.nameLabel") }} :
            {{ meeting ? meeting.customer_name : "" }}
          </v-subheader>
          <v-subheader>
            <v-icon class="mr-2">mdi-phone</v-icon>
            {{ $i18n.t("USER.phoneLabel") }} :
            {{ meeting ? meeting.customer_phone : "" }}
          </v-subheader>
          <template v-if="hasVideo || hasImage">
            <v-subheader>
              <v-icon class="mr-2">mdi-email</v-icon>

              E-mail :
              <textarea
                class="textAreaNofocus"
                :value="meeting ? meeting.customer_email : ''"
              ></textarea>
            </v-subheader>
            <v-subheader>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              {{ $i18n.t("MEETING.description") }} :
              {{ meeting ? meeting.description : "" }}
            </v-subheader>
          </template>
        </v-col>
        <v-col cols="6" class="text-center">
          <template v-if="!hasVideo && !hasImage">
            <v-subheader>
              <v-icon class="mr-2">mdi-email</v-icon>
              E-mail :
              <textarea
                class="textAreaNofocus"
                :value="meeting ? meeting.customer_email : ''"
              ></textarea>
            </v-subheader>
            <v-subheader>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              {{ $i18n.t("MEETING.description") }} :
              {{ meeting ? meeting.description : "" }}
            </v-subheader>
          </template>

          <v-progress-circular
            v-show="loading"
            style="height: 100%; width: 50px; position: absolute; left: 42%"
            indeterminate
            color="primary"
          ></v-progress-circular>

          <video
            v-bind:class="{ imgLoading: loading }"
            controls
            v-show="hasVideo"
            id="oldMeetingVideo"
          ></video>

          <img
            v-bind:class="{ imgLoading: loading }"
            v-show="hasImage"
            style="
              width: 200px;
              height: 200px;
              margin: auto;
              object-fit: contain;
              display: block;
            "
            id="meetingImage"
          />

          <v-btn
            @click="deleteActiveMedia()"
            v-show="hasVideo || hasImage"
            icon
            color="primary"
          >
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>

          <v-btn
            @click="handleIndex()"
            :disabled="recordingIndex == 0"
            v-show="hasVideo || hasImage"
            icon
            color="primary"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <label v-show="hasVideo || hasImage"
            >{{ this.recordingIndex + 1 }} /
            {{
              this.meeting && this.meeting.recordings
                ? this.meeting.recordings.length
                : 0
            }}</label
          >

          

          <v-btn
            :disabled="
              meeting &&
              meeting.recordings &&
              recordingIndex + 1 == meeting.recordings.length
            "
            @click="handleIndex(true)"
            v-show="hasVideo || hasImage"
            icon
            color="primary"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn
            @click="downloadURI()"
            v-show="hasVideo || hasImage"
            icon
            color="primary"
          >
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card-actions style="border-top: 1px solid #80808024; margin-top: 14px">
        <template v-if="!$store.state.permissions && !oldMeeting">
          <v-menu right open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn dark icon color="yellow" v-on="on">
                <v-icon>mdi-alert</v-icon>
              </v-btn>
            </template>
            <v-card class="pa-3">
              <label style="font-size: 12px; margin-bottom: 0px">
                {{ $i18n.t("ROOM.ENABLEPERMISSION") }}
                <a
                  @click="getPermissions()"
                  style="margin-left: 7px; font-size: 13px; font-weight: 500"
                  >Click here</a
                >
              </label>
            </v-card>
          </v-menu>
        </template>
        <v-btn
          class="mr-3"
          v-if="meeting && meeting.status != 'canceled'"
          :disabled="
            !$store.state.permissions ||
            ($store.state.active_meeting &&
              $store.state.active_meeting.idmeeting == meeting.idmeeting)
          "
          depressed
          color="primary"
          @click="clickOpenRoom()"
          >{{ $i18n.t("ROOM.ENTERROOM") }}</v-btn
        >
        <v-dialog v-model="dialogConfirmJoin" max-width="290">
          <v-card>
            <v-card-title class="headline">{{
              $i18n.t("MEETING.areYouSure")
            }}</v-card-title>

            <v-card-text></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="clickOpenRoom()">{{
                $i18n.t("MEETING.confirm")
              }}</v-btn>

              <v-btn color="red darken-1" text @click="dialogConfirmJoin = false">{{
                $i18n.t("GENERAL.close")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          dark
          color="red"
          v-if="
            !oldMeeting &&
            meeting &&
            meeting.status != 'canceled' &&
            meeting.status != 'finished'
          "
          @click="dialogConfirm = true"
          >{{ $i18n.t("MEETING.cancelMeeting") }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="getMeetingMessages()"
          color="primary"
          v-if="meeting && meeting.status == 'finished'"
        >
          <v-icon>mdi-message</v-icon>
          {{ $i18n.t("MEETING.CHAT") }}
        </v-btn>

        <v-btn text @click="visible = false">{{
          $i18n.t("GENERAL.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-card-title class="headline">{{
          $i18n.t("MEETING.areYouSure")
        }}</v-card-title>

        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="cancelMeeting">{{
            $i18n.t("MEETING.confirm")
          }}</v-btn>

          <v-btn color="red darken-1" text @click="dialogConfirm = false">{{
            $i18n.t("GENERAL.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessages" max-width="400">
      <v-card>
        <v-card-title class="headline" style="text-align: center">
          <v-icon style="margin-right: 15px">mdi-message</v-icon>Chat
        </v-card-title>
        <v-divider></v-divider>
        <MobileChat
          :activecall="false"
          :infomeeting="true"
          :messages="meetingMessages ? meetingMessages.chat : []"
          v-if="dialogMessages"
        ></MobileChat>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogMessages = false">{{
            $i18n.t("GENERAL.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script >
import firebase from "./../firebase/index.js";
import moment from "moment";
import Meeting from "./../models/meeting.js";
import MobileChat from "./../components/MobileChat.vue";

export default {
  components: {
    MobileChat,
  },
  data() {
    return {
      dialogConfirmJoin: false,
      oldMeeting: false,
      visible: false,
      dialogConfirm: false,
      hasVideo: false,
      hasImage: false,
      lastMeetingId: null,
      recordingIndex: 0,
      dialogMessages: false,
      meetingMessages: null,
      loading: false,
      storageRefUrlActual: null,
      storageRefActual: null,
    };
  },
  props: {
    meeting: Object,
  },
  computed: {
    returnColor() {
      let color = "";
      if (this.$props.meeting) {
        let status = this.$props.meeting.status;
        if (status == "canceled") {
          color = "red lighten-3";
        } else if (status == "pending") {
          color = "yellow darken-3";
        } else if (status == "confirmed") {
          color = "green";
        }
      }
      return color;
    },

    getMeetingDayInfo() {
      if (
        this.meeting &&
        this.meeting.idmeeting &&
        this.meeting.idmeeting != this.lastMeetingId
      ) {
        this.lastMeetingId = this.meeting.idmeeting;
        let now = moment();
        let event_end = moment(this.meeting.enddate, "YYYYMMDDHHmm");
        this.oldMeeting = now > event_end ? true : false;
        if (this.meeting.recordings && this.meeting.recordings.length > 0) {
          this.loadRecording();
        } else {
          this.hasVideo = false;
          this.hasImage = false;
          this.loading = false;
          this.storageRefActual = null;
          this.storageRefUrlActual = null;
        }

        return moment(this.meeting.startdate, "YYYYMMDDHHmm")
          .lang(this.$store.state.lang ? this.$store.state.lang : "en")
          .format("MMMM Do YYYY, HH:mm");
      } else {
        return "";
      }
    },
  },
  methods: {
    async getMeetingMessages() {
      let messages = await Meeting.getMeetingMessages(
        new Meeting(this.$props.meeting)
      );
      messages = messages.data();
      this.meetingMessages = messages;
      if (messages && messages.chat && messages.chat.length > 0) {
        this.dialogMessages = true;
      } else {
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "warning",
          title: this.$i18n.t("GENERAL.NOCHAT"),
          text: "",
        });
      }

      /*
      //transcripts
      
      if(messages.transcript.length>0){
        console.log('transcript')
      }else{
        console.log('no transcript')
      } */
    },
    handleIndex(bool) {
      try {
        let video = document.getElementById("oldMeetingVideo");
        video.pause();
      } catch (err) {
        console.log(err);
      }

      if (bool && this.recordingIndex + 1 < this.meeting.recordings.length) {
        this.recordingIndex++;
      } else if (this.recordingIndex - 1 >= 0) {
        this.recordingIndex--;
      }
      this.loadRecording();
    },

    async deleteActiveMedia() {
      let ref = this.storageRefActual;

      let result = await ref.delete();

      let deleted = this.meeting.recordings.splice(this.recordingIndex, 1);
      // File deleted successfully

      let resultUpdate = await Meeting.updateMeetingRecording(
        new Meeting(this.meeting)
      );
      let deleteValue = -Math.abs(deleted[0].size)
      let resultUpdateCompanyStorage =  await Meeting.updateCompanyAndUserStorage(new Meeting(this.meeting), deleteValue)

      if (this.meeting.recordings.length == 0) {
        this.hasVideo = false;
        this.hasImage = false;
        this.loading=false;
        this.storageRefUrlActual=null;
        this.storageRefActual=null;
      } else if (this.meeting.recordings.length >= 1) {
        this.recordingIndex = 0;
      }
      this.loadRecording();
    },
    downloadURI() {
      let url = this.storageRefUrlActual;

      let image = this.meeting.recordings[this.recordingIndex];
      /* var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function (event) {
        var blob = xhr.response;
      };
      xhr.open("GET", url);
      xhr.send();
 */
      var link = document.createElement("a");
      link.download = image.name;
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    loadRecording() {
      let storage = firebase.storage();
      let dateFormated = moment(this.meeting.startdate, "YYYYMMDDHHmm").format(
        "YYYYMMDD"
      );

      //let type = this.meeting.recordings[this.recordingIndex]

      let gsReference = storage.refFromURL(
        `gs://ucvideo.appspot.com/${this.meeting.company}/${
          this.meeting.userid
        }/${dateFormated}/${this.meeting.recordings[this.recordingIndex].name}`
      );

      this.storageRefActual = gsReference;
      this.loading = true;

      gsReference
        .getDownloadURL()
        .then((url) => {
          this.storageRefUrlActual = url;
          let image =
            this.meeting.recordings[this.recordingIndex].name.indexOf(".png") != -1;

          if (image) {
            let imgElem = document.getElementById("meetingImage");
            imgElem.src = url;
            this.hasVideo = false;
            this.hasImage = true;
            this.loading = false;
          } else {
            let video = document.getElementById("oldMeetingVideo");
            video.src = url;
            video.load();
            video.play();
            this.hasVideo = true;
            this.hasImage = false;
            this.loading = false;
          }
        })
        .catch((error) => {
          switch (error.code) {
            default:
              this.hasVideo = false;
              break;
          }
        });
    },
    async getPermissions() {
      let audioAndVideo = false;
      let audio = false;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        this.permissions = true;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.$store.commit("SET_PERMISSIONS_OK", { audio: true, video: true });
        audioAndVideo = true;
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "success",
          title: this.$i18n.t("GENERAL.MICCAMENABLED"),
          text: "",
        });
        return true;
      } catch (err) {}

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.permissions = true;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.$store.commit("SET_PERMISSIONS_OK", { audio: true, video: false });
        audio = true;
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "success",
          title: this.$i18n.t("GENERAL.ONLYMICENABLED"),
          text: "",
        });
        return true;
      } catch (err) {}

      if (!audio && !audioAndVideo) {
        this.$store.commit("SET_PERMISSIONS_OK", {
          audio: false,
          video: false,
        });
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "error",
          title: this.$i18n.t("GENERAL.ERRORMEDIA"),
          text: "",
        });
        this.permissions = false;
        return false;
      }
    },
    async cancelMeeting() {
      try {
        let meeting = this.$props.meeting;
        let copy = Object.assign({}, meeting);
        let resp = await Meeting.cancelMeeting(new Meeting(copy));

        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "success",
          title: "Canceled meeting",
          text: "",
        });
        this.dialogConfirm = false;
        this.visible = false;
        this.$emit("reloadMeetings");
      } catch (err) {
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "error",
          title: "error canceling meeting",
          text: "",
        });
      }
    },
    openDialog(meeting) {
      this.visible = true;

      if (meeting && meeting.customer_email == "noreply") {
        this.clickOpenRoom(meeting);
      }
    },
    clickOpenRoom(meeting) {
      if (this.$store.state.active_meeting && this.dialogConfirmJoin) {
        if (meeting) {
          this.$store.commit("SET_ACTIVE_MEETING", meeting);
        } else {
          this.$store.commit("SET_ACTIVE_MEETING", this.$props.meeting);
        }

        this.visible = false;
        this.$router.replace("/");
        return;
      }

      if (this.$store.state.active_meeting) {
        this.dialogConfirmJoin = true;
        return;
      }

      if (meeting) {
        this.$store.commit("SET_ACTIVE_MEETING", meeting);
      } else {
        this.$store.commit("SET_ACTIVE_MEETING", this.$props.meeting);
      }
      this.visible = false;
      this.$router.replace("/");
    },
    onCopy: function (e) {
      this.$notify({
        group: "feedback",
        duration: 4000,
        type: "success",
        title: this.$i18n.t("ROOM.CLIPBOARDCOPY"),
        text: "",
      });
    },
    onError: function (e) {
      this.$notify({
        group: "feedback",
        duration: 4000,
        type: "error",
        title: this.$i18n.t("ROOM.CANTCLIPBOARDCOPY"),
        text: "",
      });
    },
  },
  watch: {
    visible: function (val) {
      if (val == false) {
        var video = document.getElementById("oldMeetingVideo");
        video.pause();
      }
    },
  },
};
</script>

<style>
.imgLoading {
  opacity: 0.3;
}

.datepickerindex {
  z-index: 9999;
}
#oldMeetingVideo:focus {
  border: 0px;
  outline: 0px;
}
#oldMeetingVideo {
  width: 93%;
  margin: auto;
  display: block;
}

.textAreaNofocus {
  width: 68% !important;
  margin-left: 4% !important;
  resize: none !important;
}

.textAreaNofocus:focus {
  border: 0px !important;
  outline: 0px !important;
}
</style>