import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import * as Auth from "./modules/auth.js";
import * as Meeting from "./modules/meeting.js";

import main from "@/main.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: navigator.language.substring(0, 2),
    active_meeting: null,
    active_call: false,
    actual_uploads: null,
    active_people: 0,
    isDark: false,
    primary: "#654e6a",
    meeting_members: {},
    no_focus_alert: false,
    portrait: "nothing",
    videosource: "user",
    active_rooms: {},
    shard: 0,
    permissions: null,
    client_meeting: null,
    company: {},
    room_configs: {
      virtualBackground: null,
      subtitles: false,
      mirrorSubtitles: false,
      video_quality: 1,
      rec_quality: 0,
      backgroundBlur: false,
      onlyAudio: false
    },
    videoDeviceLabel: null,
    audioInputDeviceLabel: null,
    availableDevices: null
  },
  mutations: {
    SET_USER_COMPANY(state, value) {
      state.company = value
    },
    SET_VIDEO_QUALITY(state, value) {
      state.room_configs.video_quality = value.value
    },
    SET_VIDEO_REC_QUALITY(state, value) {
      state.room_configs.rec_quality = value.value
    },
    SET_THEME(state, value) {
      state.isDark = value;
    },
    SET_PRIMARY(state, value) {
      state.primary = value;
    },
    SET_LANG(state, value) {
      state.lang = value;
      main.i18n.locale = value;
    },
    SET_ACTIVE_MEETING(state, value) {
      state.active_meeting = value;
      if (state.active_meeting == null) state.active_call = false;
    },
    SET_MEETING_MEMBERS(state, value) {
      state.meeting_members = value ? value : {};
    },
    SET_IS_IN_ACTIVE_CALL(state, value) {
      state.active_call = value
      if (!value) {
        this.videosource = "user"
      }
    },
    SET_CAMERA_ORIENTATION(state, value) {
      state.portrait = value
    },
    SET_VIDEO_SOURCE(state, value) {
      state.videosource = value
    },
    SET_ACTUAL_UPLOAD(state, obj) {
      state.actual_uploads = obj;
    },
    SET_ACTUAL_UPLOAD_URL(state, obj) {
      state.actual_uploads.url = obj.url
    },
    SET_NO_FOCUS_WINDOW(state, value) {
      state.no_focus_alert = value
      if (!value) document.title = document.title.replace(" *", "")
    },
    SET_SHARD(state, value) {
      if (isNaN(value)) state.shard = 0;
      else state.shard = parseInt(value);
    },
    SET_PERMISSIONS_OK(state, value) {
      state.permissions = value;
    },
    SET_CLIENT_MEETING(state, value) {
      state.client_meeting = value;
    },
    TOGGLE_CONFIG_SUBTITLES(state) {
      state.room_configs.subtitles = !state.room_configs.subtitles;
    },
    TOGGLE_ONLY_AUDIO(state, value) {
      state.room_configs.onlyAudio = value;
    },
    TOGGLE_CONFIG_SUBTITLES_MIRROR(state) {
      state.room_configs.mirrorSubtitles = !state.room_configs.mirrorSubtitles;
    },
    TOGGLE_BACKGROUND_BLUR(state, value) {
      state.room_configs.backgroundBlur = value;
      if (value) {
        state.room_configs.virtualBackground = false
      }
    },
    SET_INPUT_CONFIG(state, obj) {
      if (obj.input == "video") {
        state.videoDeviceLabel = obj.value
      }
      else if (obj.input == "audio") {
        state.audioInputDeviceLabel = obj.value
      }
    },
    SET_AVAILABLE_DEVICES(state, devices) {
      state.availableDevices = devices
    },
    UNSET_ACTIVE_MEETING(state, value) {
      state.active_meeting = null;
      state.active_call = false;
    },
    SET_VIRTUAL_BACKGROUND(state, value) {
      state.room_configs.virtualBackground = value
      if (value) {
        state.room_configs.backgroundBlur = false
      }
    }
  },
  actions: {
    changeTheme({ commit }, value) {
      commit("SET_THEME", value);
    },
    changePrimary({ commit }, value) {
      commit("SET_PRIMARY", value);
    },
    changeLang({ commit }, value) {
      commit("SET_LANG", value);
    }
  },
  modules: {
    Auth,
    Meeting
  },
  plugins: [new VuexPersistence().plugin]
});
