import firebase from "../firebase/index";

export default class User {


  constructor(obj) {
    this.id = obj.id || "";
    this.fullname = obj.fullname || "";
    this.avatar = obj.avatar || "";
    this.description = obj.description || "";
    this.type = obj.type || "";
    this.password = obj.password || "";
    this.email = obj.email || "";
    this.phone = obj.phone || "";
    this.disabled = obj.disabled || false;
    this.recordings = obj.recordings || "";
    this.lang = obj.lang || navigator.language.substring(0, 5);
    this.recordEnabled = obj.recordEnabled || "both";
    this.storageUsed = obj.storageUsed || 0;
  }

  static async getAll(companyId) {
    const query = await firebase.firestore().collection(`/companies/${companyId}/users`).get();
    return query.docs.map(user => {
      return new User({
        ...user.data(),
        id: user.id
      });
    });
  }

  static async update(user, companyId) {
    user.id = user.id + "@" + companyId;
    let result = await firebase.functions().httpsCallable("ucvideo/userUpdate")(user);
    console.log(result);
    return result;
  }

  static async add(user, companyId) {
    user.id = user.id + "@" + companyId;
    let result = await firebase.functions().httpsCallable("ucvideo/userAdd")(user);
    return result;
  }

  static async remove(user, companyId) {
    user.id = user.id + "@" + companyId;
    let result = await firebase.functions().httpsCallable("ucvideo/userDelete")(user);
    console.log(result);
    return result;
  }

  getObject() {
    return {
      id: this.id, //user@company
      fullname: this.fullname,
      avatar: this.avatar,
      description: this.description,
      type: this.type,
      password: this.password,
      email: this.email,
      phone: this.phone,
      disabled: this.disabled,
      lang: this.lang,
      recordEnabled: this.recordEnabled,
      storageUsed: this.storageUsed
    };
  }

  static validate(user) {
    return !!user.id;
  }

  clone() {
    return new User({
      id: this.id,
      fullname: this.fullname,
      avatar: this.avatar,
      description: this.description,
      type: this.type,
      password: this.password,
      email: this.email,
      phone: this.phone,
      disabled: this.disabled,
      lang: this.lang,
      recordEnabled: this.recordEnabled,
      storageUsed: this.storageUsed
    });
  }
}
