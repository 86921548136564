<template>
  <div>
    <div>
      <v-row v-if="!startMeeting" class="room-row">
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <img
            style="width: 70%;
            margin: auto;
            display: block;"
            :src="require('../../src/assets/videocall.svg')"
            alt="Image not found"
          />
        </v-col>
        <v-col v-if="!meeting && !startMeeting" xl="6" lg="6" md="6" sm="6" cols="12">
          <v-row class="room-row">
            <v-col>
              <v-icon class="rotate">mdi-autorenew</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else-if="meeting && !startMeeting" xl="6" lg="6" md="6" sm="6" cols="12">
          <v-list-item three-line v-if="permissions && getMeetingState === 'confirmed'">
            <v-list-item-content>
              <div
                style="border-bottom: 1px solid #80808026;
                  padding: 10px;
                  padding-right: 8%;"
                class="headline mb-4 text-left"
              >{{$i18n.t("ROOM.WELCOME") +" "+meeting.customer_name}}!!</div>
              <v-list-item-title
                class="mb-1 pl-3 text-left"
              >{{ $i18n.t("ROOM.MEETINGSTART") + getFromNowMeeting()}} ({{getFormattedStart()}})</v-list-item-title>
              <v-list-item-subtitle
                class="overline pl-3 text-left"
              >{{$i18n.t("ROOM.REASON") + meeting.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line v-if="permissions && getMeetingState === 'notontime'">
            <v-list-item-content>
              <div
                style="border-bottom: 1px solid #80808026;
                    padding: 10px;
                    padding-right: 8%;"
                class="headline mb-4 text-left"
              >{{$i18n.t("ROOM.WELCOME") +" "+meeting.customer_name}}!!</div>
              <v-list-item-title
                class="mb-1 pl-3 text-left"
              >{{ $i18n.t("ROOM.PLEASECOMEBACK")}} {{getFromNowMeeting()}}</v-list-item-title>
              <v-list-item-subtitle class="overline pl-3 text-left">({{getFormattedStart()}})</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line v-if="permissions && getMeetingState === 'finished'">
            <v-list-item-content>
              <div
                style="border-bottom: 1px solid #80808026;
                      padding: 10px;
                      padding-right: 8%;"
                class="headline mb-4 text-left"
              >Oops!!</div>
              <v-list-item-title class="mb-1 pl-3 text-left">{{ $i18n.t("ROOM.ALREADYFINISHED")}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line v-if="!permissions">
            <v-list-item-content>
              <div
                style="border-bottom: 1px solid #80808026;
                padding: 10px;
                padding-right: 8%;"
                class="headline mb-4 text-left"
              >{{$i18n.t("ROOM.PLEASE")}}</div>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn
              :disabled="logged"
              v-if="permissions "
              :loading="loadingEnterRoomButton"
              class="ml-4"
              color="primary"
              @click="enterRoom"
            >{{$i18n.t("ROOM.ENTERROOM")}}</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </div>

    <Room
      v-if="meeting && startMeeting"
      :companyProp="meeting.company"
      :userIdProp="meeting.userid"
      :idmeetingProp="meeting.idmeeting"
      :isAgentProp="false"
      :meeting="meeting"
      v-on:endcall="onEndCall()"
    ></Room>
  </div>
</template>

<script>
import Company from "./../models/company.js";
import Meeting from "../models/meeting";
import moment from "moment";
import Room from "./Room";
import { mapState } from "vuex";

export default {
  components: {
    Room,
  },
  data() {
    return {
      logged: false,
      permissions: false,
      meeting: null,
      startMeeting: false,
      loadingEnterRoomButton: false,
    };
  },
  async created() {
    await this.getMeeting();
  },
  mounted() {
    //let permissions = this.getPermissions();
  },
  methods: {
    async getLocation() {
      let cachi = this;
      navigator.geolocation.getCurrentPosition(async (position) => {
        let meeting = new Meeting(cachi.meeting);

        await Meeting.updateMeetingGeoLocation(meeting, position.coords);
      });
    },
    async getPermissions() {
      let audioAndVideo = false;
      let audio = false;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        this.permissions = true;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.$store.commit("SET_PERMISSIONS_OK", { audio: true, video: true });
        await this.getLocation();
        audioAndVideo = true;

        return true;
      } catch (err) {}

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.permissions = true;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.$store.commit("SET_PERMISSIONS_OK", { audio: true, video: false });
        await this.getLocation();
        audio = true;

        return true;
      } catch (err) {}

      if (!audio && !audioAndVideo) {
        this.$store.commit("SET_PERMISSIONS_OK", {
          audio: false,
          video: false,
        });
        this.permissions = false;
        return false;
      }
    },
    async getMeeting() {
      let id = this.$route.query.id;
      let u = this.$route.query.u;
      let c = this.$route.query.c;
      let d = this.$route.query.d;
      if (id && u && c && d) {
        this.meeting = await Meeting.getMeetingForClient(c, u, d, id);

        if (!this.meeting) {
          this.$router.replace(`/404`);
          return;
        } else {
          let loggd = this.$store.state.Auth.isLogged;
          if (loggd) {
            let meetingUserId =
              this.meeting.userid + "@" + this.meeting.company;
            let loggedAgentUserId = this.$store.state.Auth.token.claims.user_id;
            meetingUserId == loggedAgentUserId
              ? (this.logged = true)
              : (this.logged = false);
          }

          this.$store.commit("SET_USER_COMPANY", this.meeting.turn);
          this.$store.commit("SET_CLIENT_MEETING", this.meeting);

          let permissions = this.getPermissions();
        }
      } else this.$router.replace(`/404`);
    },
    getFromNowMeeting() {
      return moment(this.meeting.startdate, "YYYYMMDDHHmm")
        .lang(this.$store.state.lang ? this.$store.state.lang : "en")
        .fromNow();
    },
    getFormattedStart() {
      return moment(this.meeting.startdate, "YYYYMMDDHHmm").format("LLLL");
    },
    enterRoom() {
      this.startMeeting = true;
    },
    onEndCall() {
      this.$router.replace(
        `/endcall?id=${this.meeting.idmeeting}&u=${this.meeting.userid}&c=${
          this.meeting.company
        }&d=${this.meeting.startdate.substring(0, 8)}`
      );
    },
  },
  computed: {
    getMeetingState() {
      //ontime true o false es la hora calculada en el back
      //diff es la diferencia desde ahora hasta el momento que empieza la meeting, si la meeting ya empezo la diferencia va a ser positiva sino negativa
      if (this.meeting) {
        //si el agente tiene la meeting activa, lo dejo entrar al cliente... siempre
        if (this.meeting.active) return "confirmed";
        //si esta en hora y confirmada, o si esta fuera de hora porque ya termino, pero sigue en confirmed (porque el agente todavia la tiene abierta)
        if (
          this.meeting.status === "confirmed" &&
          (this.meeting.time.ontime || this.meeting.time.diff > 0)
        )
          return "confirmed";
        //si esta pendiente, sin confirmar, pero esta en hora
        if (this.meeting.status === "pending" && this.meeting.time.ontime)
          return "confirmed";
        //si esta cancelada o finalizo
        if (
          this.meeting.status === "canceled" ||
          this.meeting.status === "finished" ||
          this.meeting.status === "deleted"
        )
          return "finished";
        //si no esta en hora porque todavia falta, dias quizas
        if (!this.meeting.time.ontime) return "notontime";
        //por defecto finished
        else return "finished";
      }
    },
  },
  watch: {},
};
</script>
<style>
.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>