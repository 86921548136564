<template>
  <span id="chronometer">{{chronometer}}</span>
</template>
<script>
export default {
  name: 'chronometer',
  data(){
    return {
      callSecondsCounter: {'hours':0, 'minutes': '00', 'seconds': '00'}
    }
  },

  computed:{
    chronometer(){
      if(this.callSecondsCounter.hours){
        return ` ${this.callSecondsCounter.hours}:${this.callSecondsCounter.minutes}:${this.callSecondsCounter.seconds}`
      }
      return ` ${this.callSecondsCounter.minutes}:${this.callSecondsCounter.seconds}`
    }
  },

  mounted(){
    setInterval(()=> this.timeCounter(), 1000)
  },

  methods: {
    timeCounter(){
      let callTimeCounter = this.callSecondsCounter
      if (Number(callTimeCounter.seconds) < 59) {
        this.callSecondsCounter.seconds ++;
        if(callTimeCounter.seconds<10){
          this.callSecondsCounter.seconds = '0'+ this.callSecondsCounter.seconds ;
        }
      } else {
        if (callTimeCounter.minutes < 59) {
          this.callSecondsCounter.minutes ++;
          this.callSecondsCounter.seconds = 0;
        } else {
          this.callSecondsCounter.hours ++;
          this.callSecondsCounter.minutes = 0; 
        }

        if(callTimeCounter.minutes<10){
          this.callSecondsCounter.minutes = '0'+ this.callSecondsCounter.minutes ;
        }
      }

    }
  },
}
</script>

<style scoped>

</style>

