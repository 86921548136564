<template>
  <div>
    <div v-if="!$store.state.active_meeting">
      <v-row class="room-row">
        <v-col class="col-12 col-sm-6 col-md-4">
          <v-card class="p-4 rounded" elevation="0">
            <p class="h3 text-left">
              {{ $i18n.t("GENERAL.HI") }}
              {{ $store.state.Auth.token.claims.name }}.
            </p>
            <p class=".text-md-body-1 text-left">
              <template v-if="permissions">{{
                $i18n.t("ROOM.UNDERTEXT")
              }}</template>
              <template v-else>{{ $i18n.t("ROOM.ENABLEPERMISSION") }}</template>
            </p>

            <digital-clock
              style="
                color: rgb(253, 73, 92);
                position: absolute;
                left: 32%;
                font-size: 61px;
                font-family: Roboto;
                font-weight: 500;
                position: absolute;
                text-shadow: 3px 3px 2px rgb(218 218 218);
              "
              :blink="false"
            />
          </v-card>

          <img
            class=""
            style="width: 90%%; margin: auto; display: block"
            :src="require('../../src/assets/waiting.svg')"
            alt="Image not found"
          />
        </v-col>

        <v-col class="col-12 col-sm-6 col-md-4 d-none d-sm-block">
          <v-row>
            <v-col>
              <v-card
                style="background-color: #abc8e74a"
                elevation="2"
                class="pt-5 pb-5"
              >
                <v-list-item>
                  <v-list-item-avatar style="background-color: #abc8e7">
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left h4">
                      {{ this.stats.total }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-left"
                      >Total</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                elevation="2"
                style="background-color: #d8c4e54a"
                class="pt-5 pb-5"
              >
                <v-list-item>
                  <v-list-item-avatar style="background-color: #d8c4e5">
                    <v-icon>mdi-account-multiple-check</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left h4">{{
                      this.stats.finished
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-left">{{
                      $i18n.t("GENERAL.finished")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card
                elevation="2"
                style="background-color: #d5ede74a"
                class="pt-5 pb-5"
              >
                <v-list-item>
                  <v-list-item-avatar style="background-color: #d5ede7">
                    <v-icon>mdi-bell-cancel</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left h4">{{
                      this.stats.pending
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-left">{{
                      $i18n.t("GENERAL.pending")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                elevation="2"
                style="background-color: #ffbe9f4a"
                class="pt-5 pb-5"
              >
                <v-list-item>
                  <v-list-item-avatar style="background-color: #ffbe9f">
                    <v-icon>mdi-bell-cancel</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left h4">
                      {{ this.stats.canceled }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-left">{{
                      $i18n.t("GENERAL.canceled")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row class="mt-8">
            <v-col>
              <v-card class="pa-4">
                <p class="h5">Videollamadas por hora</p>
                <v-sparkline
                  :labels="[
                    '12am',
                    '3am',
                    '6am',
                    '9am',
                    '12pm',
                    '3pm',
                    '6pm',
                    '9pm',
                  ]"
                  :value="[200, 675, 410, 390, 310, 460, 250, 240]"
                  height="150"
                  color="primary"
                  line-width="2"
                  padding="10"
                  stroke-linecap="round"
                  smooth
                ></v-sparkline>
              </v-card>
            </v-col>
          </v-row> -->
        </v-col>

        <v-col class="col-12 col-sm-6 col-md-4 d-none d-sm-flex">
          <Schedule
            @update_stats="update_stats"
            v-if="$store.state.Auth.isLogged"
            :layoutMode="true"
          ></Schedule>
        </v-col>
      </v-row>
    </div>

    <Room
      :key="
        $store.state.active_meeting ? $store.state.active_meeting.idmeeting : ''
      "
      v-if="$store.state.active_meeting"
      :companyProp="$store.state.active_meeting.company"
      :userIdProp="$store.state.active_meeting.userid"
      :idmeetingProp="$store.state.active_meeting.idmeeting"
      :meeting="$store.state.active_meeting"
      :isAgentProp="true"
      v-on:endcall="onEndCall()"
      v-on:exitroom="onExitRoom()"
    
    ></Room>

    <VideoUploads v-if="$store.state.actual_uploads"></VideoUploads>

    <DispositionDialog
      ref="dispositionDialog"
      :meeting="$store.state.active_meeting"
      :user="user"
      :company="company"
    ></DispositionDialog>
  </div>
</template>

<script>
import Room from "./Room";
import DispositionDialog from "./../components/DispositionDialog";
import VideoUploads from "./../components/VideoUploads";

import Schedule from "./../components/Schedule";

import DigitalClock from "vue-digital-clock";

import RoomNotifications from "./../communications/RoomNotifications";
import Meeting from "../models/meeting";
export default {
  components: {
    Room,
    DispositionDialog,
    VideoUploads,
    Schedule,
    DigitalClock,
  },
  data() {
    return {
      roomNotifications: null,
      user: this.$store.state.Auth.token.claims.sub.split("@")[0],
      company: this.$store.state.Auth.token.claims.sub.split("@")[1],
      permissions: false,
      stats: {
        total: 0,
        confirmed: 0,
        pending: 0,
        finished: 0,
      },
    };
  },
  created() {
    this.roomNotifications = new RoomNotifications(this.company, this.user);
    this.$store.dispatch("Meeting/getMeetings", {
      company: this.company,
      user: this.user,
    });
  },
  mounted() {
    let permissions = this.getPermissions();
  },
  methods: {
    update_stats(meetingsList) {
      let total = meetingsList.length;
      let finished = meetingsList.filter(
        (meeting) => meeting.meeting.status == "finished"
      ).length;
      let pending = meetingsList.filter(
        (meeting) => meeting.meeting.status == "pending"
      ).length;
      let canceled = meetingsList.filter(
        (meeting) => meeting.meeting.status == "canceled"
      ).length;

      this.stats.total = total;
      this.stats.finished = finished;
      this.stats.pending = pending;
      this.stats.canceled = canceled;
    },
    async getPermissions() {
      let audioAndVideo = false;
      let audio = false;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        this.permissions = true;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.$store.commit("SET_PERMISSIONS_OK", { audio: true, video: true });
        audioAndVideo = true;
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "success",
          title: this.$i18n.t("GENERAL.MICCAMENABLED"),
          text: "",
        });
        return true;
      } catch (err) {
        console.log("error");
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.permissions = true;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.$store.commit("SET_PERMISSIONS_OK", { audio: true, video: false });
        audio = true;
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "success",
          title: this.$i18n.t("GENERAL.ONLYMICENABLED"),
          text: "",
        });
        return true;
      } catch (err) {
        console.log("error2");
      }

      if (!audio && !audioAndVideo) {
        this.$store.commit("SET_PERMISSIONS_OK", {
          audio: false,
          video: false,
        });
        this.$notify({
          group: "feedback",
          duration: 4000,
          type: "error",
          title: this.$i18n.t("GENERAL.ERRORMEDIA"),
          text: "",
        });
        this.permissions = false;
        return false;
      }
    },

    onEndCall() {
      this.$store.commit("SET_CAMERA_ORIENTATION", "nothing");
    },
    onExitRoom() {
      this.$refs.dispositionDialog.openDialog();
      this.$store.commit("SET_ACTIVE_MEETING", null);
    },
  },
  computed: {},
};
</script>
<style>
</style>