<template>
  <v-card elevation="0" max-height="800px" class="pb-6 pt-2">
    <v-row no-gutters class="px-6 table__header">
      <v-col xl="4" lg="4" md="6" sm="5" cols="12" class="table__searcher pl-2">
        <v-text-field
          v-model="search"
          :label="$i18n.t('TABLE.searchLabel')"
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col xl="3" lg="3" md="4" sm="5" cols="12" class="pl-2" v-if="acceptCompanies">
        <v-autocomplete
          filled
          rounded
          @change="getCompany"
          :value="companySelected"
          :items="companiesNames"
          :search-input.sync="searchCompany"
          hide-no-data
          hide-selected
          item-text="Description"
          item-value="API"
          label="Select Company"
          placeholder="Start typing to Search"
          prepend-inner-icon="mdi-domain"
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col class="text-end">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-on="on"
                fixed
                bottom
                right
                color="green"
                dark
                fab
                @click="$emit('add')"
                class="add-new__floating"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>{{ $i18n.t("TABLE.addNew")}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="green" dark fab @click="$emit('add')" class="add-new__button">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t("TABLE.addNew")}}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="table-overflow">
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <v-container fluid>
        <v-row no-gutters>
          <v-col
            v-for="item in filtered"
            :key="item.id"
            xl="2"
            lg="2"
            md="3"
            sm="6"
            cols="12"
            style="min-width: 280px;"
            class="px-4 py-4"
          >
            <TableItem :item="item" @save="$emit('save', item)" @delete="$emit('delete', item)">
              <slot slot="icon">{{ icon }}</slot>
              <slot slot="title">{{ item[title] }}</slot>
              <slot slot="subtitle">{{ item[subtitle] }}</slot>
              <slot slot="subtitleIcon">{{ subtitleIcon }}</slot>
            </TableItem>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import TableItem from "@/components/TableItem.vue";
import Company from "@/models/company.js";
import { mapState } from "vuex";

export default {
  data(){
    return{
      search : "",
      searchCompany : "",
      companySelected: ""
    }
  },
  components: {
    TableItem,
  },
  computed: {
    companiesNames() {
    let x = this.companies.map((c) => {
      return c.name;
    });
    return x;
  },

  filtered() {
    return (this.data || []).filter((item) => {
      let valid = false;
      Object.keys(item).forEach((key) => {
        if (this.headers.indexOf(key) > -1) {
          if (
            (item[key] || "")
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          ) {
            valid = true;
          }
        }
      });
      return valid;
    });
  }

  },
  props:{
    data: Array,
    icon: String,
    title: String,
    subtitle:String,
    subtitleIcon:String,
    loading: Boolean,
    acceptCompanies:Boolean,
    companies: Array,
    headers: Array
  },
  methods:{

  getCompany(e) {
    let companyId;
    this.companies.forEach((c) => {
      if (c.name == e) {
        companyId = c.id;
      }
    });
    this.$emit("getCompany", companyId);
  },

  

  }  
}
</script>

<style scoped lang="scss">
.add-new__button {
  display: none;
}
@media (min-width: 1264px) {
  .table-overflow {
    padding: 0 40px;
    overflow-y: auto;
    max-height: 550px;
  }
  .table__header {
    padding-top: 20px !important;
  }
  .table__searcher {
    padding-left: 40px !important;
  }
}
@media (min-width: 600px) {
  .table__header {
    padding-top: 20px;
  }
  .table__searcher {
    padding-left: 5px;
  }
  .add-new__floating {
    display: none;
  }
  .add-new__button {
    display: inline-block;
  }
  .table-overflow {
    overflow-y: auto;
    max-height: 550px;
  }
}
</style>
