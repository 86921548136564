<template>
 <v-dialog
      v-model="modalMeeting"
      width="450px"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
      overlay-opacity="0.5"
      persistent
    >
      <v-card>
        <v-card-title class="justify-center" style="color: #525252;">
          <v-icon class="mr-2">mdi-alert</v-icon>
          Entering meeting room 
        </v-card-title>

        <v-card-text class="pt-2 px-12">
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              Are you sure?
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-2">
          <div class="text-end" style="width: 100%;">
            <v-btn class="mr-2" @click="modalMeeting=false" text>{{ $i18n.t("GENERAL.close") }}</v-btn>
            <v-btn
              depressed
              color="primary"
              @click="clickOpenRoom()"
            >{{ $i18n.t("GENERAL.send") }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  components:{
  },
  data() {
    return {
      modalMeeting:false
    }
  },
  props:{
    meeting: Object,
    showModal:Number
  },
  methods: {
    clickOpenRoom(){
      this.$store.commit("SET_ACTIVE_MEETING", this.$props.meeting);
      this.modalMeeting= false;
    }
  },
  watch:{
      'showModal':function(val){
          this.modalMeeting= true;
      }
  }
};
</script>

<style lang="scss" scoped>
</style>