<template>
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{$i18n.t('DISPOSITIONS.TITLE')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-form ref="saveDispositionForm" lazy-validation>
                <v-select
                  v-model="selectedDisposition"
                  :items="dispositions"
                  :label="$i18n.t('DISPOSITIONS.QUALITY')"
                  required
                ></v-select>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <small>*{{$i18n.t('GENERAL.required')}}</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeDispositionDialog()"
        >{{$i18n.t('GENERAL.close')}}</v-btn>
        <v-btn
          color="blue darken-1"
          :loading="loadingDisposition"
          text
          @click="saveDispositionDialog()"
        >{{$i18n.t('GENERAL.save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Company from './../models/company.js'
import Meeting from '../models/meeting.js';
export default {
  props:{
    meeting: Object,
    user: String, 
    company: String
  },
  components:{
    
  },
  data() {
    return {
      visible: false,
      dispositions: [],
      meetingObj: null,
      selectedDisposition: "",
      loadingDisposition: false
    };
  },
  created(){
    this.getDispositions();
  },  
  mounted() {

  },
  methods: {
    async getDispositions(){
      let company = await Company.getById(this.company);
      if(company) this.dispositions = company.dispositions;
    },

    openDialog(){
      this.meetingObj = this.meeting;
      this.visible = this.dispositions.length > 0;
    },
    closeDispositionDialog(){
      this.visible = false;
    },
    async saveDispositionDialog(){
      if(this.$refs.saveDispositionForm.validate()){
        this.loadingDisposition = true;
        try{
          await Meeting.updateMeeting(this.meetingObj, {disposition: this.selectedDisposition});
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: "Disposition was saved",
          });
          this.loadingDisposition = false;
          this.visible = false;
          this.selectedDisposition = "";
        }catch(err){
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "Error",
          });
        }
        
      }
    }
  },
  computed:{
    
  }
};
</script>