import store from "./../store/index";

export default class WebRTC {
  constructor(signal) {
    this.store = store;
    this.signal = signal;
    this.polite = signal.isAgent;
    this.localVideo = document.getElementById("localVideo");
    this.remoteVideo = document.getElementById("remoteVideo");
    this.fileIndex = null;
    this.file = new Object();
    this.file.size = 0;
    this.file.name = "";
    this.file.receiveSize = 0;
    this.file.receiveBuffer = [];
    this.blurStream = null;




    this.configuration = {
      iceServers: [
        {
          urls: [
            "turn:" + store.state.company.turn,
            "stun:stun.l.google.com:19302",
            "stun:stun.ucontactcloud.com"
          ],
          username: store.state.company.turnUser,
          credential: store.state.company.turnPassword,
        },
      ],
    };

    this.createConstraints();
    this.createRTCPeerConnection();
  }

  //Constraints for Audio and
  createConstraints() {
    this.pcconstraints = {
      optional: [
        // { googIPv6: true },
        { googHighStartBitrate: 0 },
        { googPayloadPadding: true },
        { googScreencastMinBitrate: 300 },
        { googCpuOveruseDetection: true },
        { googCpuOveruseEncodeUsage: true },
        { googCpuUnderuseThreshold: 45 },
        { googCpuOveruseThreshold: 85 },
        { googSuspendBelowMinBitrate: true },
        { googImprovedWifiBwe: true },
      ],
    };
    //Audio
    this.audioconstraints = {
      echoCancellation: { exact: true },
      autoGainControl: { exact: true },
      noiseSuppression: { exact: true },
      googHighpassFilter: true,
      googEchoCancellation: true,
      googExperimentalNoiseSuppression: true,
      googExperimentalEchoCancellation: true,
      googExperimentalAutoGainControl: true,
      googAudioMirroring: true,
    };


    //Video
    this.videoconstraints = {
      width: { min: 320, max: 1280, ideal: 1280 },
      height: { min: 180, max: 720, ideal: 720 },
      aspectRatio: { ideal: 1.77777778 },
      facingMode: { ideal: "user" },
    };
    let video_resolution_configs = [
      { height: 180, width: 320 },
      { height: 360, width: 640, },
      { height: 720, width: 1280 }
    ]
    let videoResolution = video_resolution_configs[this.store.state.room_configs.video_quality];

    this.videoconstraints.width = {
      exact: videoResolution.width
    }
    this.videoconstraints.height = {
      exact: videoResolution.height
    }


    if (this.store.state.availableDevices && this.store.state.availableDevices.length > 0) {
      let videoInputDevice = this.store.state.availableDevices.filter(
        (device) => device.deviceId === this.store.state.videoDeviceLabel
      );

      if (videoInputDevice && videoInputDevice.length > 0) {
        this.videoconstraints.deviceId = { exact: this.store.state.videoDeviceLabel };
      }

      let audioInputDevice = this.store.state.availableDevices.filter(
        (device) => device.deviceId === this.store.state.audioInputDeviceLabel
      );

      if (audioInputDevice && audioInputDevice.length > 0) {
        this.audioconstraints.deviceId = { exact: this.store.state.audioInputDeviceLabel };
      }
    }
  }

  //Creates webrtc peer connection
  createRTCPeerConnection() {
    this.pc = new RTCPeerConnection(this.configuration, this.pcconstraints);

    this.makingOffer = false;
    this.ignoreOffer = false;

    //State of the connection
    this.pc.onconnectionstatechange = (event) => {
      console.debug("NEW", event.srcElement.connectionState, event);
      switch (event.srcElement.connectionState) {
        case "connected":
          if (this.polite) {
            this.startDataChannel();
          }
          break;
        case "disconnected":
          break;
        case "failed":
          if (this.pc) {
            if (typeof this.pc.restartIce === "function") {
              this.pc.restartIce();
            }
          }
          break;
        case "closed":
          break;
      }
    };

    //ICE candidate arrives send it to the other peer to establish connection
    this.pc.onicecandidate = (event) => {
      if (event.candidate && event.candidate.candidate != "") {
        this.signal.sendSignalMessage({ candidate: event.candidate.toJSON() });
      }
    };

    // If user is offerer let the 'negotiationneeded' event create the offer
    this.pc.onnegotiationneeded = async () => {
      try {
        this.makingOffer = true;
        await this.pc.setLocalDescription(await this.pc.createOffer());
        this.signal.sendSignalMessage({
          sdp: this.pc.localDescription.toJSON(),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.makingOffer = false;
      }
    };

    // once media for a remote track arrives, show it in the remote video element
    this.pc.ontrack = ({ track, streams }) => {
      console.debug("NEW", track);
      // don't set srcObject again if it is already set.
      //if (this.remoteVideo.srcObject) return;
      this.remoteVideo.srcObject = streams[0];
    };

    //on datachannel open by remote
    this.pc.ondatachannel = (e) => {
      this.datachannel = e.channel;
      this.datachannel.binaryType = "arraybuffer";
      this.datachannel.onopen = (e) => console.debug("Datachannel Opened.");
      this.datachannel.onmessage = (e) => this.handleDataChannelMessage(e);
    };
  }

  //Handle Session Description Protocol
  async handleSDP(sdp) {
    const offerCollision =
      sdp.type == "offer" &&
      (this.makingOffer || this.pc.signalingState != "stable");

    this.ignoreOffer = !this.polite && offerCollision;
    if (this.ignoreOffer) {
      return;
    }
    await this.pc.setRemoteDescription(sdp); // SRD rolls back as needed
    if (sdp.type == "offer") {
      await this.pc.setLocalDescription(await this.pc.createAnswer());
      this.signal.sendSignalMessage({ sdp: this.pc.localDescription.toJSON() });
    }
  }

  // Add the new ICE candidate to our connections remote description
  async handleICECandidate(candidate) {
    try {
      await this.pc.addIceCandidate(candidate);
    } catch (err) {
      if (!this.ignoreOffer) throw err; // Suppress ignored offer's candidates
    }
  }

  //Get your Video in localVideo and add the stream
  StartCall() {

    if (store.state.permissions && store.state.permissions.video && store.state.permissions.audio) {
      if (this.blurStream) {
        this.cleanVideo();
        this.localVideo.srcObject = this.blurStream;
        navigator.mediaDevices
          .getUserMedia({
            audio: this.audioconstraints,
          })
          .then((stream) => {
            stream.getTracks().forEach((track) => this.pc.addTrack(track, stream));
            this.blurStream.getTracks().forEach((track) => this.pc.addTrack(track, stream));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.cleanVideo();
        navigator.mediaDevices
          .getUserMedia({
            audio: this.audioconstraints,
            video: this.videoconstraints,
          })
          .then((stream) => {
            stream.getTracks().forEach((track) => this.pc.addTrack(track, stream));
            this.localVideo.srcObject = stream;
            if (store.state.permissions && store.state.permissions.audio && store.state.permissions.video && store.state.room_configs.onlyAudio) {
              this.mutevideo()
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }


    } else if (store.state.permissions && !store.state.permissions.video && store.state.permissions.audio) {
      this.cleanVideo();

      let blackScreenCanvas = document.getElementById("blackScreenCanvas");

      let blackStream = blackScreenCanvas.captureStream(1)

      navigator.mediaDevices
        .getUserMedia({
          audio: this.audioconstraints,
        })
        .then((stream) => {
          stream.getTracks().forEach((track) => this.pc.addTrack(track, stream));
          blackStream.getVideoTracks().forEach((track) => this.pc.addTrack(track, stream));
          this.localVideo.srcObject = blackStream;
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  //Mute only audio
  mutesound() {
    const sender = this.pc.getSenders().find((s) => {
      return s.track.kind == "audio";
    });

    if(sender){
      sender.track.enabled = !sender.track.enabled;

      this.signal.communications.audioMuted = !sender.track.enabled;
      if (this.signal.cc) {
        if (sender.track.enabled) {
          this.signal.cc.start()
        } else {
          this.signal.cc.stop()
        }
      }
    }
  }

  //Mute only video
  mutevideo() {
    const sender = this.pc.getSenders().find((s) => {
      return s.track.kind == "video";
    });
    if(sender){
      sender.track.enabled = !sender.track.enabled;
      this.signal.communications.videoMuted = !sender.track.enabled
    }
    
  }

  //Change Resolution
  changeResolution(height, width) {

    this.videoconstraints.width = { exact: width };
    this.videoconstraints.height = { exact: height };

    this.localVideo.srcObject.getTracks().forEach((track) => {
      if (track.kind == "video") {
        track.applyConstraints(this.videoconstraints);
      }
    });
  }


  //Replace streams
  replaceVideo(stream,isRear) {
    this.pc.getSenders().find((s) => {
      if (s.track.kind == "video") {
        s.replaceTrack(stream.getVideoTracks()[0]);
      } else if (s.track.kind == "audio") {
        if (stream.getAudioTracks()[0]) {
          s.replaceTrack(stream.getAudioTracks()[0]);
        }
      }
    });

      if(isRear){
        this.localVideo.srcObject = stream;
      }else{
        this.localVideo.srcObject.getTracks().forEach((track) => {
          if (track.kind == "video") {
            track.stop();
            this.localVideo.srcObject.removeTrack(track);
            this.localVideo.srcObject.addTrack(stream.getVideoTracks()[0]);
          } else if (track.kind == "audio") {
            if (stream.getAudioTracks()[0]) {
              track.stop();
              this.localVideo.srcObject.removeTrack(track);
              this.localVideo.srcObject.addTrack(stream.getAudioTracks()[0]);
            }
          }
        });
      }
  }

  //Send DesktopScreen
  async sendDesktop() {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia();
      stream.getVideoTracks()[0].onended = () => {
        this.sendCamera();
      };
      this.replaceVideo(stream);
      this.signal.communications.screenSharing = true;
      return true;
    } catch (err) {
      console.error(err);
      this.signal.communications.screenSharing = false;
      return false;
    }
  }

  //Send Fron Camera
  async sendCamera() {
    try {

      if (this.localVideo.srcObject) {
        this.localVideo.srcObject.getTracks().forEach((track) => {
          if(track.kind=='video'){
            track.stop();
            this.localVideo.srcObject.removeTrack(track);
          }
        });
        this.localVideo.srcObject = null;
      }

      this.videoconstraints.facingMode = { ideal: "user" };
      const stream = await navigator.mediaDevices.getUserMedia({
        audio:false,
        video: this.videoconstraints
      });

      if (this.signal.communications.screenSharing) { 
        this.signal.communications.screenSharing = false;
      }
      if (this.signal.communications.videoMuted) {
        stream.getVideoTracks()[0].enabled = false;
      }

      this.replaceVideo(stream,true);

    } catch (err) {
      console.error(err);
    }
  }

  //Send Back Camera
  async sendRearCamera() {
    try {
      if (this.localVideo.srcObject) {
        this.localVideo.srcObject.getTracks().forEach((track) => {
          if(track.kind=='video'){
            track.stop();
            this.localVideo.srcObject.removeTrack(track);
          }
        });
        this.localVideo.srcObject = null;
      }

      this.videoconstraints.facingMode = { exact: "environment" };

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: this.videoconstraints
      });
      this.replaceVideo(stream,true);
    } catch (err) {
      console.error(err);
    }
  }

  //change the input device for audio or video
  changeInputDevice() {

    this.localVideo.srcObject.getTracks().forEach((track) => {
      if (track.kind == "video") {
        this.videoconstraints.deviceId = { exact: this.store.state.videoDeviceLabel };
      
        navigator.mediaDevices
          .getUserMedia({
            video: this.videoconstraints
          })
          .then((stream) => {
            this.replaceVideo(stream)
          })
          .catch((error) => {
            console.error(error);
          });
        
        track.applyConstraints(this.videoconstraints);
      }
      if (track.kind == "audio") {
        this.audioconstraints.deviceId = { exact: this.store.state.audioInputDeviceLabel };

        navigator.mediaDevices
          .getUserMedia({
            audio: this.audioconstraints
          })
          .then((stream) => {
            this.replaceVideo(stream)
          })
          .catch((error) => {
            console.error(error);
          });

        track.applyConstraints(this.audioconstraints);
      }
    });

  }

  //Datachannel
  startDataChannel() {
    if (!this.datachannel) {
      this.datachannel = this.pc.createDataChannel("filetransfer");
      this.datachannel.binaryType = "arraybuffer";
      this.datachannel.onmessage = (e) => this.handleDataChannelMessage(e);
      console.debug("Datachannel Created");
    }
  }

  handleDataChannelMessage(e) {
    var data = e.data;
    if (data instanceof ArrayBuffer) {
      this.getDatachannelFile(data);
    }

    if (data instanceof Blob) {
      console.debug("Blob");
    }

    if (data instanceof String) {
      console.debug("String ->" + data);
      this.getDatachannelText(data);
    }

    if (typeof data === "string") {
      console.debug("String ->" + data);
      this.getDatachannelText(data);
    }
  }

  //Send Datachannel
  sendData(data) {
    if (this.datachannel && this.datachannel.readyState == "open") {
      this.datachannel.send(JSON.stringify(data));
    }
  }

  //Send file trough datachannel
  sendFile(file) {
    if (this.datachannel.readyState == "open" && file && file.size != 0) {
      const chunkSize = 16384;
      let fileReader = new FileReader();
      let offset = 0;

      fileReader.addEventListener("error", (error) =>
        console.error("Error reading file:", error)
      );
      fileReader.addEventListener("abort", (event) =>
        console.warn("File reading aborted:", event)
      );
      fileReader.addEventListener("load", (e) => {
        this.datachannel.send(e.target.result);
        offset += e.target.result.byteLength;

        if (offset < file.size) {
          readSlice(offset);
        }

        if (offset == file.size) {
          this.signal.communications.messages[this.fileIndex].progress = 100;
        }
      });

      this.fileIndex = this.signal.communications.messages.length;
      this.signal.communications.messages.push({
        size: file.size,
        name: file.name,
        incoming: false,
        type: "file",
        progress: 0,
        url: null,
      });

      const readSlice = (o) => {
        const slice = file.slice(offset, o + chunkSize);
        fileReader.readAsArrayBuffer(slice);
        //update progress percentage
        let progressPecentage = Math.floor(Math.abs((o / file.size) * 100));

        if (
          this.signal.communications.messages[this.fileIndex].progress !=
          progressPecentage
        ) {
          this.signal.communications.messages[
            this.fileIndex
          ].progress = progressPecentage;
          console.debug("Progress ", progressPecentage);
        }
      };

      readSlice(0);
    }
  }

  //Text From Datachannel
  getDatachannelText(data) {
    let text = JSON.parse(data);

    //If close captions
    if (text.cc) {
      let elem = document.getElementById("pCaptions");
      if (elem && text && text.cc && text.cc != "") {
        let sCaptions = document.getElementById("sCaptions");
        sCaptions.style.display = "block";
        elem.innerText = text.cc;
      }
    }

    //location message
    if(text.chat && text.chat.indexOf("||&||")!=-1){
      this.signal.communications.messages.push({
        message: text.chat,
        incoming: true,
        type: "location",
      });
      return;
    }

    //if chat message
    if (text.chat) {
      this.signal.communications.messages.push({
        message: text.chat,
        incoming: true,
        type: "text",
      });
    }

    //Info about incoming file
    if (text.file) {
      this.file.size = text.size;
      this.file.name = text.file;
      this.fileIndex = this.signal.communications.messages.length;
      this.signal.communications.messages.push({
        size: this.file.size,
        name: this.file.name,
        incoming: true,
        type: "file",
        progress: 0,
        url: null,
      });
    }

    //Camera Orientation
    if (typeof text.portrait != "undefined") {
      this.store.commit("SET_CAMERA_ORIENTATION", text.portrait);
      if (this.polite && this.signal.communications.videoRecorder.record) {
        this.signal.communications.videoRecorder.rotateRecording(text.portrait);
      }
    }

    if (text.videosource) {
      this.store.commit("SET_VIDEO_SOURCE", text.videosource);
    }

  }

  //File From Datachannel
  getDatachannelFile(data) {
    this.file.receiveBuffer.push(data);
    this.file.receiveSize += data.byteLength;
    let progressPecentage = Math.floor(
      Math.abs((this.file.receiveSize / this.file.size) * 100)
    );

    if (
      this.signal.communications.messages[this.fileIndex].progress !=
      progressPecentage
    ) {
      this.signal.communications.messages[
        this.fileIndex
      ].progress = progressPecentage;
      console.debug("Progress ", progressPecentage);
    }

    //End receiving file
    if (this.file.receiveSize === this.file.size) {
      const received = new Blob(this.file.receiveBuffer);
      console.debug("Got File: " + this.file.name + " Size: " + this.file.size);

      this.signal.communications.messages[
        this.fileIndex
      ].url = URL.createObjectURL(received);

      this.file.receiveBuffer = [];
      this.file.receiveSize = 0;
      this.file.name = "";
      this.file.size = 0;
    }
  }

  End() {
    if (this.pc) {
      this.pc.ontrack = null;
      this.pc.onnegotiationneeded = null;
      this.pc.onicecandidate = null;
      this.pc.oniceconnectionstatechange = null;
      this.pc.onsignalingstatechange = null;
      this.pc.onicegatheringstatechange = null;

      this.cleanVideo();

      if (this.datachannel) {
        this.datachannel.close();
        this.datachannel = null;
        delete this.datachannel;
      }

      this.pc.close();
      this.pc = null;
      delete this.pc;
    }
  }

  async cleanVideo() {
    if (this.remoteVideo.srcObject) {
      this.remoteVideo.srcObject.getTracks().forEach((track) => {
        track.stop();
        this.remoteVideo.srcObject.removeTrack(track);
      });
      this.remoteVideo.srcObject = null;
    }

    if (this.localVideo.srcObject) {
      this.localVideo.srcObject.getTracks().forEach((track) => {
        track.stop();
        this.localVideo.srcObject.removeTrack(track);
      });
      this.localVideo.srcObject = null;
    }
  }

  endScreenStream(){

    if (this.localVideo.srcObject) {
      this.localVideo.srcObject.getTracks().forEach((track) => {
        if(track.kind=="video"){
          track.stop();
        this.localVideo.srcObject.removeTrack(track);
        }
        
      });
      this.localVideo.srcObject = null;
    }

  }

  toggleBlurredStream(streamBlur) {
    if (streamBlur) {
      this.replaceVideo(streamBlur)
    } else {
      navigator.mediaDevices
        .getUserMedia({
          video: this.videoconstraints
        })
        .then((stream) => {
          this.replaceVideo(stream)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
