<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on" @click.prevent="$router.push('/admin')">
          <v-icon size="30">mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>{{ $i18n.t("TABLE.back")}}</span>
    </v-tooltip>
    <Table
      :data="companies"
      :headers="headers"
      :loading="loading"
      :icon="'mdi-domain'"
      :title="'name'"
      :subtitle="'country'"
      :subtitleIcon="'mdi-map-marker'"
      @save="showSave"
      @add="showAdd"
      @delete="showDelete"
      :companies="[]"
      :acceptCompanies="false"
    ></Table>

    <v-dialog
      v-model="dialogs.add"
      persistent
      width="1000px"
      overlay-opacity="0.5"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
    >
      <v-card>
        <v-tabs vertical id="campaign">
          <v-tab
            
            v-for="setting in companySettings"
            :key="setting.name"
            style="justify-content: start; color: white;"
          >
            <v-icon class="pr-2 pb-1" color="white">{{ setting.icon }}</v-icon>

            <template v-if="setting.icon=='mdi-border-color'">{{$i18n.t("COMPANY.DISPOSITIONS")}}</template>
            <label v-else>{{ setting.name }}</label>
          </v-tab>
          <v-tab-item>

            
            <v-card class="save-card px-8 pb-4 pt-8">
              <v-card-text class="pt-6">
                <v-row no-gutters class="px-2">
                  <v-col cols="12" class="px-2">
                    <label v-show="(dialogs.save == true && !dialogs.add == false)" style="position: absolute;
    right: 10px;
    top: -40px;"><v-icon>mdi-account</v-icon> {{ $i18n.t("COMPANY.TOTALAGENTS") + company.actualAgents}} </label>
                    
                    <v-form ref="add-form" lazy-validation>
                      <v-text-field
                        filled
                        dense
                        rounded
                        v-model="company.id"
                        :rules="[rules.required,rules.notExist]"
                        placeholder="ex: Integra"
                        prepend-icon="mdi-identifier"
                        :label="$i18n.t('COMPANY.idLabel')"
                        :disabled="(dialogs.save == true && !dialogs.add == false)"
                      ></v-text-field>
                      <v-text-field
                        filled
                        dense
                        rounded
                        v-model="company.name"
                        :rules="[rules.required]"
                        prepend-icon="mdi-account"
                        :label="$i18n.t('COMPANY.nameLabel')"
                      ></v-text-field>
                      <v-row no-gutters>
                        <v-col cols="6" class="pr-2">
                          <v-autocomplete
                            :rules="[rules.required]"
                            v-model="company.country"
                            :items="itemsCountry"
                            dense
                            prepend-icon="mdi-web"
                            filled
                            rounded
                            :label="$i18n.t('COMPANY.countryLabel')"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-text-field
                            filled
                            dense
                            rounded
                            type="number"
                            :rules="[rules.required]"
                            v-model="company.shard"
                            prepend-icon="mdi-cards-diamond"
                            :label="$i18n.t('COMPANY.shardLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-text-field
                            v-if="this.$store.state.Auth.token.claims.type=='superuser'"
                            filled
                            dense
                            rounded
                            type="number"
                            min="1"
                            :rules="[rules.required,rules.minAgents]"
                            v-model="company.maxAgents"
                            prepend-icon="mdi-account"
                            :label="$i18n.t('COMPANY.MAXAGENTS')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-textarea
                            filled
                            auto-grow
                            rows="1"
                            dense
                            rounded
                            v-model="company.description"
                            prepend-icon="mdi-card-text"
                            :label="$i18n.t('COMPANY.descriptionLabel')"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-autocomplete
                        :items="turnItems"
                        filled
                        dense
                        rounded
                        v-model="company.turn"
                        prepend-icon="mdi-server"
                        :label="'Turn Host'"
                      ></v-autocomplete>

                      <v-row no-gutters>
                        <v-col cols="6" class="pr-2">
                          <v-text-field
                            v-model="company.turnUser"
                            dense
                            prepend-icon="mdi-account"
                            filled
                            rounded
                            :label="'Turn user'"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-text-field
                            filled
                            dense
                            rounded
                            type="password"
                            v-model="company.turnPassword"
                            prepend-icon="mdi-lock"
                            :label="'Turn password'"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end;">
                    <v-btn text class="mr-1" @click="closeAdd">{{ $i18n.t("GENERAL.close") }}</v-btn>
                    <v-btn
                      v-if="dialogs.add && !dialogs.save"
                      depressed
                      color="green"
                      dark
                      @click.prevent="add"
                    >{{ $i18n.t("GENERAL.add") }}</v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="green"
                      dark
                      @click.prevent="save"
                    >{{ $i18n.t("GENERAL.save") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title style="justify-content: center;" class="pa-0">MAIL</v-card-title>
              <v-card-text class="pa-0">
                <v-stepper non-linear v-model="step" class="pb-4">
                  <v-stepper-header>
                    <v-stepper-step editable step="1">
                      {{ $i18n.t(
                      'MAIL.authentication')}}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="2">{{ $i18n.t('MAIL.advanced')}}</v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row no-gutters class="pt-6">
                        <v-col cols="6" class="pr-2">
                          <v-text-field
                            v-model="email.emailuser"
                            rounded
                            filled
                            dense
                            prepend-icon="mdi-account"
                            :label="$i18n.t('MAIL.userLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-text-field
                            filled
                            rounded
                            dense
                            v-model="email.emailpass"
                            prepend-icon="mdi-lock"
                            :label="$i18n.t('MAIL.passwordLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pr-2">
                          <v-text-field
                            filled
                            rounded
                            dense
                            v-model="email.from"
                            prepend-icon="mdi-email-edit"
                            :label="$i18n.t('MAIL.fromLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-text-field
                            filled
                            rounded
                            dense
                            v-model="email.fromname"
                            prepend-icon="mdi-account-edit"
                            :label="$i18n.t('MAIL.fromNameLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="email.emailservice"
                            filled
                            rounded
                            :items="serviceTypes"
                            :label="$i18n.t('MAIL.serviceLabel')"
                            prepend-icon="mdi-email-send"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="pr-2">
                          <v-text-field
                            filled
                            rounded
                            dense
                            v-model="email.emailhost"
                            v-if="email.emailservice == 'smtp'"
                            prepend-icon="mdi-server"
                            :label="$i18n.t('MAIL.hostLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-text-field
                            filled
                            dense
                            rounded
                            v-model="email.emailport"
                            v-if="email.emailservice == 'smtp'"
                            prepend-icon="mdi-serial-port"
                            :label="$i18n.t('MAIL.portLabel')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            filled
                            dense
                            rounded
                            v-model="email.emailsecure"
                            v-if="email.emailservice == 'smtp'"
                            prepend-icon="mdi-email-lock"
                            :label="$i18n.t('MAIL.secureLabel')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <v-row no-gutters class="pt-6">
                        <v-col cols="6">
                          <v-select
                            v-model="templateTypeSelected"
                            filled
                            rounded
                            :items="templateTypeList"
                            :label="$i18n.t('MAIL.templates')"
                            prepend-icon="mdi-email-send"
                          ></v-select>
                        </v-col>
                        <!-- ["cancel_meeting", "confirm_meeting", "deny_meeting", "edit_password", "new_user", "see_agenda"] -->
                        <v-col cols="6">
                          <!-- Subject -->
                          <v-text-field
                            v-if="templateTypeSelected === 'cancel_meeting'"
                            v-model="email.cancel_meeting_subject"
                            filled
                            rounded
                            dense
                            prepend-icon="mdi-text-subject"
                            :label="$i18n.t('MAIL.subjectLabel')"
                          />
                          <v-text-field
                            v-if="templateTypeSelected === 'edit_password'"
                            v-model="email.edit_password_subject"
                            filled
                            rounded
                            dense
                            prepend-icon="mdi-text-subject"
                            :label="$i18n.t('MAIL.subjectLabel')"
                          />
                          <v-text-field
                            v-if="templateTypeSelected === 'new_user'"
                            v-model="email.new_user_subject"
                            filled
                            rounded
                            dense
                            prepend-icon="mdi-text-subject"
                            :label="$i18n.t('MAIL.subjectLabel')"
                          />
                          <v-text-field
                            v-if="templateTypeSelected === 'see_agenda'"
                            v-model="email.see_agenda_subject"
                            filled
                            rounded
                            dense
                            prepend-icon="mdi-text-subject"
                            :label="$i18n.t('MAIL.subjectLabel')"
                          />
                        </v-col>
                        <v-col cols="12">
                          <!-- Summernote -->
                          <SummerNoteComponent
                            key="cancel_meeting"
                            v-if="templateTypeSelected === 'cancel_meeting'"
                            v-model="email.cancel_meeting_body"
                          ></SummerNoteComponent> 
                          <SummerNoteComponent
                            key="confirm_meeting"
                            v-if="templateTypeSelected === 'confirm_meeting'"
                            v-model="email.confirm_meeting_body"
                          ></SummerNoteComponent>
                          <SummerNoteComponent  
                            key="deny_meeting"
                            v-if="templateTypeSelected === 'deny_meeting'"
                            v-model="email.deny_meeting_body"
                          ></SummerNoteComponent>
                          <SummerNoteComponent  
                            key="edit_password"
                            v-if="templateTypeSelected === 'edit_password'"
                            v-model="email.edit_password_body"
                          ></SummerNoteComponent>
                          <SummerNoteComponent 
                            key="new_user"
                            v-if="templateTypeSelected === 'new_user'"
                            v-model="email.new_user_body"
                          ></SummerNoteComponent>
                          <SummerNoteComponent  
                            key="see_agenda"
                            v-if="templateTypeSelected === 'see_agenda'"
                            v-model="email.see_agenda_body"
                          ></SummerNoteComponent>

                          <div class="mt-3">
                            <span
                              v-if="templateTypeSelected === 'cancel_meeting'"
                            >${CUSTOMER} ${STARTDATE} ${FULLNAME} ${COMPANY}</span>
                            <span
                              v-if="templateTypeSelected === 'confirm_meeting'"
                            >${CUSTOMER} ${STARTDATE} ${FULLNAME} ${COMPANY} ${CONFIRM_URL} ${DENY_URL}</span>
                            <span
                              v-if="templateTypeSelected === 'deny_meeting'"
                            >${CUSTOMER} ${STARTDATE} ${FULLNAME} ${COMPANY}</span>
                            <span
                              v-if="templateTypeSelected === 'edit_password'"
                            >${FULLNAME} ${USER} ${PASSWORD} ${COMPANY}</span>
                            <span
                              v-if="templateTypeSelected === 'new_user'"
                            >${FULLNAME} ${USER} ${PASSWORD} ${COMPANY}</span>
                            <span
                              v-if="templateTypeSelected === 'see_agenda'"
                            >${CUSTOMER} ${STARTDATE} ${FULLNAME} ${COMPANY} ${URL}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end;">
                    <v-btn text class="mr-1" @click="closeAdd">{{ $i18n.t("GENERAL.close") }}</v-btn>
                    <v-btn
                      v-if="dialogs.add && !dialogs.save"
                      depressed
                      color="green"
                      dark
                      @click.prevent="add"
                    >{{ $i18n.t("GENERAL.add") }}</v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="green"
                      dark
                      @click.prevent="save"
                    >{{ $i18n.t("GENERAL.save") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center;"
              >{{$i18n.t("COMPANY.DISPOSITIONS")}}</v-card-title>
              <v-card-text class="pt-3">
                <v-row no-gutters class="px-12">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="10">
                            <v-text-field
                              v-model="disposition"
                              :label="$i18n.t('COMPANY.ADDNEW')"
                              color="primary"
                              flat
                              filled
                              rounded
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" style="padding-left: 40px;">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                  <v-btn
                                    @click="addDisposition"
                                    v-on="on"
                                    bottom
                                    right
                                    color="green"
                                    dark
                                    fab
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-fab-transition>
                              </template>
                              <span>{{ $i18n.t("TABLE.addNew")}}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="search"
                          @keyup.13="pSearch"
                          :label="$i18n.t('COMPANY.SEARCH')"
                          color="primary"
                          append-icon="mdi-magnify"
                          flat
                          filled
                          rounded
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-list class="list__overflow pr-5" rounded>
                      <v-list-item-group v-model="listSelector" color="primary">
                        <template v-for="disposition in filtered(company.dispositions)">
                          <v-list-item :key="disposition" :value="disposition" two-line>
                            <v-avatar size="50" class="ma-2">
                              <v-icon size="42">mdi-account</v-icon>
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                class="pl-3"
                                style="letter-spacing: 1px; font-size: 14px;"
                              >{{ disposition }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon @click="deleteDisposition(disposition)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end;">
                    <v-btn text class="mr-1" @click="closeAdd">{{ $i18n.t("GENERAL.close") }}</v-btn>
                    <v-btn
                      v-if="dialogs.add && !dialogs.save"
                      depressed
                      color="green"
                      dark
                      @click.prevent="add"
                    >{{ $i18n.t("GENERAL.add") }}</v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="green"
                      dark
                      @click.prevent="save"
                    >{{ $i18n.t("GENERAL.save") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center;"
              >{{ $i18n.t("COMPANY.TAGS") }}</v-card-title>
              <v-card-text class="pt-3">
                <v-row no-gutters class="px-12">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="10">
                            <v-text-field
                              v-model="tag"
                              :label="$i18n.t('COMPANY.ADDNEW')"
                              color="primary"
                              flat
                              filled
                              rounded
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" style="padding-left: 40px;">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                  <v-btn
                                    @click="addDispositionTag"
                                    v-on="on"
                                    bottom
                                    right
                                    color="green"
                                    dark
                                    fab
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-fab-transition>
                              </template>
                              <span>{{ $i18n.t("TABLE.addNew")}}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="searchTag"
                          @keyup.13="pSearch"
                          :label="$i18n.t('COMPANY.SEARCH')"
                          color="primary"
                          append-icon="mdi-magnify"
                          flat
                          filled
                          rounded
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-list class="list__overflow pr-5" rounded>
                      <v-list-item-group v-model="listSelectorTag" color="primary">
                        <template v-for="tag in filteredTag(company.tags)">
                          <v-list-item :key="tag" :value="tag" two-line>
                            <v-avatar size="50" class="ma-2">
                              <v-icon size="42">mdi-tag-multiple</v-icon>
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                class="pl-3"
                                style="letter-spacing: 1px; font-size: 14px;"
                              >{{ tag }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon @click="deleteTag(tag)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end;">
                    <v-btn text class="mr-1" @click="closeAdd">{{ $i18n.t("GENERAL.close") }}</v-btn>
                    <v-btn
                      v-if="dialogs.add && !dialogs.save"
                      depressed
                      color="green"
                      dark
                      @click.prevent="add"
                    >{{ $i18n.t("GENERAL.add") }}</v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="green"
                      dark
                      @click.prevent="save"
                    >{{ $i18n.t("GENERAL.save") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center;"
              >{{$i18n.t('COMPANY.STORAGE')}}</v-card-title>
              <v-card-text class="pt-3">
                <v-row no-gutters class="px-12">
                  <v-col cols="12">
                    <v-progress-linear
                      v-model="totalGB"
                      height="25"
                    >
                      <strong>{{ Math.ceil(totalGB) }}%</strong>
                    </v-progress-linear>

                    <v-card-title
                class="pa-0 pt-5 pb-4 pl-5"
                style="justify-content: center;"
              >{{$i18n.t('COMPANY.STORAGEPLAN')}}</v-card-title>


              <v-col cols="12" class="pr-2">
                          <v-autocomplete
                            v-model="company.maxStorage"
                            :items="itemsStorage"
                            dense
                            prepend-icon="mdi-web"
                            filled
                            rounded
                            :label="$i18n.t('COMPANY.STORAGEPLAN')"
                          ></v-autocomplete>
                        </v-col>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end;">
                    <v-btn text class="mr-1" @click="closeAdd">{{ $i18n.t("GENERAL.close") }}</v-btn>
                    <v-btn
                      v-if="dialogs.add && !dialogs.save"
                      depressed
                      color="green"
                      dark
                      @click.prevent="add"
                    >{{ $i18n.t("GENERAL.add") }}</v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="green"
                      dark
                      @click.prevent="save"
                    >{{ $i18n.t("GENERAL.save") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
           <v-tab-item>
            <TextProviders :company='company' @closeDialog="closeAdd()" ></TextProviders>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>

    <Delete v-model="this.dialogs.delete" @remove="remove" @close="closeDelete"></Delete>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";
import { mapState } from "vuex";
import Delete from "@/components/Delete.vue";
import EmailConfig from "../models/email_config.js";

import SummerNoteComponent from "./../components/SummerNoteComponent.vue";
import Company from './../models/company'
import TextProviders from './../components/TextProviders.vue'


class smsConfig {
  constructor() {
    this.providers=[];
    this.templates = {
      cancel_meeting:"",
      confirm_meeting:"",
      deny_meeting:"",
      edit_password:"",
      new_user:"",
      see_agenda:""
    }
  }
   getObject(){
      return {
        templates:{
          cancel_meeting:this.templates.cancel_meeting,
          confirm_meeting:this.templates.confirm_meeting,
          deny_meeting:this.templates.deny_meeting,
          edit_password:this.templates.edit_password,
          new_user:this.templates.new_user,
          see_agenda:this.templates.see_agenda
        },
        providers:this.providers
      }
    }
}



export default {

  components: {
    TextProviders,
    Table,
    Delete,
    SummerNoteComponent,
  },
  computed: {},
  data(){
    return {
      totalGB :0,
      turnItems:[
        { 
          text: "North America",
          value: "turn-na.ucontactcloud.com:443"
        },
        {
          text: "South America",
          value: "turn-sa.ucontactcloud.com:443"
        },
        {
          text: "Europe",
          value: "turn-eu.ucontactcloud.com:443"
        },
        {
          text: "Asia",
          value: "turn-as.ucontactcloud.com:443"
        }
      ],
      companies: [],
      listSelector:1,
      listSelectorTag:1,
      tag:null,
      searchTag:"",
      search: "",
      itemsStorage:[
        {
          text:"100GB",
          value:100
        },
        {
          text:"500GB",
          value:500
        },
        {
          text:"3TB",
          value:3000
        }
      ],
      itemsCountry:[ 
        {text: 'Afghanistan', code: 'AF'}, 
        {text: 'Åland Islands', code: 'AX'}, 
        {text: 'Albania', code: 'AL'}, 
        {text: 'Algeria', code: 'DZ'}, 
        {text: 'American Samoa', code: 'AS'}, 
        {text: 'AndorrA', code: 'AD'}, 
        {text: 'Angola', code: 'AO'}, 
        {text: 'Anguilla', code: 'AI'}, 
        {text: 'Antarctica', code: 'AQ'}, 
        {text: 'Antigua and Barbuda', code: 'AG'}, 
        {text: 'Argentina', code: 'AR'}, 
        {text: 'Armenia', code: 'AM'}, 
        {text: 'Aruba', code: 'AW'}, 
        {text: 'Australia', code: 'AU'}, 
        {text: 'Austria', code: 'AT'}, 
        {text: 'Azerbaijan', code: 'AZ'}, 
        {text: 'Bahamas', code: 'BS'}, 
        {text: 'Bahrain', code: 'BH'}, 
        {text: 'Bangladesh', code: 'BD'}, 
        {text: 'Barbados', code: 'BB'}, 
        {text: 'Belarus', code: 'BY'}, 
        {text: 'Belgium', code: 'BE'}, 
        {text: 'Belize', code: 'BZ'}, 
        {text: 'Benin', code: 'BJ'}, 
        {text: 'Bermuda', code: 'BM'}, 
        {text: 'Bhutan', code: 'BT'}, 
        {text: 'Bolivia', code: 'BO'}, 
        {text: 'Bosnia and Herzegovina', code: 'BA'}, 
        {text: 'Botswana', code: 'BW'}, 
        {text: 'Bouvet Island', code: 'BV'}, 
        {text: 'Brazil', code: 'BR'}, 
        {text: 'British Indian Ocean Territory', code: 'IO'}, 
        {text: 'Brunei Darussalam', code: 'BN'}, 
        {text: 'Bulgaria', code: 'BG'}, 
        {text: 'Burkina Faso', code: 'BF'}, 
        {text: 'Burundi', code: 'BI'}, 
        {text: 'Cambodia', code: 'KH'}, 
        {text: 'Cameroon', code: 'CM'}, 
        {text: 'Canada', code: 'CA'}, 
        {text: 'Cape Verde', code: 'CV'}, 
        {text: 'Cayman Islands', code: 'KY'}, 
        {text: 'Central African Republic', code: 'CF'}, 
        {text: 'Chad', code: 'TD'}, 
        {text: 'Chile', code: 'CL'}, 
        {text: 'China', code: 'CN'}, 
        {text: 'Christmas Island', code: 'CX'}, 
        {text: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {text: 'Colombia', code: 'CO'}, 
        {text: 'Comoros', code: 'KM'}, 
        {text: 'Congo', code: 'CG'}, 
        {text: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {text: 'Cook Islands', code: 'CK'}, 
        {text: 'Costa Rica', code: 'CR'}, 
        {text: 'Cote D\'Ivoire', code: 'CI'}, 
        {text: 'Croatia', code: 'HR'}, 
        {text: 'Cuba', code: 'CU'}, 
        {text: 'Cyprus', code: 'CY'}, 
        {text: 'Czech Republic', code: 'CZ'}, 
        {text: 'Denmark', code: 'DK'}, 
        {text: 'Djibouti', code: 'DJ'}, 
        {text: 'Dominica', code: 'DM'}, 
        {text: 'Dominican Republic', code: 'DO'}, 
        {text: 'Ecuador', code: 'EC'}, 
        {text: 'Egypt', code: 'EG'}, 
        {text: 'El Salvador', code: 'SV'}, 
        {text: 'Equatorial Guinea', code: 'GQ'}, 
        {text: 'Eritrea', code: 'ER'}, 
        {text: 'Estonia', code: 'EE'}, 
        {text: 'Ethiopia', code: 'ET'}, 
        {text: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {text: 'Faroe Islands', code: 'FO'}, 
        {text: 'Fiji', code: 'FJ'}, 
        {text: 'Finland', code: 'FI'}, 
        {text: 'France', code: 'FR'}, 
        {text: 'French Guiana', code: 'GF'}, 
        {text: 'French Polynesia', code: 'PF'}, 
        {text: 'French Southern Territories', code: 'TF'}, 
        {text: 'Gabon', code: 'GA'}, 
        {text: 'Gambia', code: 'GM'}, 
        {text: 'Georgia', code: 'GE'}, 
        {text: 'Germany', code: 'DE'}, 
        {text: 'Ghana', code: 'GH'}, 
        {text: 'Gibraltar', code: 'GI'}, 
        {text: 'Greece', code: 'GR'}, 
        {text: 'Greenland', code: 'GL'}, 
        {text: 'Grenada', code: 'GD'}, 
        {text: 'Guadeloupe', code: 'GP'}, 
        {text: 'Guam', code: 'GU'}, 
        {text: 'Guatemala', code: 'GT'}, 
        {text: 'Guernsey', code: 'GG'}, 
        {text: 'Guinea', code: 'GN'}, 
        {text: 'Guinea-Bissau', code: 'GW'}, 
        {text: 'Guyana', code: 'GY'}, 
        {text: 'Haiti', code: 'HT'}, 
        {text: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {text: 'Holy See (Vatican City State)', code: 'VA'}, 
        {text: 'Honduras', code: 'HN'}, 
        {text: 'Hong Kong', code: 'HK'}, 
        {text: 'Hungary', code: 'HU'}, 
        {text: 'Iceland', code: 'IS'}, 
        {text: 'India', code: 'IN'}, 
        {text: 'Indonesia', code: 'ID'}, 
        {text: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {text: 'Iraq', code: 'IQ'}, 
        {text: 'Ireland', code: 'IE'}, 
        {text: 'Isle of Man', code: 'IM'}, 
        {text: 'Israel', code: 'IL'}, 
        {text: 'Italy', code: 'IT'}, 
        {text: 'Jamaica', code: 'JM'}, 
        {text: 'Japan', code: 'JP'}, 
        {text: 'Jersey', code: 'JE'}, 
        {text: 'Jordan', code: 'JO'}, 
        {text: 'Kazakhstan', code: 'KZ'}, 
        {text: 'Kenya', code: 'KE'}, 
        {text: 'Kiribati', code: 'KI'}, 
        {text: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {text: 'Korea, Republic of', code: 'KR'}, 
        {text: 'Kuwait', code: 'KW'}, 
        {text: 'Kyrgyzstan', code: 'KG'}, 
        {text: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {text: 'Latvia', code: 'LV'}, 
        {text: 'Lebanon', code: 'LB'}, 
        {text: 'Lesotho', code: 'LS'}, 
        {text: 'Liberia', code: 'LR'}, 
        {text: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {text: 'Liechtenstein', code: 'LI'}, 
        {text: 'Lithuania', code: 'LT'}, 
        {text: 'Luxembourg', code: 'LU'}, 
        {text: 'Macao', code: 'MO'}, 
        {text: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {text: 'Madagascar', code: 'MG'}, 
        {text: 'Malawi', code: 'MW'}, 
        {text: 'Malaysia', code: 'MY'}, 
        {text: 'Maldives', code: 'MV'}, 
        {text: 'Mali', code: 'ML'}, 
        {text: 'Malta', code: 'MT'}, 
        {text: 'Marshall Islands', code: 'MH'}, 
        {text: 'Martinique', code: 'MQ'}, 
        {text: 'Mauritania', code: 'MR'}, 
        {text: 'Mauritius', code: 'MU'}, 
        {text: 'Mayotte', code: 'YT'}, 
        {text: 'Mexico', code: 'MX'}, 
        {text: 'Micronesia, Federated States of', code: 'FM'}, 
        {text: 'Moldova, Republic of', code: 'MD'}, 
        {text: 'Monaco', code: 'MC'}, 
        {text: 'Mongolia', code: 'MN'}, 
        {text: 'Montserrat', code: 'MS'}, 
        {text: 'Morocco', code: 'MA'}, 
        {text: 'Mozambique', code: 'MZ'}, 
        {text: 'Myanmar', code: 'MM'}, 
        {text: 'Namibia', code: 'NA'}, 
        {text: 'Nauru', code: 'NR'}, 
        {text: 'Nepal', code: 'NP'}, 
        {text: 'Netherlands', code: 'NL'}, 
        {text: 'Netherlands Antilles', code: 'AN'}, 
        {text: 'New Caledonia', code: 'NC'}, 
        {text: 'New Zealand', code: 'NZ'}, 
        {text: 'Nicaragua', code: 'NI'}, 
        {text: 'Niger', code: 'NE'}, 
        {text: 'Nigeria', code: 'NG'}, 
        {text: 'Niue', code: 'NU'}, 
        {text: 'Norfolk Island', code: 'NF'}, 
        {text: 'Northern Mariana Islands', code: 'MP'}, 
        {text: 'Norway', code: 'NO'}, 
        {text: 'Oman', code: 'OM'}, 
        {text: 'Pakistan', code: 'PK'}, 
        {text: 'Palau', code: 'PW'}, 
        {text: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {text: 'Panama', code: 'PA'}, 
        {text: 'Papua New Guinea', code: 'PG'}, 
        {text: 'Paraguay', code: 'PY'}, 
        {text: 'Peru', code: 'PE'}, 
        {text: 'Philippines', code: 'PH'}, 
        {text: 'Pitcairn', code: 'PN'}, 
        {text: 'Poland', code: 'PL'}, 
        {text: 'Portugal', code: 'PT'}, 
        {text: 'Puerto Rico', code: 'PR'}, 
        {text: 'Qatar', code: 'QA'}, 
        {text: 'Reunion', code: 'RE'}, 
        {text: 'Romania', code: 'RO'}, 
        {text: 'Russian Federation', code: 'RU'}, 
        {text: 'RWANDA', code: 'RW'}, 
        {text: 'Saint Helena', code: 'SH'}, 
        {text: 'Saint Kitts and Nevis', code: 'KN'}, 
        {text: 'Saint Lucia', code: 'LC'}, 
        {text: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {text: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {text: 'Samoa', code: 'WS'}, 
        {text: 'San Marino', code: 'SM'}, 
        {text: 'Sao Tome and Principe', code: 'ST'}, 
        {text: 'Saudi Arabia', code: 'SA'}, 
        {text: 'Senegal', code: 'SN'}, 
        {text: 'Serbia and Montenegro', code: 'CS'}, 
        {text: 'Seychelles', code: 'SC'}, 
        {text: 'Sierra Leone', code: 'SL'}, 
        {text: 'Singapore', code: 'SG'}, 
        {text: 'Slovakia', code: 'SK'}, 
        {text: 'Slovenia', code: 'SI'}, 
        {text: 'Solomon Islands', code: 'SB'}, 
        {text: 'Somalia', code: 'SO'}, 
        {text: 'South Africa', code: 'ZA'}, 
        {text: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {text: 'Spain', code: 'ES'}, 
        {text: 'Sri Lanka', code: 'LK'}, 
        {text: 'Sudan', code: 'SD'}, 
        {text: 'Suritext', code: 'SR'}, 
        {text: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {text: 'Swaziland', code: 'SZ'}, 
        {text: 'Sweden', code: 'SE'}, 
        {text: 'Switzerland', code: 'CH'}, 
        {text: 'Syrian Arab Republic', code: 'SY'}, 
        {text: 'Taiwan, Province of China', code: 'TW'}, 
        {text: 'Tajikistan', code: 'TJ'}, 
        {text: 'Tanzania, United Republic of', code: 'TZ'}, 
        {text: 'Thailand', code: 'TH'}, 
        {text: 'Timor-Leste', code: 'TL'}, 
        {text: 'Togo', code: 'TG'}, 
        {text: 'Tokelau', code: 'TK'}, 
        {text: 'Tonga', code: 'TO'}, 
        {text: 'Trinidad and Tobago', code: 'TT'}, 
        {text: 'Tunisia', code: 'TN'}, 
        {text: 'Turkey', code: 'TR'}, 
        {text: 'Turkmenistan', code: 'TM'}, 
        {text: 'Turks and Caicos Islands', code: 'TC'}, 
        {text: 'Tuvalu', code: 'TV'}, 
        {text: 'Uganda', code: 'UG'}, 
        {text: 'Ukraine', code: 'UA'}, 
        {text: 'United Arab Emirates', code: 'AE'}, 
        {text: 'United Kingdom', code: 'GB'}, 
        {text: 'United States', code: 'US'}, 
        {text: 'United States Minor Outlying Islands', code: 'UM'}, 
        {text: 'Uruguay', code: 'UY'}, 
        {text: 'Uzbekistan', code: 'UZ'}, 
        {text: 'Vanuatu', code: 'VU'}, 
        {text: 'Venezuela', code: 'VE'}, 
        {text: 'Viet Nam', code: 'VN'}, 
        {text: 'Virgin Islands, British', code: 'VG'}, 
        {text: 'Virgin Islands, U.S.', code: 'VI'}, 
        {text: 'Wallis and Futuna', code: 'WF'}, 
        {text: 'Western Sahara', code: 'EH'}, 
        {text: 'Yemen', code: 'YE'}, 
        {text: 'Zambia', code: 'ZM'}, 
        {text: 'Zimbabwe', code: 'ZW'} 
      ],
      headers: ["name", "country", "description", "shard"],
      loading: false,
      buttonLoader: false,
      serviceTypes: ["gmail", "smtp"],
      templateTypeList: ["cancel_meeting", "confirm_meeting", "deny_meeting", "edit_password", "new_user", "see_agenda"],
      templateTypeSelected: "",
      step: 1,
      disposition: "",
      company: {},
      email: {},
      companySettings: [
        {
          name: "General",
          icon: "mdi-apps",
        },
        {
          name: "email",
          icon: "mdi-email",
        },
        { name: this.$i18n.t("COMPANY.DISPOSITIONS"), icon: "mdi-border-color" },
        {
          name:  this.$i18n.t("COMPANY.TAGS"),
          icon: "mdi-tag-multiple"
        },
        {
          name:  this.$i18n.t('COMPANY.STORAGE'),
          icon: "mdi-database"
        },
        { name: this.$i18n.t('COMPANY.APITEXT'), icon: "mdi-whatsapp" }
        
      ],
      rules: {
        required: (value) => !!value || "Required",
        minAgents : v => v > 0 || 'Min 1',
        notExist: v => this.validateExist(v) || "Already exist"
      },
      dialogs: {
        delete: false,
        add: false,
        save: false,
      },
    }
  },
  

  created() {
    this.getCompanies();
  },

  methods:{
    validateExist(company){

      if(this.dialogs.save == true && !this.dialogs.add == false){
        return true
      }
      
      let exist =false;
      let items = this.companies.forEach(element => {
        if(company==element.id){
          exist = true;
        }
      });

      if(exist){
        return false
      }else{
        return true
      }
    },  
    removeTag (item) {
        this.company.tags.splice(this.chips.indexOf(item), 1)
        this.company.tags = [...this.company.tags]
    },
    addDisposition() {
      if (this.disposition) {
        this.company.dispositions.push(this.disposition);
      }
      this.disposition = "";
    },

    deleteDisposition(dispositon) {
      this.company.dispositions.splice(
        this.company.dispositions.indexOf(dispositon),
        1
      );
    },

    addDispositionTag() {
      if (this.tag) {
        this.company.tags.push(this.tag);
      }
      this.tag = "";
    },

    deleteTag(tag) {
      this.company.tags.splice(
        this.company.tags.indexOf(tag),
        1
      );
    },

    async getCompanies() {
      try {
        this.companies = await Company.getAll();
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching companies",
        });
      }
    },

    async remove() {
      try {
        await Company.remove(this.company);
        await this.getCompanies();
        this.closeDelete();
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "DELETE",
          text: "Company removed successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "DELETE",
          text: "Error in remove company",
        });
        console.log(error);
      }
    },

    async add() {
      if (this.$refs["add-form"].validate()) {
        try {
          const company = new Company(this.company);

          await Company.save(company);
    
          if(this.email && this.email.getObject && typeof this.email.getObject == 'function'){
            await Company.saveConfiguration("email", company, this.email);
            let newSMSConfig = new smsConfig();
            await Company.saveConfiguration("sms",company,newSMSConfig.getObject())
          }
          this.getCompanies();
          this.closeAdd();
          this.company={}
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: "Company added successfully",
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "Error in add company",
          });
        }
      }
    },

    async save() {
      if (this.$refs["add-form"].validate()) {
        try {
          await Company.save(this.company);
          await Company.saveConfiguration("email", this.company, this.email);
          this.getCompanies();

          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "SAVE",
            text: "Company updated successfully",
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "SAVE",
            text: "Error in save company",
          });
        }
      }
    },

    closeDelete() {
      this.dialogs.delete = !this.dialogs.delete;
    },

    closeAdd() {
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.$refs["add-form"].reset();
    },

    showDelete(company) {
      this.dialogs.delete = !this.dialogs.delete;
      this.company = company;
    },
    showAdd() {
      this.dialogs.add = !this.dialogs.add;
    },
    showSave(company) {





      this.company = new Company(company);

      let totalGB  = ((this.company.usedStorage / 1024) / 1024) / 1024;
      this.totalGB = totalGB


      this.dialogs.add = true;
      this.dialogs.save = true;
      this.getConfigurations(company);
    },
    async getConfigurations(company) {
      let configs = await Company.getAllConfigurations(company);
      this.email = configs["email"];
    },

    filtered(dispositions) {
      return (dispositions || []).filter((disposition) => {
        return disposition.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
     filteredTag(tag) {
      return (tag || []).filter((tag) => {
        return tag.toLowerCase().indexOf(this.searchTag.toLowerCase()) > -1;
      });
    }
  },

  
}
</script>

<style lang="scss">
.list__overflow {
  max-height: 300px;
  overflow-y: auto;
}

.save-card {
  border-top: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
#campaign > .v-tabs-bar {
  background-image: radial-gradient(
    circle 386px at 0% 64.1%,
    var(--v-primary-base) 0%,
    var(--v-primary-darken1) 100.7%
  ) !important;
  border-top-right-radius: 0px !important;
}

.v-stepper__label {
  opacity: 0.8 !important;
  font-size: 14px !important;
}

.v-stepper {
  border: none !important;
  box-shadow: none !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-stepper__content {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>
