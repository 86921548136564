import firebase from '../firebase/index'
import EmailConfig from './email_config';
import store from './../store/index.js'

export default class Company {


  constructor(obj) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.country = obj.country || '';
    this.description = obj.description || '';
    this.shard = obj.shard || '';
    this.turn = obj.turn || 'turn-na.ucontactcloud.com:443';
    this.turnUser = obj.turnUser || 'uc0nt4ct';
    this.turnPassword = obj.turnPassword || 'IntegraV1d30';
    this.dispositions = obj.dispositions || [];
    this.maxAgents = parseInt(obj.maxAgents) || 10;
    this.actualAgents = parseInt(obj.actualAgents) || 0;
    this.tags = obj.tags || [];
    this.maxStorage = obj.maxStorage || 100;
    this.usedStorage = obj.usedStorage || 0;
  }

  static async getAll() {
    let type = store.state.Auth.token.claims.type;
    if (type == 'superuser') {
      const query = await firebase.firestore().collection("/companies").get();
      return query.docs.map(company => {
        return new Company({
          ...company.data(),
          id: company.id
        })
      });

    } else if (type == 'admin') {
      let company = store.state.Auth.token.claims.user_id.split("@")[1];
      const query = await firebase.firestore().collection("companies").doc(company).get();

      return [
        new Company({
          ...query.data(),
          id: company
        })
      ]
    }
  }

  static async getById(id) {
    const company = await firebase.firestore().collection("/companies").doc(id).get();
    if (company.exists) return new Company(company.data());
    else return null;
  }

  static async getAllConfigurations(company) {
    const query = await firebase.firestore().collection(`/companies/${company.id}/configurations`).get();
    const configs = {};
    query.docs.forEach(config => {
      if (config.id === 'email') configs[config.id] = new EmailConfig(config.data());
      else configs[config.id] = config.data();
    });
    configs['email'] = configs['email'] || new EmailConfig({});
    return configs;
  }

  static async remove(company) {
    const result = await firebase.firestore().collection(`/companies`).doc(company.id).delete();
    return result;
  }

  clone() {
    return new Company({
      id: this.id,
      name: this.name,
      country: this.country,
      description: this.description,
      shard: this.shard,
      turn: this.turn,
      turnUser: this.turnUser,
      turnPassword: this.turnPassword,
      dispositions: [...this.dispositions],
      maxAgents: this.maxAgents,
      actualAgents: this.actualAgents,
      tags: this.tags,
      usedStorage: this.usedStorage,
      maxStorage: this.maxStorage
    });
  }

  static async update(company) {
    const result = await firebase.firestore().collection(`/companies`).doc(company.id).set(company.getObject());
    return result;
  }
  static async save(company) {

    try {
      let idComp = company.id.toString()
      let companySAVE = company.getObject()
      companySAVE.id = idComp;
      let result = await firebase.functions().httpsCallable("ucvideo/addCompany")(companySAVE);
      if (!result.data.company) return null;
      return new Company(result.data.company);
    } catch (err) {
      console.log(err)
    }



  }

  getObject() {
    return {
      name: this.name,
      country: this.country,
      description: this.description,
      shard: this.shard,
      turn: this.turn,
      turnUser: this.turnUser,
      turnPassword: this.turnPassword,
      dispositions: this.dispositions,
      maxAgents: this.maxAgents,
      actualAgents: this.actualAgents,
      tags: this.tags,
      usedStorage: this.usedStorage,
      maxStorage: this.maxStorage
    }
  }

  static validate(company) {
    return !!company.id;
  }

  static validateConfiguration(type, config) {
    if (type === 'email') return !!config.email_user;
    if (type === 'sms') return !!config.sms_number;
  }

  static async saveConfiguration(type, company, config) {
    if (type == 'email') {
      const query = await firebase.firestore().collection(`/companies/${company.id}/configurations`).doc(type).set(config.getObject());
      return query;
    }

    else if (type == "sms") {
      const query = await firebase.firestore().collection(`/companies/${company.id}/configurations`).doc(type).set({
        providers: config.providers,
        templates: config.templates
      });
      return query;
    }
  }

  static async updateCompanyStorage(company, bytes, name) {
    let obj = {
      company: company,
      bytes: bytes,
      name: name
    }
    let result = await firebase.functions().httpsCallable("ucvideo/updateCompanyStorage")(obj);
    return result;
  }
}