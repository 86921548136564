import firebase from '../firebase/index'
import store from '../store/index'
import { relativeTimeThreshold } from 'moment';
export default class Meeting {

  constructor(obj) {
    this.userid = obj.userid || "";
    this.company = obj.company || "";
    this.startdate = obj.startdate || "";
    this.enddate = obj.enddate || "";
    this.customer_name = obj.customer_name || "";
    this.customer_email = obj.customer_email || "";
    this.customer_phone = obj.customer_phone || "";
    this.agent_fullname = obj.agent_fullname || "";
    this.description = obj.description || "";
    this.idmeeting = obj.idmeeting;
    this.timezone = obj.timezone || "";
    this.url = obj.url;
    this.status = obj.status || "pending";
    this.disposition = obj.disposition || "";
    this.rating = obj.rating || "";
    this.time = obj.time || {};
    this.active = obj.active || false;
    this.turn = obj.turn || ""
    this.recordings = obj.recordings || []
    this.latitude = obj.latitute || ''
    this.longitude = obj.longitude || '' 
    this.tags = obj.tags || {}
  }

  getObject() {
    return {
      userid: this.userid,
      company: this.company,
      startdate: this.startdate,
      enddate: this.enddate,
      customer_name: this.customer_name,
      customer_email: this.customer_email,
      customer_phone: this.customer_phone,
      description: this.description,
      idmeeting: this.idmeeting,
      timezone: this.timezone,
      url: this.url,
      agent_fullname: this.agent_fullname,
      status: this.status,
      disposition: this.disposition,
      rating: this.rating,
      time: this.time,
      active: this.active,
      recordings: this.recordings,
      latitude: this.latitude,
      longitude: this.longitude,
      tags: this.tags
    }
  }

  static async addMeeting(meeting) {
    let result = await firebase.functions().httpsCallable("ucvideo/addMeeting")(meeting.getObject());
    return new Meeting(result.data);
  }

  static async updateMeetingRecording(meeting) {
    try {
      const result = await firebase.firestore()
        .collection(`/companies`)
        .doc(meeting.company)
        .collection('/users')
        .doc(meeting.userid)
        .collection(meeting.startdate.substring(0, 8))
        .doc(meeting.idmeeting)
        .update({ recordings: meeting.recordings });
      return result;
    } catch (err) {
      console.log(err);
    }
  }

  static async cancelMeeting(meeting) {
    let result = await firebase.functions().httpsCallable("ucvideo/cancelMeeting")(meeting.getObject());
    return result;
  }



  static validate(meeting) {
    return meeting.userid && meeting.company && meeting.startdate && meeting.enddate && meeting.customer_name;
  }

  static async getMeetingsInADay(userid, company, day) {
    const query = await firebase.firestore().collection(`/companies/${company}/users/${userid}/${day}`).get();
    return query.docs.map(meeting => {
      return new Meeting({
        ...meeting.data(),
        id: meeting.id
      });
    });
  }

  static async getMeetingForClient(company, user, date, id) {
    let result = await firebase.functions().httpsCallable("ucvideo/getMeeting")({ idmeeting: id, company, user, date });
    if (!result.data.meeting) return null;
    store.commit('SET_SHARD', result.data.shard);
    return new Meeting(result.data.meeting);
  }

  static getMeetingReference(meeting) {
    return firebase.firestore()
      .collection("companies")
      .doc(meeting.company)
      .collection("users")
      .doc(meeting.userid)
      .collection(meeting.startdate.substring(0, 8))
      .doc(meeting.idmeeting);
  }

  static async updateMeeting(meeting, obj) {
    try {
      await this.getMeetingReference(meeting).update(obj);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async updateRating(meeting) {
    try {
      let result = await firebase.functions().httpsCallable("ucvideo/updateRating")(meeting.getObject());
      if (!result.data.meeting) return null;
      return new Meeting(result.data.meeting);
    }
    catch (err) {
      console.log(err)
      throw err;
    }

  }

  static async updateMeetingGeoLocation(meeting, location) {
    try {
      meeting.latitude = location.latitude;
      meeting.longitude = location.longitude;
      let result = await firebase.functions().httpsCallable("ucvideo/updateMeetingGeoLocation")(meeting.getObject());
      if (!result.data.meeting) return null;
      return new Meeting(result.data.meeting);
    }
    catch (err) {
      console.log(err)
      throw err;
    }
  }


  static async getMeetingMessages(meeting) {
    try {
      return firebase.firestore()
        .collection(`/companies`)
        .doc(meeting.company)
        .collection('/users')
        .doc(meeting.userid)
        .collection("messages")
        .doc(meeting.idmeeting)
        .get()
    }
    catch (err) {
      console.log(err)
      throw err;
    }
  }

  static async updateMeetingMessages(meeting, messages) {
    try {
      let ref = firebase.firestore()
        .collection(`/companies`)
        .doc(meeting.company)
        .collection('/users')
        .doc(meeting.userid)
        .collection("messages")
        .doc(meeting.idmeeting)
      let get = await ref.get();
      let exists = get.exists

      if (exists) {
        let data = get.data();
        if (messages.length > 0) {
          const result = await ref.update({ chat: data.chat.concat(messages) });
          return result;
        }
      } else {
        let result = await firebase.firestore()
          .collection(`/companies`)
          .doc(meeting.company)
          .collection('/users')
          .doc(meeting.userid)
          .collection("messages")
          .doc(meeting.idmeeting)
          .set({
            chat: messages
          })

        return result
      }
    }
    catch (err) {
      console.log(err)
      throw err;
    }



  }

  static async transcriptRecording(meeting) {
    let result = await firebase.functions().httpsCallable("ucvideo/speechTranscript")(meeting.getObject());
    return true;
  }



  static async updateCompanyAndUserStorage(meeting,bytes){
    let objmeeting = meeting.getObject();
    objmeeting.bytes = bytes;
    let result = await firebase.functions().httpsCallable("ucvideo/updateCompanyAndUserStorage")(objmeeting);
    return true;
  }
}