import store from './../store';

const getFirebaseDataBase = ()=>{
    let shard = '0'
    if(store.state.Auth.token) shard = store.state.Auth.token.claims.shard;
    else if(store.state.shard) shard = store.state.shard;
    
    if(shard === '0') return `https://ucvideo.firebaseio.com/`
    else return `https://shard${shard}.firebaseio.com/`
};

export{getFirebaseDataBase}