import firebase from '../firebase/index'


export default class Login {

  static async login(username, password) {
    try {
      let userLogin = firebase.functions().httpsCallable('ucvideo/userLogin');

      let result = await userLogin({
        id: username,
        password: password
      });
      if (result.data.token) await firebase.auth().signInWithCustomToken(result.data.token);
      else throw 'error';

    } catch (err) {
      throw err;
    }
  }

  static async logout() {
    try {
      firebase.auth().signOut();
    } catch (err) {
      throw err;
    }
  }

  static async forgetPassword(id, email) {
    try {
      let forgetPassword = firebase.functions().httpsCallable('ucvideo/forgetPassword');

      let result = await forgetPassword({
        id,
        email
      });
      if (result) return result;
      else throw 'error';
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  static async changePassword(user, password, newPassword) {
    try {
      let changePassword = firebase.functions().httpsCallable('ucvideo/changePassword');

      let result = await changePassword({
        user,
        password,
        newPassword
      });
      if (result && result.data.succeeded) return true;
      else return false;
    } catch (err) {
      return false;
    }
  }

}
