<template>
  <v-card flat class="px-8 py-6 card__content-size">
    <v-card-title class="pa-0 pb-4 pl-5" style="justify-content: center;"
      >Text Providers</v-card-title
    >
    <v-card-text class="pt-3">
      <v-row no-gutters>
        <v-col cols="12">
          <v-stepper non-linear v-model="step" class="pb-4">
            <v-stepper-header>
              <v-stepper-step editable step="1">
                Configuration
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step editable step="2">
                Templates
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0">
                <v-row no-gutters class="pt-6">
                  <v-expansion-panels accordion>
                    <v-expansion-panel
                      v-for="(item, i) in providersList"
                      :key="i"
                    >
                      <v-expansion-panel-header>
                        <v-col cols="6">
                          <v-text-field
                            prepend-icon="mdi-truck-delivery"
                            v-model="item.name"
                            label="Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1"> </v-col>

                        <v-col cols="3">
                          <v-checkbox
                            @click="enable(item)"
                            v-model="item.enabled"
                            :label="`Enabled`"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="2">
                          <v-chip class="ma-2" label>
                            POST
                          </v-chip>
                        </v-col>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="item.url"
                              label="URL"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-textarea
                              rows="4"
                              outlined
                              label="Body"
                              v-model="item.body"
                              :placeholder="`{\n  data1:'Patrick',\n  data2:'James'\n}`"
                            ></v-textarea>
                          </v-col>

                          <v-col cols="6">
                            <v-textarea
                              rows="4"
                              outlined
                              label="Headers"
                              v-model="item.headers"
                              :placeholder="`Content-Type:application/json\n`"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="9"> </v-col>
                          <v-col cols="3">
                            <v-btn
                              dark
                              color="red"
                              class="mt-0"
                              @click="deleteProvider(item, i)"
                              >{{ $i18n.t("GENERAL.delete") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn
                    dark
                    color="green"
                    class="mt-3"
                    @click="add_provider()"
                    >{{ $i18n.t("GENERAL.add") }}</v-btn
                  >
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <v-row no-gutters class="pt-6">
                  <v-col cols="6">
                    <v-select
                      v-model="templateTypeSelected"
                      filled
                      rounded
                      :items="templateTypeList"
                      :label="$i18n.t('MAIL.templates')"
                      prepend-icon="mdi-email-send"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-if="templates"
                      rows="4"
                      outlined
                      label="Template"
                      v-model="templates[templateTypeSelected]"
                   
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-col cols="12" style="text-align: end;">
          <v-btn text class="mr-1" @click="close_dialog">{{
            $i18n.t("GENERAL.close")
          }}</v-btn>

          <v-btn depressed color="green" dark @click="save">{{
            $i18n.t("GENERAL.save")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import TextProvider from "./../models/text_providers";

export default {
  components: {},
  data() {
    return {
      step:1,
      templates:{},
      templateTypeList: [
        "cancel_meeting",
        "confirm_meeting",
        "deny_meeting",
        "edit_password",
        "new_user",
        "see_agenda",
      ],
      templateTypeSelected: "cancel_meeting",
      selectedItem: null,
      providersList: [],
    };
  },
  props: {
    company: Object,
  },
  destroyed() {},
  mounted() {
    this.getAll();
  },
  computed: {},
  methods: {
    deleteProvider(provider, index) {
      this.providersList.splice(index, 1);
    },
    enable(provider) {
      let list = this.providersList;

      list.forEach((element) => {
        if (element.name == provider.name) {
          element.enabled = true;
        } else {
          element.enabled = false;
        }
      });
    },
    add_provider() {
      let newProvider = new TextProvider({});
      this.providersList.push(newProvider.getObject());
    },
    async getAll() {
      let smsConfigs = await TextProvider.getAllConfigurations(this.$props.company);
      this.providersList = smsConfigs.providers;
      this.templates = smsConfigs.templates
    },
    async save() {
      //let provider = new TextProvider(this.configuration);
      let provider_list = this.providersList;
      let templates = this.templates;
      let result = await TextProvider.saveConfiguration(
        "sms",
        this.$props.company,
        provider_list,
        templates
      );
      this.close_dialog();
    },
    close_dialog() {
      this.$emit('closeDialog');
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>