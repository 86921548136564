import cancel_meeting_body_default from './../assets/emails/cancel_meeting.html'
const cancel_meeting_subject_default = 'Meeting Canceled'
import confirm_meeting_body_default from './../assets/emails/confirm_meeting.html';
import deny_meeting_body_default from './../assets/emails/deny_meeting.html';

import edit_password_body_default from './../assets/emails/edit_password.html'
const edit_password_subject_default = 'Edit Password'

import new_user_body_default from './../assets/emails/new_user.html'
const new_user_subject_default = 'uCVideo User created'

import see_agenda_body_default from './../assets/emails/see_agenda.html'
const see_agenda_subject_default = 'New meeting'

export default class EmailConfig {
  constructor(obj) {
    this.emailuser = obj.emailuser || "";
    this.emailpass = obj.emailpass || "";
    this.emailservice = obj.emailservice || "";
    this.emailhost = obj.emailhost || "";
    this.emailport = obj.emailport || "";
    this.emailsecure = obj.emailsecure || "";
    this.fromname = obj.fromname || "";
    this.from = obj.from || "";

    this.cancel_meeting_body =  obj.cancel_meeting_body || cancel_meeting_body_default;
    this.cancel_meeting_subject = obj.cancel_meeting_subject || cancel_meeting_subject_default;
    this.confirm_meeting_body = obj.confirm_meeting_body || confirm_meeting_body_default;
    this.deny_meeting_body = obj.deny_meeting_body || deny_meeting_body_default;
    this.edit_password_body = obj.edit_password_body || edit_password_body_default;
    this.edit_password_subject = obj.edit_password_subject || edit_password_subject_default;
    this.new_user_body = obj.new_user_body || new_user_body_default;
    this.new_user_subject = obj.new_user_subject || new_user_subject_default;
    this.see_agenda_body = obj.see_agenda_body || see_agenda_body_default;
    this.see_agenda_subject = obj.see_agenda_subject || see_agenda_subject_default;
  }

  getObject(){
    return {
      emailuser: this.emailuser,
      emailpass: this.emailpass,
      emailservice: this.emailservice,
      emailhost: this.emailhost,
      emailport: this.emailport,
      emailsecure: this.emailsecure,
      fromname: this.fromname,
      from: this.from,
      
      cancel_meeting_body: this.cancel_meeting_body,
      cancel_meeting_subject: this.cancel_meeting_subject,
      confirm_meeting_body: this.confirm_meeting_body,
      deny_meeting_body: this.deny_meeting_body,
      edit_password_body: this.edit_password_body,
      edit_password_subject: this.edit_password_subject,
      new_user_body: this.new_user_body,
      new_user_subject: this.new_user_subject,
      see_agenda_body: this.see_agenda_body,
      see_agenda_subject: this.see_agenda_subject

    }
  }

}
