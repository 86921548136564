var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"box-shadow":"0px 0px"},attrs:{"app":"","color":_vm.$store.state.active_call || !_vm.$store.state.Auth.isLogged ? 'transparent':'primary',"dark":_vm.$store.state.active_call?false:true,"id":"nav","dense":""}},[_c('v-img',{staticClass:"shrink mr-2",class:{'logoOpacity':_vm.$store.state.active_call},staticStyle:{"cursor":"pointer"},attrs:{"alt":"Logo","contain":"","src":_vm.$store.state.active_call || !_vm.$store.state.Auth.isLogged ?require('../assets/logo-grey.svg'):require('../assets/logo.svg'),"transition":"scale-transition","width":"170"},on:{"click":function($event){return _vm.$router.replace('/')}}}),(_vm.$store.state.active_call)?_c('v-speed-dial',{staticStyle:{"position":"absolute","top":"55px","left":"38px"},attrs:{"value":_vm.fab,"direction":'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","fab":"","small":""},on:{"click":function($event){_vm.fab = !_vm.fab}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]},proxy:true}],null,false,1541297353)},[(_vm.$store.state.Auth.isLogged)?_c('ScreenShot'):_vm._e(),(_vm.$store.state.Auth.isLogged)?_c('Notifications',{attrs:{"active_rooms":_vm.$store.state.meeting_members}}):_vm._e(),(_vm.$store.state.Auth.isLogged)?_c('Schedule'):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('Settings',{attrs:{"incall":_vm.$store.state.active_call}})],1)]}}],null,false,2656620732)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("SETTING.title")))])])],1):_vm._e(),_c('v-spacer'),(!_vm.$store.state.active_call  && _vm.$store.state.Auth.isLogged)?[_c('Notifications',{attrs:{"active_rooms":_vm.$store.state.meeting_members}}),_c('Schedule'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('Settings')],1)]}}],null,false,3009309576)},[_c('span',{attrs:{"data-cy":"settingsMenutt"}},[_vm._v(_vm._s(_vm.$i18n.t("SETTING.title")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"overflow":"hidden"},attrs:{"small":"","depressed":"","color":"#d75d5d","fab":"","data-cy":"nameMenu"}},on),[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.initials))])])]}}],null,false,4266676650)},[_c('span',{attrs:{"data-cy":"nameMenutt"}},[_vm._v(_vm._s(_vm.$store.state.Auth.token.claims.name))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","data-cy":"logoutbtn"},on:{"click":function($event){return _vm.logout()}}},on),[_c('v-icon',[_vm._v("mdi-exit-run")])],1)]}}],null,false,2575370487)},[_c('span',{attrs:{"data-cy":"logoutbtntt"}},[_vm._v("Logout")])])]:_vm._e(),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$i18n.t("MEETING.areYouSure")))]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.logout(true)}}},[_vm._v(_vm._s(_vm.$i18n.t("MEETING.confirm")))]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogConfirm = false}}},[_vm._v(_vm._s(_vm.$i18n.t("GENERAL.close")))])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }