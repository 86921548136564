var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3",style:({'display':_vm.layoutMode?'inline-block':'inline-flex','width':_vm.layoutMode?'100%':'unset'})},[(_vm.layoutMode)?[_c('v-card',{staticClass:"dark",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"meeting-title"},[_vm._v(_vm._s(_vm.$i18n.t("MEETING.title")))]),_c('v-card-text',{staticClass:"pb-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"fab":"","text":"","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.updateCalendar(false)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}],null,false,1187566016)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("GENERAL.prev")))])])],1),_c('v-col',{staticClass:"pa-3 pt-0",attrs:{"cols":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$i18n.t('MEETING.ACTUALDAY'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}],null,false,4220546878),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.lang? _vm.$store.state.lang :'en'},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"fab":"","text":"","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.updateCalendar(true)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,false,3883455856)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("GENERAL.next")))])])],1)],1),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingCalendar),expression:"!loadingCalendar"}],staticStyle:{"height":"calc(100vh - 350px)"}},[_c('v-calendar',{ref:"calendar",attrs:{"now":"2020-04-07 12:00:00","type":"day","events":_vm.meetingsList,"interval-format":_vm.interval,"event-color":_vm.getEventColor,"event-overlap-mode":"stack","event-overlap-threshold":30},on:{"change":_vm.getMeetings,"click:event":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickMeeting.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingCalendar),expression:"loadingCalendar"}],staticStyle:{"height":"calc(100vh - 350px)"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-autorenew")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-4 pb-4",staticStyle:{"justify-content":"flex-end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"green","dark":"","small":"","data-cy":"addschedule"},on:{"click":function($event){return _vm.$refs.addScheduleDialog.openDialog()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,963607488)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("MEETING.new")))])])],1)],1)]:_vm._e(),(!_vm.layoutMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loadingQuick,"icon":"","data-cy":"quickmeetingMenu"},on:{"click":_vm.createNewMeeting}},on),[(!_vm.loadingQuick)?_c('v-icon',[_vm._v("mdi-account-multiple")]):_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-cached")])],1)]}}],null,false,1576838679)},[_c('span',{attrs:{"data-cy":"quickmeetingMenutt"}},[_vm._v(_vm._s(_vm.$i18n.t("MEETING.EXPRESS")))])]):_vm._e(),(!_vm.layoutMode)?_c('v-menu',{attrs:{"nudge-bottom":"60","nudge-left":"350","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","data-cy":"scheduleMenu"},on:{"click":function($event){return _vm.refreshMeetingColor()}}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1)]}}],null,true)},[_c('span',{attrs:{"data-cy":"scheduleMenutt"}},[_vm._v(_vm._s(_vm.$i18n.t("MEETING.title")))])])]}}],null,false,2129273804)},[_c('v-card',{attrs:{"width":"400px"}},[_c('v-card-title',{staticClass:"meeting-title"},[_vm._v(_vm._s(_vm.$i18n.t("MEETING.title")))]),_c('v-card-text',{staticClass:"pb-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"fab":"","text":"","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.updateCalendar(false)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}],null,false,1187566016)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("GENERAL.prev")))])])],1),_c('v-col',{staticClass:"pa-3 pt-0",attrs:{"cols":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$i18n.t('MEETING.ACTUALDAY'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}],null,false,4220546878),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.lang? _vm.$store.state.lang :'en'},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"fab":"","text":"","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.updateCalendar(true)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,false,3883455856)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("GENERAL.next")))])])],1)],1),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingCalendar),expression:"!loadingCalendar"}],staticStyle:{"height":"calc(100vh - 350px)"}},[_c('v-calendar',{ref:"calendar",attrs:{"now":"2020-04-07 12:00:00","type":"day","events":_vm.meetingsList,"interval-format":_vm.interval,"event-color":_vm.getEventColor,"event-overlap-mode":"stack","event-overlap-threshold":30},on:{"change":_vm.getMeetings,"click:event":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickMeeting.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingCalendar),expression:"loadingCalendar"}],staticStyle:{"height":"calc(100vh - 350px)"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-autorenew")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-4 pb-4",staticStyle:{"justify-content":"flex-end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"green","dark":"","small":"","data-cy":"addschedule"},on:{"click":function($event){return _vm.$refs.addScheduleDialog.openDialog()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,963607488)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("MEETING.new")))])])],1)],1)],1):_vm._e(),_c('InfoMeeting',{ref:"infoMeeting",attrs:{"meeting":_vm.selectedMeeting},on:{"reloadMeetings":_vm.reloadCalendar}}),_c('AddMeeting',{ref:"addScheduleDialog",on:{"newMeeting":_vm.add_meeting}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }