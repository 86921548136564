import firebase from "./../firebase/index";
import store from "./../store/index";
import { getFirebaseDataBase } from "./../firebase/utils";

export default class RoomNotification {
  constructor(company, userid) {
    this.db = getFirebaseDataBase();
    this.connections = {};
    this.company = company;
    this.userid = userid;

    this.messages = firebase
      .database(this.db)
      .ref(`${company}/${userid}/notifications/`);

    this.messages.on("value", (data) => {
      this.checkConnection(data.val());
    });
  }

  checkConnection(data) {
    store.commit("SET_MEETING_MEMBERS", data);
  }
}
