import WebRTC from "./WebRTC";
import firebase from "./../firebase/index";
import store from "./../store/index";
import { getFirebaseDataBase } from "./../firebase/utils";
import CloseCaptions from "./CloseCaptions";

export default class Signalling {
  constructor(
    meeting,
    localVideo,
    remoteVideo,
    communications,
    clientID,
    isAgent
  ) {
    this.localVideo = localVideo;
    this.remoteVideo = remoteVideo;
    this.store = store;
    this.isAgent = isAgent;
    this.meeting = meeting;
    this.userid = meeting.userid;
    this.company = meeting.company;
    this.idmeeting = meeting.idmeeting;
    this.clientID = clientID;
    this.db = getFirebaseDataBase();
    this.communications = communications;

    if (!isAgent) {
      this.initWRTC();
    }

    this.subscribeSignalling();
    this.subscribePresence();
    this.subscribeChat();
  }

  initWRTC(stream) {
    this.wrtc = new WebRTC(this);
    if (stream) {
      this.wrtc.blurStream = stream
    } else {
      this.wrtc.blurStream = null;
    }
  }

  toggleCC(bool) {
    if (this.checkSafari()) { } else {
      if (this.cc) {
        if (bool) {
          this.cc.start()
        } else {
          this.cc.stop()
        }
      } else {
        let lang = store.state.Auth.token.claims.lang;
        if (!lang) {
          lang = navigator.language.substring(0, 5)
        }
        this.cc = new CloseCaptions(this.communications, this.wrtc, lang);
        this.cc.start();
      }
    }
  }

  //handles all signalling
  subscribeSignalling() {
    this.messages = firebase
      .database(this.db)
      .ref(
        this.company + "/" + this.userid + "/" + this.idmeeting + "/signalling"
      );

    //Signalling Events
    this.messages.on("child_added", (data) => {
      const message = data.toJSON();
      //join call
      if (message.join) {
        this.communications.isInCall = true;
        this.wrtc.StartCall();
        this.store.commit("SET_IS_IN_ACTIVE_CALL", true);
        this.sendData({ portrait: this.getOrientation() });
        if (this.isAgent && this.store.state.room_configs.subtitles) {
          if (this.checkSafari()) { }
          else {
            if (!this.cc) {
              let lang = store.state.Auth.token.claims.lang;
              if (!lang) {
                lang = navigator.language.substring(0, 5)
              }
              this.cc = new CloseCaptions(this.communications, this.wrtc, lang);
            }
            this.cc.start();
          }
        }

        if (message.onlyAudio) {
          this.communications.onlyAudio = true;
        }

        this.communications.disabledRecording = message.recordEnabled;

      }

      //end call
      if (message.end) {
        this.communications.isInCall = false;
        if (this.isAgent && this.store.state.room_configs.subtitles) {
          if (this.checkSafari()) { } else {
            this.cc.stop();
          }
        }
        this.endCall();
      }

      // Message was sent by us dont process!!!!
      if (message.clientID == this.clientID) return;

      console.debug("NEW", message.clientID, message);

      //SDP
      if (message.sdp) {
        delete message.clientID;
        if (this.wrtc) {
          this.wrtc.handleSDP(message.sdp);
        }
      }

      //ICE candidate
      if (message.candidate) {
        delete message.clientID;
        if (this.wrtc) {
          this.wrtc.handleICECandidate(message.candidate);
        }
      }

      //Camera Orientation
      if (typeof message.portrait != "undefined") {
        delete message.clientID;
        this.store.commit("SET_CAMERA_ORIENTATION", message.portrait);
        if (this.isAgent && this.communications.videoRecorder && this.communications.videoRecorder.record) {
          this.communications.videoRecorder.rotateRecording(message.portrait);
        }
      }
    });
  }

  checkSafari() {
    return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
  }

  endCall() {
    this.callEnded = true;
    this.clearSignallingDatabase();
    this.destroyWebRtc();
    this.communications.onEndCall();
  }

  //handles presence
  subscribePresence() {
    this.presence = firebase
      .database(this.db)
      .ref(
        this.company + "/" + this.userid + "/" + this.idmeeting + "/connections"
      );
    this.connection = firebase.database(this.db).ref(".info/connected");

    this.userDataBaseRef = firebase
      .database(this.db)
      .ref(
        this.company +
        "/" +
        this.userid +
        "/" +
        this.idmeeting +
        "/connections/" +
        this.clientID
      );
    this.infoMeeting = firebase
      .database(this.db)
      .ref(this.company + "/" + this.userid + "/notifications");
    //Member Presence
    this.connection.on("value", (data) => {
      if (data.val() === true) {
        //var con = this.presence.push(this.clientID);
        this.userDataBaseRef
          .onDisconnect()
          .remove()
          .then(() => {
            this.userDataBaseRef.set({ state: "online" });
          });

        //si no es agente dejo la info de la meeting por aqui
        if (!this.isAgent) {
          this.refAgent = this.infoMeeting.push(this.communications.meeting);
          this.refAgent.onDisconnect().remove();
        }
      }
    });

    //Member Change
    this.presence.on("value", (data) => {
      this.communications.onMembersChange(data.numChildren());
    });
  }

  //handles chat
  subscribeChat() {
    this.chat = firebase
      .database(this.db)
      .ref(this.company + "/" + this.userid + "/" + this.idmeeting + "/chat");

    this.chat.on("child_added", (data) => {
      const msg = data.toJSON();

      // Message was sent by us dont process!!!!
      if (msg.clientID == this.clientID) {
        if(msg.location){
          this.communications.messages.push({
            message: msg.chat,
            incoming: false,
            type: "location"
          });
  
          return;

        }else{
          this.communications.messages.push({
            message: msg.chat,
            incoming: false,
            type: "text",
          });
        }

       
        return;
      }

      if(msg.chat && msg.chat.indexOf("||&||")!=-1){
        this.communications.messages.push({
          message: msg.chat,
          incoming: true,
          type: "location"
        });

        return;
      }



      if (msg.chat) {
        this.communications.messages.push({
          message: msg.chat,
          incoming: true,
          type: "text"
        });
      }

    });
  }

  // Send signaling message
  sendSignalMessage(message) {
    message.clientID = this.clientID;
    firebase
      .database(this.db)
      .ref(
        this.company + "/" + this.userid + "/" + this.idmeeting + "/signalling/"
      )
      .push(message);
    console.debug("SENT", this.clientID, message);
  }


  sendLocationMessage(location){

    if (
      this.wrtc &&
      this.wrtc.datachannel &&
      this.wrtc.datachannel.readyState == "open"
    ) {
      this.communications.messages.push({
        message: location.chat,
        incoming: false,
        type: "location"
      });
      this.wrtc.sendData(location);
    }else{
      location.clientID = this.clientID;
      location.location=true;
      firebase
        .database(this.db)
        .ref(this.company + "/" + this.userid + "/" + this.idmeeting + "/chat/")
        .push(location);
      console.debug("SENT", this.clientID, location);
    }
  }

  // Send chat message
  sendChatMessage(message) {
    if (
      this.wrtc &&
      this.wrtc.datachannel &&
      this.wrtc.datachannel.readyState == "open"
    ) {
      this.communications.messages.push({
        message: message.chat,
        incoming: false,
        type: "text"
      });
      this.wrtc.sendData(message);
    } else {
      message.clientID = this.clientID;
      firebase
        .database(this.db)
        .ref(this.company + "/" + this.userid + "/" + this.idmeeting + "/chat/")
        .push(message);
      console.debug("SENT", this.clientID, message);
    }
  }

  //send signalling data
  sendData(data) {
    if (
      this.wrtc &&
      this.wrtc.datachannel &&
      this.wrtc.datachannel.readyState == "open"
    ) {
      this.wrtc.sendData(data);
    } else {
      this.sendSignalMessage(data);
    }
  }

  async destroyWebRtc() {
    if (this.wrtc) {
      this.wrtc.End();
      delete this.wrtc;
    }
  }

  //Camera Position
  getOrientation() {
    if (typeof window.orientation == "undefined") {
      return false;
    }
    if (window.innerHeight > window.innerWidth) {
      return true;
    } else {
      return false;
    }
  }

  clearSignallingDatabase() {
    firebase
      .database(this.db)
      .ref(
        this.company + "/" + this.userid + "/" + this.idmeeting + "/signalling/"
      )
      .remove();
  }

  clearChatDatabase() {
    firebase
      .database(this.db)
      .ref(this.company + "/" + this.userid + "/" + this.idmeeting + "/chat/")
      .remove();
  }

  //remove all messages of the current interaction
  async disconnectDataBase() {
    if (this.userDataBaseRef) this.userDataBaseRef.remove();
    if (this.refAgent) this.refAgent.remove();

    if (this.connection) this.connection.off();
    if (this.messages) this.messages.off();
    if (this.presence) this.presence.off();
    this.clearSignallingDatabase();
    this.clearChatDatabase();
    console.debug("FINISH...");
  }
}
