import * as bodyPix from "@tensorflow-models/body-pix";
import worker from 'workerize-loader?inline!./worker.js'

export default class BlurStream {
    constructor() {
        this.instance = worker();
        this.camera = null;
        this.state = {
            video: false
        }
        this.lastCalledTime = null;
        this.fps = 0;
        this.doLoad();
        this.init();

    }

    async init() {
        let stream = this;

        this.state.video.addEventListener('play', async function () {
            var $this = this; //cache
            (async function loop() {
                if (!$this.paused && !$this.ended) {
                    stream.ctx1.drawImage($this, 0, 0);
                    let img = stream.ctx1.getImageData(0, 0, 640, 480);
                    let predictionResults = await stream.instance.getPrediction(img);

                    if (predictionResults) {
                        bodyPix.drawBokehEffect(
                            stream.c2,
                            stream.c1,
                            predictionResults,
                            3,
                            3,
                            false
                        );
                    }
                    loop()
                }
            })();
        }, 0);
        await this.loadVideo(this.camera);
        await this.getVideoInputs();
        let mediaStream = this.getStream()
        return mediaStream

    }

    async loadVideo(cameraLabel) {
        try {
            this.state.video = await this.setupCamera(cameraLabel);
        } catch (e) {
            let info = document.getElementById("info");
            console.log(info)
            alert("this browser does not support video capture," +
                "or this device does not have a camera")
            throw e;
        }

        this.state.video.onloadeddata = () => {
            this.state.video.play();
        };
    }

    async setupCamera(cameraLabel) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            throw new Error(
                "Browser API navigator.mediaDevices.getUserMedia not available"
            );
        }

        const videoElement = this.state.video;

        this.stopExistingVideoCapture();

        let videoConstraints = await this.getConstraints(cameraLabel);


        const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: videoConstraints
        });
        videoElement.srcObject = stream;

        return new Promise(resolve => {
            videoElement.onloadedmetadata = () => {
                videoElement.width = videoElement.videoWidth;
                videoElement.height = videoElement.videoHeight;
                resolve(videoElement);
            };
        });
    }

    async getConstraints(cameraLabel) {
        let deviceId;
        let facingMode;

        if (cameraLabel) {
            deviceId = await this.getDeviceIdForLabel(cameraLabel);
            // on mobile, use the facing mode based on the camera.
            facingMode = this.isMobile() ? this.getFacingMode(cameraLabel) : null;
        }

        return { deviceId, facingMode };
    }
    isAndroid() {
        return /Android/i.test(navigator.userAgent);
    }

    isiOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    isMobile() {
        return this.isAndroid() || this.isiOS();
    }

    stopExistingVideoCapture() {
        if (this.state.video && this.state.video.srcObject) {
            this.state.video.srcObject.getTracks().forEach(track => {
                track.stop();
            });
            this.state.video.srcObject = null;
        }
    }
    // on mobile, facing mode is the preferred way to select a camera.
    // Here we use the camera label to determine if its the environment or
    // user facing camera
    getFacingMode(cameraLabel) {
        if (!cameraLabel) {
            return "user";
        }
        if (cameraLabel.toLowerCase().includes("back")) {
            return "environment";
        } else {
            return "user";
        }
    }
    async getDeviceIdForLabel(cameraLabel) {
        const videoInputs = await this.getVideoInputs();

        for (let i = 0; i < videoInputs.length; i++) {
            const videoInput = videoInputs[i];
            if (videoInput.label === cameraLabel) {
                return videoInput.deviceId;
            }
        }

        return null;
    }
    async getVideoInputs() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            //console.log("enumerateDevices() not supported.");
            return [];
        }

        const devices = await navigator.mediaDevices.enumerateDevices();

        const videoDevices = devices.filter(
            device => device.kind === "videoinput"
        );

        return videoDevices;
    }


    getStream() {
        return this.c2.captureStream(24);
    }
    stop() {
        this.stopExistingVideoCapture();
    }
    doLoad() {
        let video = document.createElement("video");
        video.id = "video-mask-stream";

        let canvas = document.createElement("canvas");
        canvas.id = "canvas-mask-stream";
        canvas.width = 640;
        canvas.height = 480;
        let canvas2 = document.createElement("canvas");
        canvas2.id = "canvas-mask-stream2";
        canvas2.width = 640;
        canvas2.height = 480;

        document.body.appendChild(video);
        document.body.appendChild(canvas);
        document.body.appendChild(canvas2);
        video.style.display = "none";
        canvas.style.display = "none";
        canvas2.style.display = "none";

        this.state.video = document.getElementById('video-mask-stream');
        this.c1 = document.getElementById('canvas-mask-stream');
        this.ctx1 = this.c1.getContext("2d");
        this.c2 = document.getElementById('canvas-mask-stream2');
        this.ctx2 = this.c2.getContext("2d");
    }

    handleFps() {

        if (!this.lastCalledTime) {
            this.lastCalledTime = Date.now();
            this.fps = 0;
            return;
        }
        let delta = (Date.now() - this.lastCalledTime) / 1000;
        this.lastCalledTime = Date.now();
        this.fps = 1 / delta;
        console.log(this.fps)
    }

}