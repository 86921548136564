import store from "./../store/index";

export default class CloseCaptions {
  constructor(communications, wrtc, language) {
    if ("webkitSpeechRecognition" in window) {
      this.wrtc = wrtc;
      this.final_transcript = "";
      this.communications = communications;
      this.recognizing = false;
      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = language;

      this.recognition.onstart = () => {
        console.debug("Starting ASR...");
      };

      this.recognition.onerror = (event) => {
        console.error(event.error);
      };

      this.recognition.onend = () => {
        console.debug("Stopped ASR.");
        this.recognizing = false;

        if (this.communications.isInCall && this.recognizing) {
          this.start();
        }
      };

      this.recognition.onresult = (event) => {
        var interim_transcript = "";
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.final_transcript += event.results[i][0].transcript;


            if (store.state.room_configs.subtitles && store.state.room_configs.mirrorSubtitles) {
              let elem = document.getElementById("pCaptions");
              if (event.results[i][0].transcript != "") {
                let sCaptions = document.getElementById("sCaptions");
                sCaptions.style.display = "block";
                elem.innerText = event.results[i][0].transcript;
              }
            }


            console.debug(event.results[i][0].transcript);
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
          this.wrtc.sendData({ cc: interim_transcript });
        }
      };
    }
  }

  start() {
    this.recognizing = true;
    this.recognition.start();
  }

  stop() {
    this.recognizing = false
    this.recognition.stop();
  }
}
