<template>
  <div>
    <v-dialog
      v-model="visibleDialog"
      width="525px"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
      overlay-opacity="0.5"
      persistent
    >
      <v-card>
        <v-card-title class="justify-center" style="color: #525252;">
          <v-icon class="mr-2">mdi-calendar-edit</v-icon>
          {{ $i18n.t("MEETING.new") }}
        </v-card-title>
        <v-row no-gutters v-if="$store.state.Auth.token.claims.type!='agent'">
          <v-col cols="6" class="px-12" style="padding:0px;" v-if="$store.state.Auth.isLogged">
            <span
              class="pr-4 pt-3"
              style="font-size: 13px; font-weight: 400; opacity: 0.9; display:inline-block"
            >{{$i18n.t("MEETING.ADMINSCHEDULE")}}</span>
            <v-switch
              v-model="adminScheduleForAgent"
              hide-details
              class="pt-4"
              style="margin-top: 0px !important;display:inline-block"
              color="primary"
              @change="changeAgentSchedule"
              :input-value="true"
            ></v-switch>
          </v-col>
          <v-col cols="6" class="pr-12" style="padding:0px;" v-if="$store.state.Auth.isLogged">
            <v-autocomplete
              v-if="adminScheduleForAgent"
              v-model="userForSchedule"
              hide-details
              prepend-inner-icon="mdi-account"
              :items="agentList"
              item-text="fullname"
              item-value="id"
              dense
              filled
              rounded
              :label="$i18n.t('MEETING.AGENTS')"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-form lazy-validation ref="add">
          <v-card-text class="pt-2 px-12">
            <v-row class="pb-4" no-gutters>
              <v-col cols="12" class="date-box">
                <v-menu v-model="datemenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      data-cy="meetingdate"
                      :value="formatedDate"
                      :label="$i18n.t('MEETING.date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                      @click:clear="date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$store.state.lang? $store.state.lang :'en'"
                    v-model="meetingForm.date"
                    @change="datemenu = false;formatDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="5" class="pl-2 date-box">
                <v-menu
                  ref="startmenu"
                  v-model="timestart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="timestart"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      data-cy="meetingstarts"
                      v-model="meetingForm.startdate"
                      :label="$i18n.t('MEETING.starts')"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :max="meetingForm.enddate"
                    meetingForm.enddate
                    v-if="timestart"
                    format="24hr"
                    class="datepickerindex"
                    v-model="meetingForm.startdate"
                    full-width
                    @click:minute="$refs.startmenu.save(meetingForm.startdate)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="5" class="pl-2 date-box">
                <v-menu
                  ref="endmenu"
                  v-model="timeend"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="timeend"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      data-cy="meetingends"
                      v-model="meetingForm.enddate"
                      :label="$i18n.t('MEETING.ends')"
                      :rules="[rules.required]"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :min="meetingForm.startdate"
                    v-if="meetingForm.startdate && timeend"
                    format="24hr"
                    class="datepickerindex"
                    v-model="meetingForm.enddate"
                    full-width
                    @click:minute="$refs.endmenu.save(meetingForm.enddate)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="2" class="pt-3 pl-3 date-box">
                <v-btn @click="openTimeZones">
                  <v-icon>mdi-earth</v-icon>
                </v-btn>
              </v-col>

              <v-col v-if="openGMT" cols="12" class="pl-2 date-box">
                <v-select
                  v-model="meetingForm.timezone"
                  :items="getTimeZones()"
                  :label="$i18n.t('MEETING.timezone')"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  data-cy="customerName"
                  filled
                  rounded
                  dense
                  prepend-icon="mdi-account"
                  :label="$i18n.t('MEETING.customerName')"
                  v-model="meetingForm.customer_name"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  data-cy="customerPhone"
                  filled
                  rounded
                  dense
                  type="number"
                  prepend-icon="mdi-cellphone"
                  :label="$i18n.t('MEETING.customerPhone')"
                  v-model="meetingForm.customer_phone"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  data-cy="customerEmail"
                  filled
                  rounded
                  dense
                  prepend-icon="mdi-at"
                  :label="$i18n.t('MEETING.customerEmail')"
                  v-model="meetingForm.customer_email"
                  :rules="[rules.required,rules.email]"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  data-cy="customerdescription"
                  v-model="meetingForm.description"
                  filled
                  auto-grow
                  :label="$i18n.t('MEETING.description')"
                  rows="2"
                  row-height="30"
                  shaped
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-combobox
                  v-model="meetingTags"
                  :items="tags"
                  @change="changeTags"
                  chips
                  clearable
                  label="Tags"
                  multiple
                  prepend-icon="mdi-filter-variant"
                
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="remove(item)"
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-combobox>

                <v-text-field v-for="meeting in meetingTags" :key="meeting+'tagKey'"
                  filled
                  rounded
                  dense
                  prepend-icon="mdi-at"
                  :label="meeting"
                  v-model="meetingForm.tags[meeting]"
                
                ></v-text-field>
              </v-col>

            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions class="pt-2">
          <div class="text-end" style="width: 100%;">
            <v-btn class="mr-2" @click="closeDialog" text>{{ $i18n.t("GENERAL.close") }}</v-btn>
            <v-btn
              data-cy="save"
              depressed
              :loading="loadingButton"
              color="primary"
              @click.prevent="addMeeting"
            >{{ $i18n.t("GENERAL.save") }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <InfoMeeting :meeting="savedMeeting" ref="infoMeeting"></InfoMeeting>
  </div>
</template>

<script >
import moment from 'moment'
import Meeting from './../models/meeting.js'
import User from './../models/user.js'
import Company from './../models/company.js'
import timezones from './timezones.json'
import InfoMeeting from './InfoMeeting.vue'
export default {
  components:{
    InfoMeeting
  },
  data() {
    return {
      formatedDate:'',
      tags:[],
      meetingTags:null,
      adminScheduleForAgent:false,
      userForSchedule:null,
      agentList:[],
      user: this.$store.state.Auth.token.claims.sub.split('@')[0],
      company: this.$store.state.Auth.token.claims.sub.split('@')[1],
      openGMT: false,
      datemenu: null,
      timestart: null,
      timeend: null,
      visibleDialog: false,
      defaultTimeZone: `GMT${moment().format('Z')}`,
      rules: {
        required: (value) => !!value || this.$i18n.t("GENERAL.required"),
        email: v => !v || /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || 'E-mail must be valid'
      },
      meetingForm: {},
      savedMeeting: {},
      loadingButton: false
    }
  },
  props:{
    
  },
  computed:{
    computedDateFormattedMomentjs(){
      if(this.meetingForm.date){
        return moment(this.meetingForm.date).format('dddd, MMMM Do YYYY')
      }else{
        return moment().format('dddd, MMMM Do YYYY')
      } 
    }
  },
  methods: {
    formatDate(){
      this.formatedDate = moment(this.meetingForm.date,'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
    },
    changeTags(val){
      if(val.length>0){
        if(this.meetingForm.tags){
        }else{
          this.meetingForm.tags={}
        }
        val.forEach((elem)=>{
          if(typeof this.meetingForm.tags[elem] =='undefined'){
            this.meetingForm.tags[elem]=''
          }
        })
      }else{
        this.meetingForm.tags={}
      }
    },
    remove (item) {
        this.meetingTags.splice(this.meetingTags.indexOf(item), 1)
        this.meetingTags = [...this.meetingTags]
    },
    async getUsers() {
      try {
        let agents = await User.getAll(this.company);
        agents = agents.filter((agent) => agent.type === 'agent');
        this.agentList = agents;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching users",
        });
      }
    },
    changeAgentSchedule(val){
      if(val){
        this.getUsers()
      }

    },
    openTimeZones(){
      if(!this.meetingForm.timezone){
        this.meetingForm.timezone = this.defaultTimeZone;
      }
      this.openGMT = !this.openGMT
    },
    async openDialog(){   
      this.meetingForm.date = moment().format('YYYY-MM-DD');
      this.formatDate();
      this.meetingForm.startdate = moment().format("HH:mm");
      this.visibleDialog = true;
      this.getUsers();
      let company =  await Company.getById(this.$store.state.Auth.token.claims.sub.split('@')[1])
      this.tags = company.tags;
    },
    closeDialog(){
      this.visibleDialog = false;
      this.openGMT = false;
    },
    getTimeZones(){
      return timezones.map(timezone => {
        return {
          value: timezone.offset,
          text: `${timezone.name} (${timezone.offset})`
        }
      })
    },
    getMeetingObj(){
      return {
            userid: this.user,
            company: this.company,
            startdate: this.getDate(this.meetingForm.date, this.meetingForm.startdate),
            enddate: this.getDate(this.meetingForm.date, this.meetingForm.enddate),
            customer_name: this.meetingForm.customer_name,
            customer_email: this.meetingForm.customer_email,
            customer_phone: this.meetingForm.customer_phone,
            description: this.meetingForm.description,
            timezone: this.meetingForm.timezone || this.defaultTimeZone,
            agent_fullname: this.$store.state.Auth.token.claims.name,
            tags: this.meetingForm.tags
          };
    },
    getDate(date, time){
      let momentdate = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
      let momenttime = moment(time, 'HH:mm').format('HHmm');
      return momentdate + momenttime;
    },

    openMeetingInfo(meeting){
      this.savedMeeting = meeting;
      this.$refs.infoMeeting.openDialog(meeting);
    },

    async addMeeting() {
      if (this.$refs.add.validate()) {
        this.loadingButton = true;
        try {
          let obj = this.getMeetingObj();
          if(this.adminScheduleForAgent && this.userForSchedule){
            obj.userid= this.userForSchedule;
          }else if(this.adminScheduleForAgent && !this.userForSchedule){
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: $i18n.t('MEETING.MUSTPICKUSER'),
            });
            this.loadingButton = false;
            return;
          }

          let result = await Meeting.addMeeting(new Meeting(obj));

          if(result.userid == this.user){
            this.openMeetingInfo(result);
            this.$emit('newMeeting',result);
          }else{
            this.userForSchedule=null;
            this.$notify({
                group: "feedback",
                duration: 4000,
                type: "success",
                title: 'Scheduled meeting for agent',
                text: "",
              });
          }
          this.visibleDialog = false;
          this.$refs.add.reset()
          this.meetingForm.date =null
        } catch (error) {
          console.error(error);
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "GET",
            text: "Error saving meeting",
          });
        }
        this.loadingButton = false;
      }
    },

    async createQuickMeeting(){

      let now = moment();
      let end = moment().add(15,'minutes');
      let startdate = now.format('YYYY-MM-DD');
      let enddate = end.format('YYYY-MM-DD');
      let starttime = now.format('HHmm')
      let endtime = end.format('HHmm')

      let obj = {
            userid: this.user,
            company: this.company,
            startdate: this.getDate(startdate, starttime),
            enddate: this.getDate(enddate, endtime),
            customer_name: this.$i18n.t('MAIL.userLabel'),
            customer_email: 'noreply',
            customer_phone: '',
            description: '',
            timezone: this.defaultTimeZone,
            agent_fullname: this.$store.state.Auth.token.claims.name,
            status:'confirmed',
            active:true,
            recordings:[]
          };

      let result = await Meeting.addMeeting(new Meeting(obj));
      this.openMeetingInfo(result);
      this.$emit('newMeeting',result);
    }
  },
  watch:{
  }
};
</script>

<style>
.datepickerindex {
  z-index: 9999;
}
</style>