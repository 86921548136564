<template>
  <div v-shortkey.push="['space']" @shortkey="toggleMute">
    <canvas style="display:none;" width="640" height="480" id="imgResize-virtual-background"></canvas>
    <canvas style="display:none;" id="blackScreenCanvas" width="640" height="480"></canvas>

    <div class="video-camera" v-bind:class="{'switched':switched}" v-show="video.isInCall">
      <video v-if="isMobile"
        v-bind:class="{'switched':switched}"
        @click="oneClickLocal($event,'local')"
        ref="localVideo"
        id="localVideo"
        autoplay
        muted
        playsinline
      ></video>
       <video v-else
        v-bind:class="{'switched':switched}"
        @click="changeCamera"
        ref="localVideo"
        id="localVideo"
        @dblclick="switched = !switched"
        autoplay
        muted
        playsinline
      ></video>

      <!--
     <v-menu offset-y v-else>
      <template v-slot:activator="{ on, attrs }">
        <video v-bind="attrs"
          v-on="on"
        v-bind:class="{'switched':switched}"
        @click="changeCamera"
        ref="localVideo"
        id="localVideo"
        autoplay
        muted
        playsinline
      ></video>

      </template>
      <v-list>
        <v-list-item @click="switched = !switched">
          <v-list-item-title>Switch media</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="(item,index) in videoSources" :key="item.groupId">
          <v-list-item-title> Camera {{index}} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      -->
    </div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          style="position: absolute;
    right: 9px;
    top: 115px;
    z-index: 3;"
          fab
          v-on="on"
          xs-small
          id="shareScreen"
          @click="sheet? sheet = false: showChat()"
        >
          <v-badge small :content="notread" :value="notread" color="red">
            <v-icon v-show="sheet">mdi-chevron-down</v-icon>
            <v-icon v-show="!sheet">mdi-message</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span v-show="sheet">Close Chat</span>
      <span v-show="!sheet">Chat</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="!isMobile && video.isInCall && !sheet"
          style="position: fixed;
    right: 3px; height:20px;width:20px;
    top: 3px;z-index:999999"
          fab
          v-on="on"
          small
          id="fullScreen"
          @click="fullScreen"
        >
          <v-icon style="font-size: 16px;
    margin-top: -2px;">mdi-fullscreen</v-icon>
        </v-btn>
      </template>
      <span>{{$i18n.t('ROOM.FULLSCREEN')}}</span>
    </v-tooltip>
    <v-bottom-sheet v-if="isMobile" v-model="sheet" inset hide-overlay>
      <v-sheet class="text-center" height="325px" style="background:transparent !important">
        <div style="position:relative">
          <MobileChat
            :ismobile="isMobile"
            @sendLocation="sendLocation"
            @uploadFile="uploadNewFile"
            :activecall="video.isInCall"
            :messages="video.messages"
            v-on:send-message="sendMessage"
          ></MobileChat>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <div
      v-if="!isMobile"
      style="width: 33%;
        position: fixed;
        right: 0px;
        bottom: 3px;
        z-index: 7;"
    >
      <MobileChat
        :ismobile="isMobile"
        @sendLocation="sendLocation"
        @uploadFile="uploadNewFile"
        :activecall="video.isInCall"
        v-show="sheet"
        :messages="video.messages"
        v-on:send-message="sendMessage"
      ></MobileChat>
    </div>
    <v-row class="room-row" v-if="!video.isInCall">
      <template v-if="isagent">
        <v-col xl="7" lg="7" md="7" sm="7" cols="12">
          <img
            style="width: 70%;-webkit-transform: scaleX(-1);
  transform: scaleX(-1);
          margin: auto;
          display: block;"
            :src="require('../../src/assets/waiting.svg')"
            alt="Image not found"
          />

          <!-- :content="$store.state.active_people -1"
          :value="$store.state.active_people >=2"-->
        </v-col>
        <v-col xl="5" lg="5" md="5" sm="5" cols="12">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                style="border-bottom: 1px solid #80808026;
    padding: 10px;
    padding-right: 8%;"
                class="headline mb-4 text-left"
              >
                {{$i18n.t("ROOM.WELCOME") +$i18n.t('ROOM.WELCOME2')+$store.state.active_meeting.customer_name}}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      v-clipboard:copy="$store.state.active_meeting? $store.state.active_meeting.url :''"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$i18n.t('MEETING.COPYURL')}}</span>
                </v-tooltip>
              </div>
              <v-list-item-title class="mb-1 text-left pl-3">{{ fromNow }} ({{getFormattedStart()}})</v-list-item-title>
              <v-list-item-subtitle
                class="overline text-left pl-3"
              >{{$i18n.t("ROOM.REASON") + $store.state.active_meeting.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col xl="12" lg="12" md="12" sm="12" cols="12">
          <div class="text-center">
            <v-badge color="red lighten-2" icon="mdi-account" overlap :value="video.members>1">
              <v-btn
                :disabled="video.members<2"
                color="primary"
                @click="joinVideoChat"
              >{{$i18n.t('ROOM.ENTERROOM')}}</v-btn>
            </v-badge>

            <v-btn
              color="danger"
              class="ma-2 ml-5"
              @click="onExitRoom()"
            >{{$i18n.t('ROOM.EXITROOM')}}</v-btn>
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col>
          <img
            style="width: 40%;-webkit-transform: scaleX(-1);
  transform: scaleX(-1);
          margin: auto;
          display: block;"
            :src="require('../../src/assets/waiting.svg')"
            alt="Image not found"
          />

          <!-- :content="$store.state.active_people -1"
          :value="$store.state.active_people >=2"-->

          <div class="text-center" v-if="isagent">
            <v-btn
              :disabled="video.members<2"
              color="primary"
              class="ma-2"
              @click="joinVideoChat"
            >{{$i18n.t('ROOM.ENTERROOM')}}</v-btn>

            <v-btn color="primary" class="ma-2" @click="onExitRoom()">{{$i18n.t('ROOM.EXITROOM')}}</v-btn>
          </div>

          <template v-else>
            <p style="margin-top:20px">{{$i18n.t("ROOM.WAITING")}}</p>
            <chronometer></chronometer>
          </template>
        </v-col>
      </template>
    </v-row>
    <v-row v-show="video.isInCall">
      <v-col>
        <div class="video-content">
          <div v-show="video.onlyAudio" style="display: flex;
    margin-top: 33vh;">
            <v-icon
              style="display: block;
    margin: auto;
    font-size: 80px !important;
    background: #80808063;
    border-radius: 34px;
    padding: 15px;"
            >mdi-account</v-icon>
          </div>

          <v-progress-circular
            v-if="!video.onlyAudio"
            style="height: 70px;
              width: 70px;
              display: block;
              margin: auto;
              margin-top: 35vh;"
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <canvas v-show="!isMobile && $store.state.portrait" id="canvas"></canvas>
          <VueCountdown
            @end="timeEnded"
            v-show="$store.state.Auth.isLogged && !isMobile && video.isInCall && showTime && showTime>0"
            style="    position: fixed;
    bottom: 10px;
    left: 17px;
    background: #000000ab;
    border-radius: 27px;
    padding: 5px 10px 5px 10px;
    color: white;
    font-size: 13px;z-index:7"
            :time="showTime"
            :transform="transform"
          >
            <template slot-scope="props">
              <v-icon color="yellow" small style="margin-right:5px">mdi-alert</v-icon>
              {{$i18n.t("MEETING.REMAININGTIME")}} {{ props.minutes }}:{{ props.seconds }}
            </template>
          </VueCountdown>

          <video v-if="isMobile"
            ref="remoteVideo"
            id="remoteVideo"
            @click="oneClickLocal($event,'remote')"
            v-bind:class="{'client':!this.$store.state.Auth.isLogged,'vertical-phone':$store.state.portrait || videoSource=='desktop','switched':switched}"
            width="100%"
            autoplay
            playsinline
          ></video>
          <video v-else
            ref="remoteVideo"
            id="remoteVideo"
            @click="toggleButtons"
            @dblclick="switched = !switched"
            v-bind:class="{'client':!this.$store.state.Auth.isLogged,'vertical-phone':$store.state.portrait || videoSource=='desktop','switched':switched}"
            width="100%"
            autoplay
            playsinline
          ></video>



          <div v-show="!hideAll" class="video-actions" v-bind:class="{'client':!isMobile}">
            <div style="display: block;">
              <!-- mobile buttons -->
            </div>
            <div class="bottom-actions" v-show="!sheet || !isMobile">
              <div
                style="
    margin: auto auto 18px;
    color: rgba(255, 255, 255, 0.83);
    background: rgba(0, 0, 0, 0.30);
    border-radius: 52px;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    text-align: center;
    position: fixed;
    top: 11px;
    left: 48%;"
                v-if="video.isInCall"
              >
                <chronometer></chronometer>
              </div>
              <div
                class="btnContainer"
                v-bind:class="{'isDesktop':!isMobile,'notAndroid': !checkRecord}"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-4"
                      v-if="!isMobile"
                      fab
                      v-on="on"
                      id="shareScreen"
                      @click="shareScreen"
                    >
                      <v-icon>{{ video.screenSharing ? 'mdi-camera-party-mode' : 'mdi-monitor'}}</v-icon>
                    </v-btn>
                  </template>

                  <span
                    v-if="$store.state.permissions.video"
                  >{{ video.screenSharing? $i18n.t("ROOM.ENDSHARESCREEN") : $i18n.t("ROOM.SHARESCREEN") }}</span>
                  <span
                    v-else
                  >{{ video.screenSharing? $i18n.t("ROOM.FINISHSHARESCREEN") : $i18n.t("ROOM.SHARESCREEN") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="checkRecord"
                      class="mr-4"
                      fab
                      v-on="on"
                      v-bind:class="{'recording': recording}"
                      @click="recordVideo"
                    >
                      <v-icon class="recButton">{{recording? 'mdi-stop':'mdi-record'}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ recording? $i18n.t("ROOM.STOPRECORDING"): $i18n.t("ROOM.STARTRECORDING") }}</span>
                </v-tooltip>

                <ScreenShot @sendScreenShoot="sendScreenShoot" @addimage="addImageToRecorder" v-if="!isMobile && $store.state.Auth.isLogged" :room="true"></ScreenShot>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mr-4" fab v-on="on" @click="muteSound">
                      <v-icon>{{ video.audioMuted ? 'mdi-microphone-off' : 'mdi-microphone'}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ video.audioMuted? $i18n.t("ROOM.UNMUTEMIC"): $i18n.t("ROOM.MUTEMIC") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-4"
                      v-on="on"
                      fab
                      @click="muteVideo"
                      :disabled="!$store.state.permissions.video"
                    >
                      <v-icon>{{ video.videoMuted ? 'mdi-video-off' : 'mdi-video'}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ video.videoMuted? $i18n.t("ROOM.UNMUTEVIDEO"): $i18n.t("ROOM.MUTEVIDEO") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      color="red lighten-1"
                      v-on="on"
                      dark
                      @click="endCall"
                      style="transform: rotate(135deg);"
                    >
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $i18n.t("ROOM.ENDCALL") }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div style="display:none" id="sCaptions" v-if="$store.state.active_call">
      <p id="pCaptions"></p>
    </div>
    <VueFullScreenFileDrop @drop="onDrop" />


     <DialogGalleryConfirm :meeting="meeting" ref="gallery"></DialogGalleryConfirm>
  </div>
</template>

<script>
import VEmojiPicker from "v-emoji-picker";
import MobileChat from "@/components/MobileChat.vue";
import Communications from "./../communications/Communications";
import communicationsSingleton from "./../communications/CommunicationsSingleton";
import moment from "moment";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { Synth } from "tone";
import chronometer from "./../components/chronometer.vue";
import Meeting from "../models/meeting";

import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import BlurStream from "./blur.js";
import processor from "./processor.js";
import ScreenShot from "@/components/ScreenShot.vue";
import DialogGalleryConfirm from "./../components/DialogGalleryConfirm";


export default {
  components: {
    VEmojiPicker,
    MobileChat,
    chronometer,
    VueCountdown,
    VueFullScreenFileDrop,
    ScreenShot,
    DialogGalleryConfirm
  },
  data() {
    return {
      result: [],
      delay: 700,
      clicks: 0,
      timer: null,
      BlurStream: null,
      intervalToStartMeeting: null,
      fromNow: "",
      videoSource: "user",
      showTime: 0,
      dialogWebRTC: false,
      WebRTCSupported: false,
      notread: 0,
      actualCamera: true,
      sheet: false,
      recording: false,
      video: false,
      urlOK: false,
      urlParams: null,
      isMobile: null,
      hideAll: false,
      dialog: false,
      inCall: false,
      //meeting info
      company: "",
      userid: "",
      idmeeting: "",
      isagent: "",
      dropBinded: false,
      videoconstraints: false,
      switched: false,
      videoSources: null,
    };
  },
  props: {
    companyProp: String,
    userIdProp: String,
    idmeetingProp: String,
    isAgentProp: Boolean,
    meeting: Object,
  },
  created() {
    console.info("Room created");
    this.isagent = this.isAgentProp;
    this.isMobile = this.isMobileDevice();

    if (this.isMobile) {
      this.getDeviceIdForLabel(null);
    }
    if (this.isagent) {
      Meeting.updateMeeting(new Meeting(this.meeting), { active: true });
    }
  },

  mounted() {
    this.checkCompatibility();
    this.enterRoom();
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    var video = document.getElementById("remoteVideo");
    this.getFromNowMeeting();

    navigator.mediaDevices.addEventListener("devicechange", async (event) => {
      const newAudioList = await this.getConnectedDevices("audioinput");
      const newVideoList = await this.getConnectedDevices("videoinput");

      if (this.$store.state.audioInputDeviceLabel) {
        let defaultDevice = newAudioList.find(
          (device) => device.deviceId == this.$store.state.audioInputDeviceLabel
        );
        if (!deufaltDevice) {
          this.$notify({
            group: "feedback",
            duration: 4000,
            type: "error",
            title: "disconected default audio input device",
            text: "",
          });
        }
      }

      if (this.$store.state.videoDeviceLabel) {
        let defaultDevice = newVideoList.find(
          (device) => device.deviceId == this.$store.state.videoDeviceLabel
        );
        if (!deufaltDevice) {
          this.$notify({
            group: "feedback",
            duration: 4000,
            type: "error",
            title: "disconected default video input device",
            text: "",
          });
        }
      }
    });

    if (this.$store.state.Auth.isLogged) {
      video.addEventListener(
        "play",
        function () {
          var $this = this; //cache
          (function loop() {
            if (!$this.paused && !$this.ended) {
              ctx.drawImage($this, 0, 0);
              setTimeout(loop, 1000 / 30); // drawing at 30fps
            }
          })();
        },
        0
      );
      processor.doLoad();
    }
  },

  beforeDestroy() {
    //communicationsSingleton.destroyed();
    console.info("room destroyed");
    this.video = null;
  },
  methods: {
    addImageToRecorder(image){
      if(this.$store.state.Auth.isLogged){
        this.$refs['gallery'].addImage(image);
      }
    },
    
    oneClickLocal(event,type){
          this.clicks++ 
          if(this.clicks === 1) {
            var self = this
            this.timer = setTimeout(()=> {
              self.clicks = 0

              if(type=='local'){
                this.switched? this.toggleButtons():this.changeCamera()
              }else{
                this.switched? this.changeCamera():this.toggleButtons()
              }
              
            }, this.delay);
          } else{
             clearTimeout(this.timer);  
             this.clicks = 0;
             this.switched = !this.switched;
          }         
    },
    async getDeviceIdForLabel(cameraLabel) {
      const videoInputs = await this.getVideoInputs();

      for (let i = 0; i < videoInputs.length; i++) {
        const videoInput = videoInputs[i];
        if (videoInput.label === cameraLabel) {
          return videoInput.deviceId;
        }
      }

      this.videoSources = videoInputs.filter(
        (device) => device.kind === "videoinput"
      );
    },
    async getVideoInputs() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        //console.log("enumerateDevices() not supported.");
        return [];
      }

      const devices = await navigator.mediaDevices.enumerateDevices();

      return devices;
    },

    async getConnectedDevices(type) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter((device) => device.kind === type);
    },

    consoleAudioinpus(inputs) {
      console.log(inputs);
    },
    sendScreenShoot(data){
      let file = new File([data.blob], "Screenshot.png")
      this.uploadNewFile(file);
    },
    // Example event handler
    onDrop(formData, files) {
      this.uploadNewFile(files[0]);
    },
    toggleButtons() {
      this.hideAll = !this.hideAll;
      if (this.hideAll) {
        var element, name, arr;
        element = document.getElementById("app");
        name = "dontShow";
        arr = element.className.split(" ");
        if (arr.indexOf(name) == -1) {
          element.className += " " + name;
        }
      } else {
        var element = document.getElementById("app");
        element.className = element.className.replace(/\bdontShow\b/g, "");
      }
    },
    timeEnded() {
      this.showTime = 0;
    },
    toggleMute(e) {
      console.info(document.activeElement.id);
      if (this.video.isInCall) {
        let bool = e.returnValue;
        let activeElementId = document.activeElement.id;
        if (activeElementId != "message") {
          if (bool && this.video.audioMuted) {
            this.muteSound();
          } else {
            this.muteSound();
          }
        }
      }
    },
    uploadNewFile(file) {
      if (this.$store.state.active_call) {
        this.video.SendFile(file);
      }
    },
    sendLocation(location) {
      this.video.SendLocation(location);
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        // uses singular form when the value is less than 2
        const word = value < 2 ? key.replace(/s$/, "") : key;
        props[key] = `${digits}`;
      });

      return props;
    },
    onExitRoom() {
      this.video.destroyed();
      if (this.isagent) {
        Meeting.updateMeeting(new Meeting(this.meeting), {
          active: false,
          status: "finished",
        });
      }
      clearInterval(this.intervalToStartMeeting);
      this.notread = 0;
      this.actualCamera = true;
      this.video.audioMuted = false;
      this.video.videoMuted = false;
      this.recording = false;
      this.video.screenSharing = false;
      this.inCall = false;
      this.hideAll = false;

      this.$emit("exitroom");
    },
    getClientId() {
      if (this.isagent)
        return this.$store.state.Auth.token.claims.sub.split("@")[0];
      else return this.meeting.idmeeting;
    },
    enterRoom() {
      this.createCommunications();
    },
    createCommunications() {
      this.video = new Communications(
        this.meeting,
        this.$refs["localVideo"],
        this.$refs["remoteVideo"],
        this.getClientId(),
        this.isagent
      );

      this.video.onEndCallEvent = () => {
        if (this.$store.state.room_configs.backgroundBlur) {
          this.BlurStream.stop();
        }

        if (this.$store.state.room_configs.virtualBackground) {
          if (processor.video.srcObject) {
            processor.stop();
          }
        }
        
        this.SHOWMODAL()

        this.$emit("endcall");
        if (!this.isagent) {
          this.video.destroyed();
        }
      };
    },
    SHOWMODAL(){
      if(this.$refs['gallery'].images.length>0){
        this.$refs['gallery'].display();
      }
      
    },
    checkCompatibility() {
      var isWebRTCSupported =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        window.RTCPeerConnection;
      if (isWebRTCSupported) {
        this.dialogWebRTC = false;
        this.WebRTCSupported = true;
      } else {
        this.WebRTCSupported = false;
      }
    },
    showChat() {
      this.sheet = true;
      this.notread = 0;
      let elem = document.getElementById("message");
      let element = document.getElementById("messages");

      setTimeout(function () {
        elem.focus();
        element.scrollTop = element.scrollHeight;
      }, 200);
    },
    isMobileDevice() {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    },
    changeCamera() {
      if (this.isMobile) {
        if (this.actualCamera) {
          this.video.SendRearCamera();
        } else {
          this.video.SendCamera();
        }
      } else {
        this.toggleButtons();
        //this.switched=!this.switched
        //this.video.SendCamera()
      }
      this.actualCamera = !this.actualCamera;
    },
    muteVideo() {
      this.video.MuteVideo();
    },
    muteSound() {
      this.video.MuteSound();
    },
    recordVideo() {
      this.recording = !this.recording;
      this.video.Record();
    },
    async joinVideoChat() {
      clearInterval(this.intervalToStartMeeting);
      let meeting = this.$store.state.active_meeting
        ? this.$store.state.active_meeting
        : {};
      let now = moment();
      let start = moment(meeting.startdate, "YYYYMMDDHHmm");
      let end = moment(meeting.enddate, "YYYYMMDDHHmm");
      let diff = end.diff(now, "miliseconds");
      let diff2 = end.diff(start, "miliseconds");
      this.showTime = diff2;

      if (now > end) {
        this.showTime = 0;
      }

      if (
        this.$store.state.permissions &&
        this.$store.state.permissions.audio &&
        this.$store.state.permissions.video &&
        this.$store.state.room_configs.onlyAudio
      ) {
        this.video.videoMuted = true;
      }
      if (this.$store.state.room_configs.backgroundBlur) {
        this.videoconstraints = this.createConstraints();
        let stream = await this.initStream();
        this.video.JoinVideoCall(stream);
      } else if (this.$store.state.room_configs.virtualBackground) {
        let stream = await processor.start();
        this.video.JoinVideoCall(stream);
      } else {
        this.video.JoinVideoCall();
      }
    },

    getFromNowMeeting() {
      let meeting = this.$store.state.active_meeting
        ? this.$store.state.active_meeting
        : {};
      let now = moment();
      let start = moment(meeting.startdate, "YYYYMMDDHHmm");
      let timeToStart = now.diff(start, "seconds") * 1000;
      let end = moment(meeting.enddate, "YYYYMMDDHHmm");
      let diff = now.diff(end, "seconds") * 1000;

      if (now <= start) {
        this.fromNow =
          this.$i18n.t("ROOM.MEETINGSTART") +
          moment(this.$store.state.active_meeting.startdate, "YYYYMMDDHHmm")
            .lang(this.$store.state.lang ? this.$store.state.lang : "en")
            .fromNow();
        if (!this.intervalToStartMeeting) {
          setInterval(this.getFromNowMeeting, 60000);
        }
        return this.fromNow;
      } else if (now > start) {
        this.fromNow =
          this.$i18n.t("ROOM.MEETINGSTARTED") +
          moment(this.$store.state.active_meeting.startdate, "YYYYMMDDHHmm")
            .lang(this.$store.state.lang ? this.$store.state.lang : "en")
            .fromNow();
        return this.fromNow;
      }

      return;
    },
    getFormattedStart() {
      return moment(
        this.$store.state.active_meeting.startdate,
        "YYYYMMDDHHmm"
      ).format("HH:mm");
    },
    async shareScreen() {
      if (this.video.screenSharing) {
        if (this.$store.state.permissions.video) {
          this.video.SendCamera();
        } else {
          this.video.deleteScreenStream();
        }
        this.video.screenSharing = false;
      } else {
        await this.video.SendDesktop();
      }
    },
    endCall() {
      this.video.EndVideoCall();
    },
    sendMessage(msg) {
      this.video.SendChat(msg);
    },
    fullScreen() {
      var elem = document.getElementById("remoteVideo");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    },
    onCopy: function (e) {
      this.$notify({
        group: "feedback",
        duration: 4000,
        type: "success",
        title: this.$i18n.t("ROOM.CLIPBOARDCOPY"),
        text: "",
      });
    },
    onError: function (e) {
      this.$notify({
        group: "feedback",
        duration: 4000,
        type: "error",
        title: this.$i18n.t("ROOM.CANTCLIPBOARDCOPY"),
        text: "",
      });
    },
    async initStream() {
      let stream;
      if (this.BlurStream) {
        stream = this.BlurStream.init();
      } else {
        this.BlurStream = new BlurStream();
        stream = this.BlurStream.getStream();
      }

      return stream;
    },
    //Constraints for Audio and
    createConstraints() {
      //Video
      let videoconstraints = {
        width: { min: 320, max: 1280, ideal: 1280 },
        height: { min: 180, max: 720, ideal: 720 },
        aspectRatio: { ideal: 1.77777778 },
        facingMode: { ideal: "user" },
      };
      let video_resolution_configs = [
        { height: 180, width: 320 },
        { height: 360, width: 640 },
        { height: 720, width: 1280 },
      ];
      let videoResolution =
        video_resolution_configs[this.$store.state.room_configs.video_quality];

      videoconstraints.width = {
        exact: videoResolution.width,
      };
      videoconstraints.height = {
        exact: videoResolution.height,
      };
      if (
        this.$store.state.availableDevices &&
        this.$store.state.availableDevices.length > 0
      ) {
        let videoInputDevice = this.$store.state.availableDevices.filter(
          (device) => device.deviceId === this.$store.state.videoDeviceLabel
        );

        if (videoInputDevice && videoInputDevice.length > 0) {
          videoconstraints.deviceId = {
            exact: this.$store.state.videoDeviceLabel,
          };
        }
      }
      return videoconstraints;
    },
  },
  watch: {
    "video.messages": function (val) {
      if (val && val != "") {
        if (!this.sheet) {
          this.notread++;
          //create a synth and connect it to the master output (your speakers)
          const synth = new Synth().toMaster();

          //play a middle 'C' for the duration of an 8th note
          synth.triggerAttackRelease("D4", "8n");
        } else {
          let element = document.getElementById("messages");
          setTimeout(function () {
            element.scrollTop = element.scrollHeight;
          }, 100);
        }
      }
    },
    "$store.state.room_configs.video_quality": function (val) {
      if (this.video.isInCall) {
        let video_resolution_configs = [
          { height: 180, width: 320 },
          { height: 360, width: 640 },
          { height: 720, width: 1280 },
        ];
        let videoResolution =
          video_resolution_configs[
            this.$store.state.room_configs.video_quality
          ];
        this.video.ChangeResolution(
          videoResolution.height,
          videoResolution.width
        );
      }
    },
    "$store.state.room_configs.subtitles": function (val) {
      if (this.video.isInCall) {
        this.video.signal.toggleCC(val);
      }
    },
    "$store.state.room_configs.backgroundBlur": async function (val) {
      if (this.$store.state.active_call) {
        if (val) {
          let stream = await this.initStream();
          this.video.setBackgroundBlur(stream);
        } else {
          this.BlurStream.stop();
          this.video.setNoBackgroundBlur();
        }
      }
    },
    "$store.state.room_configs.virtualBackground": async function (val) {
      if (this.$store.state.active_call) {
        if (val) {
          let stream = await processor.start();
          this.video.setBackgroundBlur(stream);
        } else {
          if (processor.video.srcObject) {
            processor.stop();
            this.video.setNoBackgroundBlur();
          }
        }
      }
    },
    "$store.state.videoDeviceLabel": function (val) {
      this.video.ChangeDevices();
    },
    "$store.state.audioInputDeviceLabel": function (val) {
      this.video.ChangeDevices();
    },
    "$store.state.active_call": function (val) {
      if (!val) {
        this.video.isInCall = false;
        this.sheet = false;
        this.notread = 0;
        this.actualCamera = true;
        this.video.audioMuted = false;
        this.video.videoMuted = false;
        this.recording = false;
        this.video.screenSharing = false;
        this.inCall = false;
        this.hideAll = false;
        let elem = document.getElementById("pCaptions");
        let sCaptions = document.getElementById("sCaptions");
        sCaptions.style.display = "none";
        elem.innerText = "";
        var element = document.getElementById("app");
        element.className = element.className.replace(/\bdontShow\b/g, "");
      }
    },
    "$store.state.videosource": function (val) {
      this.videoSource = val;
    },
  },
  computed: {
    membersCount() {
      return this.video ? this.video.signal.members : 0;
    },
    checkRecord() {
      if (this.$store.state.Auth.isLogged) {
        if (
          this.$store.state.Auth.token.claims.recordEnabled == "both" ||
          this.$store.state.Auth.token.claims.recordEnabled == "only agent"
        ) {
          if (!this.isMobile) {
            return true;
          }

          let ua = navigator.userAgent.toLowerCase();
          let isAndroid = ua.indexOf("android") > -1;
          if (isAndroid && this.isMobile) {
            return true;
          }
        } else {
          return false;
        }
      } else {
        if (
          this.video.disabledRecording &&
          this.video.disabledRecording != "both"
        ) {
          return false;
        }

        if (!this.isMobile) {
          return true;
        }

        let ua = navigator.userAgent.toLowerCase();
        let isAndroid = ua.indexOf("android") > -1;
        if (isAndroid && this.isMobile) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
#sCaptions {
  top: 11px;
  left: 79px;
  margin: auto;
  position: absolute;
  display: block;
  margin-left: 10px;
  max-width: 68%;
}

#pCaptions {
  display: inline-block;
  background: #00000078;
  border-radius: 4px;
  color: #fff;
  padding: 5px 10px 5px 10px;
  font-size: 21px;
  margin-bottom: 0px;
}

.room-row {
  height: calc(100vh - 80px);
  align-items: center;
  text-align: center;
}

.video-content {
  position: relative;
  height: 100%;
}

.video-camera {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.video-actions {
  position: fixed;
  top: 60px;
  width: 50px;
  left: 17px;
}

#remoteVideo {
  position: fixed;
  right: 0;
  top: 0px;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: fill;
  object-fit: cover;
  cursor: pointer;
}

#remoteVideo.vertical-phone {
  height: 100% !important;
  object-fit: contain !important;
}

@media (min-aspect-ratio: 16/9) {
  #remoteVideo {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  #remoteVideo {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

#localVideo {
  width: 6em;
  -o-object-fit: cover;
  object-fit: cover;
  height: 6em;
  border-radius: 1000px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  cursor: pointer;
}

.bottom-actions {
  position: fixed;
  bottom: 15px;
  left: 0;
  width: 100%;
}

#remoteVideo.client {
  top: 0px !important;
}

.video-camera.client {
  top: 30px;
  right: 10px;
  z-index: 6;
}

.video-actions.client {
  top: 30px;
  z-index: 5;
}

#shareScreen.client {
  display: none;
}

.btnContainer {
  text-align: center;
  margin: auto;
}
#remoteVideo.switched {
  position: fixed !important;
  right: 10px;
  top: 10px !important;
  width: 96px;
  border-radius: 205px;
  height: 95px !important;
  min-width: unset !important;
  min-height: unset !important;
  z-index: 3;
  object-fit: unset !important;
}
#localVideo.switched {
  position: fixed;
  -webkit-transform: none !important;
  top: 0px;
  left: 0px;
  border-radius: 0px;
  width: 100%;
  height: 100%;
  transition: none !important;
}
.video-camera.switched {
  top: 0px;
  left: 0px;
  min-width: 100%;
}

.v-dialog.v-bottom-sheet.v-bottom-sheet--inset {
  box-shadow: 0px 0px !important;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

#canvas {
  position: fixed;
  top: 0px;
  width: 100%;
  filter: blur(188px);
}

.recording {
  animation: shadow-pulse 1s infinite;
}
.recButton {
  width: 46px !important;
  height: 47px !important;
  font-size: 34px !important;
  border: 2px solid white;
  border-radius: 100px;
}
</style>
