<template>
  <v-dialog v-model="show" persistent max-width="700">
    <v-card>
      <v-card-title class="headline">{{$i18n.t("MEETING.PICKSCREENSHOTUPLOAD")}}</v-card-title>

      <v-layout>
        <v-row no-gutters>
          <v-col  @click="image.selected= !image.selected" cols="3" v-for="image in images" :key="'link'+image.url" >
              <img v-bind:class="{'selectedImg': image.selected}" class="hoverImg" style="height: 202px;
    margin: auto;
    padding: 22px;
    display: block;
    width: 100%;
    object-fit: contain;" :src="image.url"  />

            <v-icon v-show="image.selected" style="margin: auto;
    display: block;
    text-align: center;
    position: absolute;
    top: 35%;
    left: 36%;
    color: green;
    font-size: 58px;"> mdi-check</v-icon>


           <v-icon v-show="!image.selected" style="margin: auto;
    display: block;
    text-align: center;
    position: absolute;
    top: 35%;
    left: 36%;
    color: red;
    font-size: 58px;"> mdi-cancel</v-icon>

         
          </v-col>
        </v-row>
      </v-layout>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" dark @click="confirm()">
          {{$i18n.t("MEETING.confirm")}}
        </v-btn>
        <v-btn color="red darken-1"  dark @click="show = false">
          {{$i18n.t("GENERAL.close")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>

import firebase from "@/firebase";

import moment from 'moment'
import Meeting from "@/models/meeting.js"

export default {
  props:{
    meeting : Object
  },
  data() {
    return {
      show: false,
      images: []
    };
  },
  mounted() {},
  methods: {
    display() {
      this.show = true;
    },
    async confirm(){
      this.images.forEach(async (image)=>{
        if(image.selected){
          let result = await this.uploadFile(image.blob,image.format);
        }      
      });
      this.show=false;
    },

    addImage(image){
        this.images.push({
            format: image.format,
            blob:image.blob,
            url:image.dataUri,
            selected:true,
            progress:0
        })
    },

    async uploadFile(blob, format) {

      return new Promise((resolve, reject) => {

        const metadata = {
          contentType: 'image/jpg',
          customMetadata: {
            company: this.$props.meeting.company,
            userid: this.$props.meeting.userid,
            id: this.$props.meeting.idmeeting,
          },
        };

        const timestamp = this.$props.meeting.startdate.substring(0, 8);


        if (!this.$props.meeting.recordings) {
          this.$props.meeting.recordings = [];
        }


        let datetime =  moment().format('YYYY-MM-DD_HH:mm:ss:SSS');
        let recordingName = datetime;


        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef
          .child(
            this.meeting.company +
            "/" +
            this.meeting.userid +
            "/" +
            timestamp +
            "/" +
            recordingName +
            "." + format
          )
          .put(blob, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed", // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //this.setUploadProgress(progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused": // or 'paused'
                console.log("Upload is paused");
                break;
              case "running": // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            reject()
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
              let meetingUpdate = this.meeting;
              meetingUpdate.recordings.push({
                name: recordingName + '.' + format,
                size: blob.size
              }); 

              //meetingUpdate.recordings.push((recordingName+'.'+format));

              await Meeting.updateMeetingRecording(new Meeting(meetingUpdate));
              await Meeting.updateCompanyAndUserStorage(new Meeting(meetingUpdate), blob.size)
         

              resolve(downloadURL)
            });
          }
        );


      })

    }
  },
};
</script>

<style scoped>
.hoverImg:hover{
    background : lightgray;
    cursor:pointer;
}

.selectedImg{
    opacity:0.6;
}

.hoverImg:not(.selectedImg){
  opacity:0.6;
}
</style>