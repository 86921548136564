<template>
  <div style="display:inline-block;"> 
  <v-tooltip
    v-if="!room"
    :bottom="!$store.state.active_call"
    :right="$store.state.active_call"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="VideoScreenshot" v-on="on" icon class="mr-2">
        <v-icon>mdi-camera</v-icon>
      </v-btn>
    </template>
    <span>{{ $i18n.t("ROOM.TAKESCREENSHOT") }}</span>
  </v-tooltip>

  <v-tooltip top v-else>
    <template v-slot:activator="{ on }">
      <v-btn class="mr-4" fab v-on="on" @click="VideoScreenshot">
        <v-icon>mdi-camera</v-icon>
      </v-btn>
    </template>
    <span>{{ $i18n.t("ROOM.TAKESCREENSHOT") }}</span>
  </v-tooltip>

  <v-dialog v-model="dialogEdit" persistent :max-width="dialogMaxWidth">
    <v-card>
      <v-card-title class="headline">{{ $i18n.t("SETTING.EDITSCREENSHOT") }}</v-card-title>
      <v-layout>
        <v-row no-gutters>
          <v-col :cols="column_a">
                <canvas  style="border:1px solid grey;margin:auto;display:block;" ref="myCanvasEdit" id="myCanvasEdit" :width="canvas_size.width" :height="canvas_size.height" @mouseleave="isDrawing=false" @mousemove="draw" @mousedown="beginDrawing" @mouseup="stopDrawing" />
          </v-col>
          <v-col :cols="column_b">

            <v-color-picker
            v-model="strokeStyle"
  dot-size="25" hide-inputs
  swatches-max-height="200"
></v-color-picker>


 <v-subheader class="pl-0">
         {{$i18n.t("SETTING.LINEWIDTH")}}
        </v-subheader>
      <v-slider class="pr-6"
            v-model="lineWidth"
           
            :max="5"
            :min="1"
            thumb-label
          >
          </v-slider>
          </v-col>
        </v-row>
      </v-layout>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" dark @click="confirmEdit();dialogEdit=false">
          {{$i18n.t("GENERAL.send")}}
        </v-btn>
        <v-btn color="red darken-1"  dark @click="dialogEdit = false">
           {{$i18n.t("GENERAL.close")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

 </div>

</template>

<script>

export default {
  props: {
    room: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogEdit:false,
      thing: null,
      frameToEdit:null,
      canvas:null,
      x:0,
      y:0,
      isDrawing:false,
      strokeStyle:"#000",
      lineWidth:3,
      dialogMaxWidth:1000,
      canvas_size:{
        width: 640,
        height: 360
      },
      column_a:8,
      column_b:4
    };
  },
   watch: {
    "$store.state.portrait": function (val) {

      if(this.dialogEdit==false){

        if(!val){
          debugger;
          this.dialogMaxWidth=1000;
          this.canvas_size.width=640;
          this.canvas_size.height=360;
          this.column_a=8;
          this.column_b=4;
        }else{
          this.dialogMaxWidth=700;
          this.canvas_size.width=260;
          this.canvas_size.height=460;
          this.column_a=6;
          this.column_b=6;
        }
      }
    }
  },
  mounted() {
    
  },
  methods: {
    showCoordinates(e) {
      this.x = e.offsetX;
      this.y = e.offsetY;
    },
     drawLine(x1, y1, x2, y2) {
      let ctx = this.canvas;
      ctx.beginPath();
      ctx.lineWidth = this.lineWidth;
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.strokeStyle = this.strokeStyle;
      ctx.stroke();
      ctx.closePath();
    },
    draw(e) {
      if(this.isDrawing){
        this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
        this.x = e.offsetX;
        this.y = e.offsetY;
      }
    },
    beginDrawing(e) {
      this.x = e.offsetX;
      this.y = e.offsetY;
      this.isDrawing = true;
    },
    stopDrawing(e) {
      if (this.isDrawing) {
        this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;
      }
    },
    async VideoScreenshot() {
      let frame = this.captureVideoFrame("remoteVideo", "png", 1);

      let method = this.$store.state.Auth.token.claims.recordings || "download";

      let res = await this.$confirm(this.$i18n.t("SETTING.CONFIRMEDIT"));
      if (res) {
        this.frameToEdit=frame;
        this.dialogEdit=true;
        this.$nextTick(() => {
          let c = document.getElementById('myCanvasEdit');
          this.canvas = c.getContext('2d');

          var url = URL.createObjectURL(frame.blob);
          var img = new Image;
          img.style.display = 'none';
          img.onload = () => {
              //this.canvas.drawImage(img, 0, 0);
              var canvas = this.canvas.canvas ;
               // get the scale
              var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
              // get the top left position of the image
              var x = (canvas.width / 2) - (img.width / 2) * scale;
              var y = (canvas.height / 2) - (img.height / 2) * scale;
              this.canvas.drawImage(img, x, y, img.width * scale, img.height * scale);
              img.remove(); 


          }
          img.src = url;

          if(!this.$store.state.portrait){
            img.width=640;
            img.height=360;  
          }else{
            img.width=260;
            img.height=460; 
          }
          
          document.body.appendChild(img);
        });
      }else{
        if (method == "download") {
          this.downloadScreenshot("screenshot.png", frame.dataUri);
        } else {
          this.$emit("addimage", frame);
        }
      }
    },
    confirmEdit(){
      let method = this.$store.state.Auth.token.claims.recordings || "download";

      let frame = this.captureEditCanvas();
      if (method == "download") {
        this.downloadScreenshot("screenshot.png", frame.dataUri);
      } else {
        this.$emit("addimage", frame);
        this.$emit("sendScreenShoot", frame);
      }
    },
    captureEditCanvas(){

      let format =  "png";
      let quality = 0.92

      let canvas = document.getElementById('myCanvasEdit');

      const dataUri = canvas.toDataURL("image/" + format, quality);
      const data = dataUri.split(",")[1];
      const mimeType = dataUri.split(";")[0].slice(5);

      const bytes = window.atob(data);
      const buf = new ArrayBuffer(bytes.length);
      const arr = new Uint8Array(buf);

      for (let i = 0; i < bytes.length; i++) {
        arr[i] = bytes.charCodeAt(i);
      }

      const blob = new Blob([arr], { type: mimeType });
      return { blob: blob, dataUri: dataUri, format: format };

    },
    captureVideoFrame(video, format, quality) {
      if (typeof video === "string") {
        video = document.getElementById(video);
      }

      format = format || "jpeg";
      quality = quality || 0.92;

      if (!video || (format !== "png" && format !== "jpeg")) {
        return false;
      }

      const canvas = document.createElement("CANVAS");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      canvas.getContext("2d").drawImage(video, 0, 0);

      const dataUri = canvas.toDataURL("image/" + format, quality);
      const data = dataUri.split(",")[1];
      const mimeType = dataUri.split(";")[0].slice(5);

      const bytes = window.atob(data);
      const buf = new ArrayBuffer(bytes.length);
      const arr = new Uint8Array(buf);

      for (let i = 0; i < bytes.length; i++) {
        arr[i] = bytes.charCodeAt(i);
      }

      const blob = new Blob([arr], { type: mimeType });
      return { blob: blob, dataUri: dataUri, format: format };
    },
    downloadScreenshot(filename, data) {
      let element = document.createElement("a");
      element.setAttribute("href", data);
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<style>
#myCanvasEdit:hover{
  cursor: crosshair;
}
</style>