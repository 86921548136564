import Signalling from "./Signalling";
import communicationsSingleton from "./CommunicationsSingleton";
import store from "./../store/index";
import { Synth } from "tone";
import VideoRecorder from "./VideoRecorder";
import Meeting from "./../models/meeting"

export default class Communications {
  constructor(meeting, localVideo, remoteVideo, clientId, isAgent) {
    console.log("comunications created!!!");
    this.meeting = meeting;
    this.localVideo = document.getElementById("localVideo");
    this.remoteVideo = document.getElementById("remoteVideo");
    this.isInCall = false;
    this.onlyAudio = false;
    this.members = 0;
    this.messages = [];
    this.store = store;
    this.disabledRecording=false;
    this.signal = new Signalling(
      meeting,
      localVideo,
      remoteVideo,
      this,
      clientId,
      isAgent
    );
    this.videoRecorder = new VideoRecorder(
      this.meeting
    );


    this.screenSharing = false;
    this.audioMuted = false;
    this.videoMuted = false;


    this.orientationHandler();
    //register on singleton for unload events
    communicationsSingleton.setCommunications(this);
  }

  onMembersChange(value) {
    if (value <= this.members) {
      if (this.store.state.Auth.isLogged && value > 1) {
        const synth = new Synth().toMaster();
        synth.triggerAttackRelease("D3", "8n");
      }
      console.log("Participant LoggedOut");
    } else {
      if (this.store.state.Auth.isLogged && value > 1) {
        const synth = new Synth().toMaster();
        synth.triggerAttackRelease("D5", "8n");
      }
      console.log("Participant LoggedIn");
    }
    this.members = value;
  }

  destroyed() {
    if (this.signal.isAgent) {
      let storage = store.state.Auth.token.claims.recordings || "download"
      if (storage == "cloudstorage") {
        this.updateMeetingMessages();
      }
    }

    this.signal.disconnectDataBase();
  }

  unloadPage() {
    console.info("unload page from communications");
    this.destroyed();
    if (this.isInCall) {
      this.onEndCall();
      this.EndVideoCall();
    }
  }

  onEndCall() {
    this.isInCall = false;
    this.videoRecorder.finishCall();
    this.store.commit("SET_IS_IN_ACTIVE_CALL", false);

    //un register from singleton
    communicationsSingleton.communications = null;
    if (this.onEndCallEvent) this.onEndCallEvent();
  }

  //Join a video Call
  JoinVideoCall(stream) {
    if (!this.isInCall) {
      this.isInCall = true;
      this.signal.initWRTC(stream);
      this.videoRecorder.startCall();
      let onlyAudio = !store.state.permissions.video && store.state.permissions.audio ? true : false
      this.signal.sendSignalMessage({
        join: "Join",
        onlyAudio: onlyAudio,
        recordEnabled: store.state.Auth.token.claims.recordEnabled
      });
      console.log(onlyAudio)
    }
  }

  //EndCall should refresh customer browser
  EndVideoCall() {
    this.signal.sendSignalMessage({ end: "End" });
  }

  //Mute Video
  MuteVideo() {
    this.signal.wrtc.mutevideo();
  }

  ChangeResolution(h, w) {
    this.signal.wrtc.changeResolution(h, w)
  }

  //Mute Sound
  MuteSound() {
    this.signal.wrtc.mutesound();
  }

  //Send Desktop to remote call
  async SendDesktop() {
    this.signal.sendData({
      videosource: "desktop",
    });
    return this.signal.wrtc.sendDesktop();
  }

  ChangeDevices() {
    this.signal.wrtc.changeInputDevice();
  }

  //Send Video Camera to remote call
  async SendCamera(muted) {
    this.signal.sendData({
      videosource: "user",
    });
    this.signal.wrtc.sendCamera(muted);
  }

  async SendRearCamera() {
    this.signal.sendData({
      videosource: "environment",
    });
    this.signal.wrtc.sendRearCamera();
  }

  //Send a Chat
  SendChat(msg) {
    this.signal.sendChatMessage({ chat: msg });
  }

  SendFile(file) {
    let msg = new Object();
    msg.size = file.size;
    msg.file = file.name;
    this.signal.wrtc.sendData(msg);
    this.signal.wrtc.sendFile(file);
  }

  SendLocation(location){
    this.signal.sendLocationMessage({chat:location.latitude+"||&||"+location.longitude});
  }

  //Count number of people in room
  Members() {
    console.debug(this.signal.members);
  }

  //fired when there is someone waiting for a call
  memberjoin() {
    console.debug("Participant LoggedIn");
  }

  //fired when someoneleft
  memberleft() {
    console.debug("Participant LoggedOut");
  }

  //Error User Media
  usermediafailed() {
    console.debug("Camera or Mic Not allowed");
  }

  Record() {
    this.videoRecorder.Record();
  }

  orientationHandler() {
    window.onorientationchange = () => {
      this.signal.sendData({
        portrait: !this.signal.getOrientation(),
      });
    };
  }

  async updateMeetingMessages() {
    let messages = this.signal.communications.messages;
    if (messages.length > 0) {
      let updateMessages = await Meeting.updateMeetingMessages(new Meeting(this.meeting), messages)
    } else {
      return false
    }
  }


  setBackgroundBlur(stream) {
    this.signal.wrtc.blurStream = stream;
    this.signal.wrtc.toggleBlurredStream(stream);
  }

  setNoBackgroundBlur() {
    this.signal.wrtc.blurStream = null;
    this.signal.wrtc.toggleBlurredStream();
  }

  deleteScreenStream(){
    this.signal.wrtc.endScreenStream();
  }
}
